<template>
	<div class="submit-container">
		<div class="info-wrapper">
			<Card
				type="content"
				:list="list"
				:info="formData" />
		</div>

		<div class="title">租房合同信息</div>

		<div class="file-list">
			<ViewFileItem
				v-for="(file, index) in formData.fileList"
				:key="file.fileNo"
				:index="index"
				:file="file"
				:del="false" />
		</div>
		<van-button
			type="black"
			round
			class="submit-btn"
			@click="handleSubmit">
			<!-- Submit -->
			提交
		</van-button>
	</div>
</template>

<script>
import getDetailMixins from '../mixins/getDetail'
import operationApi from '../api/operation'

export default {
	mixins: [getDetailMixins],
	data() {
		return {
			list: [
				// {
				// 	title: '房东',
				// 	list: [
				// 		{ label: 'First Name', value: 'firstName' },
				// 		{ label: 'Last Name', value: 'lastName' }
				// 	]
				// },
				// {
				// 	title: '房东地址 (仅限美国)',
				// 	list: [
				// 		{ label: 'State', value: 'landlordState' },
				// 		{ label: 'City', value: 'landlordCity' },
				// 		{ label: 'District', value: 'landlordDistrict' },
				// 		{ label: 'Address', value: 'landlordAddress' },
				// 		{ label: 'Zip Code', value: 'landlordZipCode' }
				// 	]
				// },
				{
					title: '银行账户收款信息',
					list: [
						{ label: '账号', value: 'bankCardNo' },
						{ label: '名', value: 'firstName' },
						{ label: '姓', value: 'lastName' },
						{ label: '开户银行', value: 'bankName' },
						{ label: 'ABA(ACH)', value: 'aba' }
					]
				}
			],
			formData: {}
		}
	},
	watch: {
		list: {
			handler(list) {
				list.forEach(item => {
					item.list.forEach(row => {
						this.$set(this.formData, row.value, '')
					})
				})
				// 额外设置fileList
				this.$set(this.formData, 'fileList', [])
			},
			immediate: true
		}
	},
	created() {
		this.getLanglordDetail()
	},
	methods: {
		handleSubmit() {
			const { landlordNo } = this
			const loading = this.$toast.loading()
			operationApi
				.submitLandlord({
					landlordNo
				})
				.then(res => {
					if (res) {
						this.$router.push({
							name: 'LandlordOperationResult',
							query: {
								// 传递landlordNo时 会导致Result页面跳转添加新房东 进入编辑模式
							}
						})
					}
				})
				.catch(console.error)
				.finally(_ => {
					loading.clear()
				})
		}
	}
}
</script>

<style scoped lang="less">
.submit-container {
	/deep/.card-container {
		padding: 0;
	}
	.info-wrapper {
		padding-bottom: 24px;
		margin-bottom: 24px;
		border-bottom: 1px dashed var(--main-07);
	}
	.file-list {
		display: flex;
		flex-wrap: wrap;
		margin-top: 20px;
		.title {
			font-size: 14px;
			color: var(--main-01);
			line-height: 14px;
		}
	}
	.submit-btn {
		margin-top: 16px;
	}
}
</style>
