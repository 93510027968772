// 路由历史记录
export default {
	namespaced: true,
	state: {
		// 历史记录路由
		historyRoute: {}
	},
	mutations: {
		SET_HISTORY_ROUTE: (state, historyRoute) => {
			state.historyRoute = historyRoute
		}
	},
	actions: {}
}
