<template>
	<div class="easy">
		<FieldInput
			v-bind="$attrs"
			:type="!showPassword ? 'password' : 'text'"
			autocomplete="off"
			v-on="$listeners">
			<template #right-icon>
				<div @click="changePassword">
					<SvgIcon
						:name="showPassword ? 'eye-on' : 'eye-off'"
						class="easy-icon" />
				</div>
			</template>
		</FieldInput>
	</div>
</template>
<script>
export default {
	name: 'InputPassword',
	props: {},
	data() {
		return {
			showPassword: false
		}
	},
	methods: {
		changePassword() {
			this.showPassword = !this.showPassword
		}
	}
}
</script>

<style scoped lang="less">
.easy {
	position: relative;
	.easy-input {
		width: 100%;
		box-sizing: border-box;
		padding: 15px 14px;
		font-size: 14px;
		line-height: 14px;
		color: var(--main-01);
		&::placeholder {
			font-family: PingFang SC;
			color: var(--black-40);
		}
	}
	.easy-icon {
		width: 20px;
		height: 20px;
		cursor: pointer;
	}
}
</style>
