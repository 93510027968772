<template>
	<div class="protocol">
		<component
			:is="agreeComponents"
			class="agree-container" />
	</div>
</template>

<script>
import BindingCardPaymentAgreement from './components/BindingCardPaymentAgreement.vue'
import PrivacyAgreement from './components/PrivacyAgreement.vue'
import ServiceAgreement from './components/ServiceAgreement.vue'
export default {
	components: {
		BindingCardPaymentAgreement,
		PrivacyAgreement,
		ServiceAgreement
	},
	data() {
		return {
			name: ''
		}
	},
	computed: {
		agreeComponents() {
			const { name } = this.$route.query

			return name === '服务协议'
				? 'ServiceAgreement'
				: name === '隐私政策'
				? 'PrivacyAgreement'
				: name === '绑卡支付协议'
				? 'BindingCardPaymentAgreement'
				: ''
		}
	},
	created() {
		// this.$toast.loading({
		// 	message: '加载中...',
		// 	forbidClick: true,
		// 	duration: 0
		// })
	},
	mounted() {},
	beforeDestroy() {
		// this.$toast.clear()
	},
	methods: {}
}
</script>

<style lang="less" scoped></style>
