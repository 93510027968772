<template>
	<div class="login">
		<div class="login-content">
			<div class="login-input">
				<InputPassword
					v-model="formData.password"
					size="large"
					maxlength="20"
					:formatter="passwordFormatter"
					placeholder="请输入6-20位数字和字母的登录密码" />
			</div>
			<div class="login-input">
				<InputPassword
					v-model="formData.password1"
					size="large"
					maxlength="20"
					:formatter="passwordFormatter"
					placeholder="请再次输入登录密码" />
			</div>
			<div class="login-tips">
				<p>温馨提示：</p>
				<p>
					登陆密码可设置为大写字母、小写字母、数字及特殊字符组合，建议使用长度在8位以上且含有三种及以上不同形式的字符组合。
				</p>
			</div>
			<div class="login-btn">
				<van-button
					round
					:disabled="!isLoginBtn"
					type="red"
					@click="nextStep">
					确 定
				</van-button>
			</div>
		</div>
	</div>
</template>
<script>
import { setPwd } from 'views/login/api'
import { mapMutations } from 'vuex'

export default {
	data() {
		return {
			formData: {
				password: '',
				password1: ''
			}
		}
	},
	computed: {
		isLoginBtn() {
			const { password, password1 } = this.formData
			return password && password1
		},
		isPasswordLength() {
			return (
				this.formData.password.length >= 6 &&
				this.formData.password1.length >= 6
			)
		}
	},
	methods: {
		...mapMutations('app', ['SET_TO_LOGIN_NEXT']),
		passwordFormatter(value) {
			return value.replace(/[^0-9a-zA-Z]/g, '')
		},
		nextStep() {
			if (!this.isPasswordLength) {
				this.$toast('密码长度至少为6位')
				return
			}
			const { password, password1 } = this.formData
			if (password !== password1) {
				this.$toast('两次密码输入不一致')
				return
			}
			const loading = this.$toast.loading()
			setPwd({
				password
			})
				.then(res => {
					this.SET_TO_LOGIN_NEXT('KycIndex')
					this.$router.replace({
						name: 'KycIndex'
					})
				})
				.catch(err => {
					console.log(err)
				})
				.finally(() => {
					loading.clear()
				})
		}
	}
}
</script>
<style scoped lang="less">
.login {
	min-height: 100vh;
	background-color: #fff;
	padding-top: 45px;
	.login-content {
		padding: 24px;
		.login-title {
			padding: 34px 0;
			text-align: center;
			font-size: 24px;
		}
		.login-input {
			margin-top: 24px;
		}
		.login-btn {
			padding-top: 40px;
		}
	}
	.login-tips {
		padding-top: 24px;
		color: var(--main-02);
		font-size: 12px;
		line-height: 20px;
	}
}
</style>
