<template>
	<div>
		<VanValidForm>
			<VanValidFormModule title="银行账户收款信息 (请录入英文)">
				<VanValidFormItem label="银行账号">
					<FieldInput
						v-model="formData.bankCardNo"
						clearable
						:allow-space="false"
						valid-type="REG4_4"
						maxlength="64"
						placeholder="Account NO." />
				</VanValidFormItem>
				<VanValidFormItem label="名">
					<FieldInput
						v-model="formData.firstName"
						clearable
						:allow-space="false"
						:capitalize="true"
						valid-type="REG4_3"
						maxlength="64"
						placeholder="First Name" />
				</VanValidFormItem>
				<VanValidFormItem label="姓">
					<FieldInput
						v-model="formData.lastName"
						clearable
						:allow-space="false"
						:capitalize="true"
						valid-type="REG4_3"
						maxlength="64"
						placeholder="Last Name" />
				</VanValidFormItem>
				<VanValidFormItem label="ABA(ACH)">
					<FieldInput
						v-model="formData.aba"
						clearable
						placeholder="9 Digits"
						maxlength="9"
						type="digit"
						@input="handleABAInput" />
				</VanValidFormItem>
				<VanValidFormItem label="开户银行">
					<FieldInput
						v-model="formData.bankName"
						:disabled="formData.aba.length !== 9 || !!formData.disabledBankName"
						clearable
						:allow-space="true"
						:capitalize="false"
						valid-type="REG4_3_1"
						maxlength="256"
						:placeholder="formData.aba.length !== 9 ? '' : ''" />
				</VanValidFormItem>
			</VanValidFormModule>
			<div class="tip">
				提示：当前仅限办理美国境内银行账户收款，暂不支持其他国家或地区银行账户。
			</div>
			<van-button
				type="black"
				round
				class="submit-btn"
				native-type="submit"
				@click="handleNext">
				<!-- Save & Next -->
				保存并下一步
			</van-button>
		</VanValidForm>
	</div>
</template>

<script>
import operationApi from '../api/operation'
import getDetailMixins from '../mixins/getDetail'
import selectCityMixins from '../mixins/selectCity'
import validateMixins from '../mixins/validate'
import { mapState } from 'vuex'

export default {
	mixins: [getDetailMixins, selectCityMixins, validateMixins],
	data() {
		return {
			// 表单
			formData: {
				bankCardNo: '',
				firstName: '',
				lastName: '',
				bankName: '',
				aba: '',
				disabledBankName: false
			},
			rules: {
				bankCardNo: [{ required: true, message: '请输入Account NO.' }],
				firstName: [{ required: true, message: '请输入First Name' }],
				lastName: [{ required: true, message: '请输入Last Name' }],
				bankName: [{ required: true, message: '请输入Bank' }],
				aba: [{ required: true, message: '请输入ACH' }]
			}
		}
	},
	computed: {
		...mapState('history', ['historyRoute'])
	},
	async created() {
		// 如果从step2返回的话 从历史记录路由中读取
		if (this.historyRoute && this.historyRoute.query) {
			const { landlordNo } = this.historyRoute.query
			if (landlordNo) {
				this.landlordNo = landlordNo
			}
		}
		await this.getLanglordDetail()
		// 初始化回显bankName有值的话不可修改，只有aba号修改后才可以改动
		if (this.formData.bankName) {
			this.formData.disabledBankName = true
		}
	},
	methods: {
		handleABAInput(e) {
			if (e.length === 9) {
				// 只有详情回显才会存在该场景，该场景下不去调用接口
				if (this.formData.bankName) {
					return
				}
				const loading = this.$toast.loading()
				operationApi
					.selectAbaBankInfo({
						abaCode: e,
						currency: 'USD',
						serviceId: 'FEDACH'
					})
					.then(res => {
						if (res && Object.keys(res).length > 0) {
							const { institutionName } = res
							this.formData.bankName = institutionName
							this.formData.disabledBankName = true
						} else {
							this.$dialog.alert({
								title: '提示',
								message:
									'我们暂时未查询到该ABA，\n' +
									'请确认填写是否正确，以免影响房东收款'
							})
						}
					})
					.catch(console.error)
					.finally(() => {
						loading.clear()
					})
			} else {
				this.formData.bankName = ''
				this.formData.disabledBankName = false
			}
		},
		handleNext() {
			const { landlordNo, formData, rules } = this
			this.validateForm(formData, rules)
				.then(_ => {
					const loading = this.$toast.loading()
					let promise
					if (landlordNo) {
						promise = operationApi.editLandlordInfo({
							landlordNo,
							...formData
						})
					} else {
						promise = operationApi.addLandlordInfo({
							...formData
						})
					}
					promise
						.then(res => {
							if (res) {
								const { landlordNo } = res
								this.$router.push({
									name: 'LandlordOperationStep3',
									query: {
										landlordNo
									}
								})
							}
						})
						.catch(console.error)
						.finally(_ => {
							loading.clear()
						})
				})
				.catch(({ rules }) => {
					const { message } = rules
					this.$dialog.alert({
						title: '提示',
						message
					})
				})
		}
	}
}
</script>

<style scoped lang="less">
.input-form {
	width: 100%;
}
.tip {
	margin-bottom: 20px;
	font-size: 12px;
	color: var(--main-04);
	line-height: 20px;
}
</style>
