<template>
	<div class="face-wrapper">
		<div class="img">
			<img
				:src="imgObj[result.img] || imgObj['INITIAL']"
				alt="" />
		</div>
		<p class="title">{{ result.title }}</p>
		<p class="desc">{{ result.desc }}</p>
		<template v-if="result.btnText">
			<van-button
				round
				type="black"
				class="btn"
				:loading="submitLoading"
				@click="handleStart">
				{{ result.btnText }}
			</van-button>
		</template>
		<slot name="btnList" />
	</div>
</template>

<script>
import successPic from '@/assets/image/common/img-success.png'
import failedPic from '@/assets/image/common/img-fail.png'
import distancePic from '@/assets/image/common/img-distance.png'

export default {
	props: {
		status: {
			type: String,
			default: 'INITIAL'
		},
		submitLoading: {
			type: Boolean,
			default: false
		},
		info: {
			type: Object,
			default() {
				return {
					// 人脸认证
					INITIAL: {
						img: 'successPic',
						title: '人脸识别',
						desc: '请正脸对准框内，并保持光线充足！',
						btnText: '马上开始'
					},
					// 人脸认证失败
					FAILED: {
						img: 'failedPic',
						title: '认证失败',
						desc: '人脸校验未通过，请重新授权',
						btnText: '确认'
					},
					// 人脸认证成功
					SUCCESS: {
						img: 'successPic',
						title: '授权完成',
						desc: '',
						btnText: '确认'
					}
				}
			}
		}
	},
	data() {
		return {
			imgObj: {
				successPic: successPic,
				failedPic: failedPic,
				distancePic: distancePic
			}
		}
	},
	computed: {
		result() {
			const { status, info } = this
			return info[status]
		}
	},
	methods: {
		handleStart() {
			this.$emit('start')
		}
	}
}
</script>

<style scoped lang="less">
.face-wrapper {
	width: 100%;
	text-align: center;
	.img {
		width: 140px;
		height: 140px;
		margin: 0 auto 30px;
		> img {
			display: block;
			width: 100%;
			height: 100%;
		}
	}
	.title {
		margin-bottom: 16px;
		font-size: 20px;
		color: var(--main-01);
		line-height: 20px;
	}
	.desc {
		margin-bottom: 30px;
		font-size: 14px;
		color: var(--main-03);
		line-height: 24px;
		padding: 0 22px;
	}
}
</style>
