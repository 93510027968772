import api from 'utils/http/axios'
import { BASE_URL } from 'utils/http/base-url'

// 验证码登陆
export const smsLogin = (params = {}, config) => {
	return api.post(BASE_URL + '/app/user/loginByPhone', params, config)
}
// 密码登陆
export const login = (params = {}, config) => {
	return api.post(BASE_URL + '/app/user/loginByPwd', params, {
		...config,
		md5Keys: 'password'
	})
}
// // 忘记密码 - 重置密码前校验手机号
// export const resetLoginPwdAuth = (params = {}, config) => {
// 	return api.post(BASE_URL + '/v1/password/login/reset/auth', params, config)
// }
// // 忘记密码 - 重置
// export const resetLoginPwd = (params = {}, config) => {
// 	return api.post(BASE_URL + '/v1/password/login/reset', params, config)
// }
// 设置登陆密码
export const setPwd = (params = {}, config) => {
	return api.post(BASE_URL + '/app/user/setPwd', params, {
		...config,
		md5Keys: 'password'
	})
}
// 设置支付密码
export const setTradePwd = (params = {}, config) => {
	return api.post(BASE_URL + '/app/user/setTradePwd', params, {
		...config,
		md5Keys: 'password'
	})
}
