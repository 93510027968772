<template>
	<van-field
		:class="[
			'field-input',
			{ 'field-input-focus': isShowFocus },
			{ 'field-input-large': size === 'large' }
		]"
		v-bind="attrs"
		v-on="listeners">
		<template #label>
			<slot name="label" />
		</template>
		<template #input>
			<slot name="input" />
		</template>
		<template #left-icon>
			<slot name="left-icon" />
		</template>
		<template #right-icon>
			<slot name="right-icon" />
		</template>
		<template #button>
			<slot name="button" />
		</template>
		<template #extra>
			<slot name="extra" />
		</template>
	</van-field>
</template>
<script>
import _ from 'lodash-es'
import validate from '@/utils/validate'
export default {
	name: 'FieldInput',
	model: {
		prop: 'value',
		event: 'input'
	},
	props: {
		size: {
			type: String,
			default: 'normal'
		},
		validType: {
			type: String,
			default: ''
		},
		// 默认允许输入空格
		allowSpace: {
			type: Boolean,
			default: true
		},
		// 默认清除字符串两端的空格
		trim: {
			type: Boolean,
			default: true
		},
		// 自动转大写
		upperCase: {
			type: Boolean,
			default: false
		},
		// 首字母大写
		capitalize: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			validate,
			isShowFocus: false
		}
	},
	computed: {
		listeners() {
			return Object.assign({}, this.$listeners, {
				blur: event => {
					this.handleBlur(event)
				},
				focus: event => {
					this.isShowFocus = true
					this.$emit('focus', event)
					this.isShowFocus = true
				}
			})
		},
		attrs() {
			return Object.assign({}, this.$attrs, {
				formatter: value => {
					const { reg, allowSpace, upperCase, capitalize } = this
					if (!allowSpace) {
						value = value
							.split('')
							.filter(char => char !== ' ')
							.join('')
					}
					if (upperCase) {
						value = value.toUpperCase()
					} else if (capitalize) {
						value = _.startCase(_.toLower(value))
					}
					// 将不合格式的输入 删除
					if (reg) {
						const arr = value.split('')
						let index = arr.length
						for (let i = 0; i < index; i++) {
							const char = arr[i]
							// || (trim && char === ' ')
							if (!reg.test(char)) {
								index = i
								break
							}
						}
						return value.slice(0, index)
					}
					return value
				}
			})
		},
		reg() {
			const { validate, validType } = this
			return validType ? validate[validType] : false
		}
	},
	methods: {
		handleFocus(event) {
			this.isShowFocus = false
			if (this.trim) {
				this.$emit('input', event.target.value.trim())
			}
			// 透传触发blur
			this.$emit('blur', event)
		},
		handleBlur(event) {
			this.isShowFocus = false
			if (this.trim) {
				this.$emit('input', event.target.value.trim())
			}
			// 透传触发blur
			this.$emit('blur', event)
		}
	}
}
</script>
<style scoped lang="less">
.field-input {
	background-color: var(--main-08);
	border: 1px solid var(--main-08);
	border-radius: 4px;
}
.field-input.field-input-large {
	padding: 14px 13px;
}
.field-input-focus {
	background-color: var(--white);
	border: 1px solid var(--main-01);
}
</style>
