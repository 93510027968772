import loginRoutes from '@/router/commonRoutes/login'
import homeRoutes from '@/router/commonRoutes/home'
import billsRoutes from '@/router/commonRoutes/bills'
import cardRoutes from '@/router/commonRoutes/card'
import kycRoutes from '@/router/commonRoutes/kyc'
import faceValidate from '@/router/commonRoutes/faceValidate/index'
import rentRoutes from '@/router/commonRoutes/rent'
import landlordRoutes from '@/router/commonRoutes/landlord'
import settingRoutes from '@/router/commonRoutes/setting'
import helpRoutes from '@/router/commonRoutes/help'

export default [
	{
		path: '/',
		redirect: '/home'
	},
	...loginRoutes,
	...kycRoutes,
	...homeRoutes,
	...billsRoutes,
	...cardRoutes,
	...faceValidate,
	...rentRoutes,
	...landlordRoutes,
	...settingRoutes,
	...helpRoutes,
	{
		path: '/protocol',
		name: 'Protocol',
		meta: {
			title: '协议',
			hideNavBarRight: true
		},
		component: () => import('@/components/protocol/Protocol.vue')
	}
]
