import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/utils/global'
// 第三方组件
import '@/components/vant'
import '@/components/element-ui'
// 样式
import '@/assets/styles/global.css'
import '@/assets/styles/reset.css'
// element-ui覆盖样式
import '@/assets/styles/element-ui/index.less'
// Svg Icon
import '@/assets/icons'
// 插件
import '@/plugins/vconsole'
import '@/plugins/user-scalable-ios'
// 桌面端适配
import '@vant/touch-emulator'
// 国际化
// import i18n from '@/lang'
// 全局组件
import '@/components'
// 全局指令
import '@/utils/directives'
import face from '@/plugins/face'

import GeeTest from '@cbibank/geetest'
import commonApi from '@/api/common'

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

Vue.use(face)

Vue.use(GeeTest, {
	appCode: 'cbimart-h5',
	authApi: {
		getAccess: commonApi.geeTestRegisterV4
	}
})

Vue.config.productionTip = false

new Vue({
	router,
	store,
	// i18n,
	render: h => h(App)
}).$mount('#app')
