<template>
	<VanValidForm v-on="$listeners">
		<VanValidFormItem
			label="汇出方式"
			direction="row"
			class="flex-start">
			<van-radio-group
				v-model="formData.payoutMethod"
				v-bind="config.payoutMethod"
				@change="handleModifyPayoutMethod">
				<van-radio name="TELEGRAPHIC_TRANSFER">
					<span>ACH</span>
					<template #icon="props">
						<van-image
							width="14px"
							height="14px"
							fit="cover"
							:src="
								props.checked
									? require('@/assets/image/common/checked-active.png')
									: require('@/assets/image/common/checked.png')
							" />
					</template>
				</van-radio>
			</van-radio-group>
		</VanValidFormItem>
		<VanValidFormItem label="房东">
			<div class="flex">
				<ElFormSelect
					v-bind="config.landlordNo"
					v-model="formData.landlordNo"
					placeholder="请选择"
					clearable
					class="input-form"
					@change="changeLandlordNo">
					<ElOption
						v-for="item in landlorOptions"
						:key="item.code"
						:label="item.cnName"
						:value="item.code" />
				</ElFormSelect>
				<div
					class="block"
					@click="handlePlusLanlord">
					<SvgIcon name="plus" />
				</div>
			</div>
		</VanValidFormItem>
		<VanValidFormItem
			label="支付项目"
			direction="row"
			class="flex-start">
			<van-radio-group
				v-model="formData.paymentItem"
				v-bind="config.paymentItem">
				<van-radio name="RENT">
					<span>房租</span>
					<template #icon="props">
						<van-image
							width="14px"
							height="14px"
							fit="cover"
							:src="
								props.checked
									? require('@/assets/image/common/checked-active.png')
									: require('@/assets/image/common/checked.png')
							" />
					</template>
				</van-radio>
			</van-radio-group>
		</VanValidFormItem>
		<VanValidFormItem
			label="汇出金额"
			class="flex-start">
			<template #label-after>
				<span style="color: var(--main-01)">（全额到账）</span>
			</template>
			<div class="flex">
				<FieldInput
					v-bind="config.amount"
					v-model="formData.amount"
					type="number"
					clearable
					:placeholder="limitPlaceholder"
					@blur="handleAmountBlur" />
				<p class="ml-10">USD</p>
			</div>
		</VanValidFormItem>

		<VanValidFormItem
			label="费用"
			direction="row"
			class="flex-start">
			<div class="flex">
				<p>{{ numberFormat(formData.feeAmount) }}</p>
				<p class="ml-10">USD</p>
				<div
					v-clickoutside="handleClickOutside"
					class="tip-wrapper">
					<SvgIcon
						name="tip"
						class="icon ml-6"
						@click.native="showTip = true" />
					<transition name="el-fade-in-linear">
						<div
							v-show="showTip"
							class="overlay">
							<p class="tip-text">收费说明</p>
							<p class="tip-text">
								{{ feeInfo.descCn }}
							</p>
						</div>
					</transition>
				</div>
			</div>
		</VanValidFormItem>
		<VanValidFormItem
			label="扣款总金额"
			direction="row"
			class="flex-start">
			<div class="flex">
				<p>{{ numberFormat(formData.totalAmount) }}</p>
				<p class="ml-10">USD</p>
			</div>
		</VanValidFormItem>

		<VanValidFormItem
			label="预估人民币金额"
			direction="row"
			class="reference-rate-wrapper flex-start">
			<div class="reference-rate-container">
				<div class="flex">
					<p>{{ numberFormat(formData.estimatedCnyAmt) }}</p>
					<p class="ml-10">USD</p>
					<div
						v-clickoutside="handleClickOutside"
						class="tip-wrapper">
						<SvgIcon
							name="tip"
							class="icon ml-6"
							@click.native="showTipRmb = true" />
						<transition name="el-fade-in-linear">
							<div
								v-show="showTipRmb"
								class="overlay">
								<p class="tip-text">预估人民币金额说明</p>
								<p class="tip-text">
									支付换汇由银联等国际卡组织直接完成，CBiMart不参与换汇，客户无汇率损失。CBiMart从公开市场获取该中间汇率报价，仅供客户参考，实际扣款金额以银联等国际卡组织实时换汇结果为准。
								</p>
							</div>
						</transition>
					</div>
					<div
						v-if="rateInfo.rateData"
						class="reference-rate">
						{{ rateText }}
					</div>
				</div>
			</div>
		</VanValidFormItem>
		<template v-if="formType === 'PaymentCancel'">
			<VanValidFormItem
				label="账单号"
				direction="row"
				class="flex-start">
				<div class="flex">
					<p>{{ detailsInfo.bizOrder }}</p>
				</div>
			</VanValidFormItem>
			<VanValidFormItem
				label="创建时间"
				direction="row"
				class="flex-start">
				<div class="flex">
					<p>{{ detailsInfo.createTimeStr }}</p>
				</div>
			</VanValidFormItem>
			<!-- <VanValidFormItem
				label="状态"
				direction="row"
				class="flex-start">
				<div class="flex">
					<p>{{ detailsInfo.statusCn }}</p>
				</div>
			</VanValidFormItem> -->
			<VanValidFormItem
				label="退款金额"
				direction="row"
				class="flex-start">
				<div class="flex">
					<p>{{ numberFormat(detailsInfo.payerAmount) }}</p>
					<p class="ml-10">USD</p>
				</div>
			</VanValidFormItem>
		</template>

		<VanValidFormItem label="付款银行卡">
			<div class="flex">
				<ElFormSelect
					v-bind="config.cardInfoNo"
					v-model="formData.cardInfoNo"
					placeholder="请选择"
					clearable
					class="input-form"
					@change="changeCard">
					<ElOption
						v-for="item in cardOptions"
						:key="item.code"
						:label="item.cnName"
						:value="item.code" />
				</ElFormSelect>
				<div
					class="block"
					@click="handlePlusCard">
					<SvgIcon name="plus" />
				</div>
			</div>
		</VanValidFormItem>

		<VanValidFormItem
			label="预估向房东结算日期"
			direction="row"
			class="flex-start">
			<div class="flex">
				<p>{{ expectedSettleDateStr }}</p>
				<div
					v-clickoutside="handleClickOutside"
					class="tip-wrapper">
					<SvgIcon
						name="tip"
						class="icon ml-6"
						@click.native="showTipDate = true" />
					<transition name="el-fade-in-linear">
						<div
							v-show="showTipDate"
							class="overlay">
							<p class="tip-text">预估结算日期规则</p>
							<p class="tip-text">
								1.本人银行卡或已授权的亲属银行卡，一般在扣款完成日（不包含）后的第4个工作日结算。
							</p>
							<p class="tip-text">
								2.未授权的亲属银行卡，需要先完成授权后，才会结算。
							</p>
						</div>
					</transition>
				</div>
			</div>
		</VanValidFormItem>

		<!-- <VanValidFormItem label="支付密码">
			<FieldInput
				v-bind="config.charPwd"
				v-model="formData.charPwd"
				clearable
				type="password"
				maxlength="6"
				autocomplete="off"
				:formatter="passwordFormatter"
				placeholder="请输入支付密码" />
		</VanValidFormItem>
		<VanValidFormItem label="短信验证码">
			<div class="flex">
				<InputTimeout
					ref="inputTimeout"
					v-bind="config.code"
					v-model="formData.code"
					class="input-timeout"
					:country-code="userInfo.countryCode"
					:phone="userInfo.mobileNo"
					:border="false"
					btn-type="black"
					:sms-type="smsType"
					@change="handleTimeChange" />
			</div>
		</VanValidFormItem> -->
		<div class="message-wrapper">
			<p
				v-if="formType === 'PaymentCancel'"
				class="message-tip">
				*
				退款交易最快将在原扣款日的5个工作日（即T+4）内处理，资金将退回原支付银行卡中，请注意查收。
			</p>
			<p
				v-else
				class="message-tip">
				*
				为了完成您的支付，您提交的相关信息将在需要的情况下提供给监管或第三方合作方。当您确认账单时，即表示您同意我们使用这些信息。
			</p>
			<slot />
		</div>
	</VanValidForm>
</template>

<script>
import { mapState } from 'vuex'
import {
	queryRentPageInfo,
	getRentTradeOrderDetail,
	getLandlordList,
	getCardPayList,
	showLimitAndDesc,
	calculateCosts,
	queryCurrentExchangeRate
} from '@/views/rent/api/index.js'
import { numberFormat } from '@/utils/utils.js'
export default {
	props: {
		// 支付Payment 再次支付PaymentAgain 取消支付PaymentCancel 换房东PaymentChangeLandlord
		formType: {
			type: String,
			default: ''
		},
		/**
		 *     TRADE_VERIFY_SUBMIT("TRADE_VERIFY_SUBMIT", "支付提交验证码"),
		 *     TRADE_VERIFY_REMIT_AGAIN("TRADE_VERIFY_REMIT_AGAIN", "修改房东再支付"),
		 *     TRADE_VERIFY_CANCEL("TRADE_VERIFY_CANCEL", "取消"),
		 */
		smsType: {
			type: String,
			default: 'TRADE_VERIFY_SUBMIT'
		},
		// 表单数据
		data: {
			type: Object,
			default() {
				return {}
			}
		},
		// 表单单项配置
		config: {
			type: Object,
			default() {
				return {}
			}
		}
	},
	data() {
		return {
			landlorOptions: [],
			cardOptions: [],
			detailsInfo: {},
			// 费用返回相关内容
			feeInfo: {
				maxAmount: 0,
				minAmount: 0,
				descCn: '',
				descEn: ''
			},
			bizOrder: '',
			formData: {},
			showTip: false,
			showTipRmb: false,
			showTipDate: false,
			// 预估向房东结算日期
			expectedSettleDate: '',
			expectedSettleDateStr: '',
			// 汇率返回内容
			rateInfo: {
				baseCur: '',
				rateData: '',
				transCur: ''
			}
		}
	},
	computed: {
		// ...mapState('app', ['userInfo']),
		...mapState('rent', ['rentFormInfo']),
		limitPlaceholder() {
			return `${numberFormat(this.feeInfo.minAmount)}-${numberFormat(
				this.feeInfo.maxAmount
			)}`
		},
		rateText() {
			const { baseCur, rateData, transCur } = this.rateInfo
			return `参考汇率 1${transCur}=${rateData} ${baseCur}`
		}
	},
	watch: {
		data: {
			handler(data) {
				if (data && typeof data === 'object') {
					this.formData = {
						...this.formData,
						...data
					}
				}
			},
			immediate: true
		}
	},
	async created() {
		this.getOptions()
		this.getLimitInfo()
		await this.initInfo()
		// 详情回显
		const { bizOrder } = this.$route.query
		this.bizOrder = bizOrder
		if (bizOrder) {
			this.getDetails()
		}
	},
	methods: {
		numberFormat,
		async initInfo() {
			const { landlordNo, cardInfoNo } = this.$route.query
			this.formData.landlordNo = landlordNo
			this.formData.cardInfoNo = cardInfoNo || ''
			this.formData = {
				...this.formData,
				...this.rentFormInfo
			}
			await this.queryCurrentExchangeRate()
			this.handleModifyPayoutMethod()
			const { amount } = this.formData
			if (amount) {
				this.handleAmountBlur()
			}
		},
		async queryCurrentExchangeRate() {
			await queryCurrentExchangeRate()
				.then(res => {
					this.rateInfo = res
				})
				.catch(console.log)
		},
		handleModifyPayoutMethod() {
			let data = {
				landlordPaymentMethod: 'TELEGRAPHIC_TRANSFER'
			}
			queryRentPageInfo(data)
				.then(res => {
					this.expectedSettleDate = res.expectedSettleDate
					this.expectedSettleDateStr = res.expectedSettleDateStr
				})
				.catch(console.log)
		},
		passwordFormatter(value) {
			return value.replace(/[^0-9]/g, '')
		},
		getDetails() {
			const { bizOrder } = this
			getRentTradeOrderDetail({
				bizOrder
			}).then(res => {
				const {
					landlordNo,
					paymentItem,
					payeeAmount,
					payeeReceiveMethod: payoutMethod,
					serviceFee: feeAmount,
					payerAmount: totalAmount,
					cardInfoNo
				} = res
				const amount = payeeAmount.replaceAll(',', '')
				let resLandlordNo = ''
				if (this.landlorOptions.some(item => item.code === landlordNo)) {
					resLandlordNo = landlordNo
				}
				let resCardInfoNo = ''
				if (this.cardOptions.some(item => item.code === cardInfoNo)) {
					resCardInfoNo = cardInfoNo
				}
				this.formData = {
					...this.formData,
					landlordNo: resLandlordNo,
					paymentItem,
					amount,
					payoutMethod,
					feeAmount,
					totalAmount,
					cardInfoNo: resCardInfoNo
				}
				this.detailsInfo = res
				console.log(this.formData)
				if (amount) {
					this.handleAmountBlur()
				}
			})
		},
		getOptions() {
			getLandlordList()
				.then(res => {
					this.landlorOptions = res || []
					this.landlorOptions.push({
						cnName: '添加新房东',
						code: 'Add_New_Lanlord'
					})
				})
				.catch(console.log)
			getCardPayList()
				.then(res => {
					this.cardOptions = res || []
					this.cardOptions.push({
						cnName: '添加新银行卡',
						code: 'Add_New_Card'
					})
				})
				.catch(console.log)
		},
		getLimitInfo() {
			showLimitAndDesc()
				.then(res => {
					this.feeInfo = res
				})
				.catch(console.log)
		},
		handleClickOutside() {
			this.showTip = false
			this.showTipRmb = false
			this.showTipDate = false
		},
		changeLandlordNo(e) {
			if (e === 'Add_New_Lanlord') {
				this.$dialog
					.confirm({
						title: '提示',
						message: '是否添加新房东？'
					})
					.then(_ => {
						this.formData.landlordNo = ''
						this.handlePlusLanlord()
					})
					.catch(_ => {
						this.formData.landlordNo = ''
					})
			}
		},
		// 添加房东
		handlePlusLanlord() {
			this.$emit('setInfo', this.getInfo())
			this.$router.push({
				name: 'LandlordList'
			})
		},
		changeCard(e) {
			console.log(e)
			if (e === 'Add_New_Card') {
				this.$dialog
					.confirm({
						title: '提示',
						message: '是否添加新银行卡？'
					})
					.then(_ => {
						this.formData.cardInfoNo = ''
						this.handlePlusCard()
					})
					.catch(_ => {
						this.formData.cardInfoNo = ''
					})
			}
		},
		// 添加银行卡
		handlePlusCard() {
			this.$emit('setInfo', this.getInfo())
			this.$router.push({
				name: 'CardList'
			})
		},
		// 短信验证码倒计时
		// handleTimeChange() {},
		// 提交
		// handleSubmit() {
		// 	this.$emit('submit')
		// }
		checkAmount() {
			const { amount } = this.formData
			const minAmount = 100
			const maxAmount = 20000
			// const boundaryAmount = Math.max(
			// 	Math.min(Number(amount), maxAmount),
			// 	minAmount
			// )
			// console.log(boundaryAmount)
			// this.formData.amount = boundaryAmount.toFixed(2)
			if (Number(amount) < minAmount || Number(amount) > maxAmount) {
				// this.$dialog.alert({
				// 	message: '付款金额范围仅限100.00 USD和 20,000.00 USD之间.'
				// })
				this.$toast('付款金额范围仅限100.00 USD和 20,000.00 USD之间.')
				return
			}
			return true
		},
		handleAmountBlur() {
			const { amount } = this.formData
			if (!amount) return
			if (!this.checkAmount()) return
			calculateCosts({
				amount,
				currency: 'USD'
			}).then(res => {
				this.formData.feeAmount = res.feeAmount
				this.formData.totalAmount = res.totalAmount
				this.formData.estimatedCnyAmt = amount * this.rateInfo.rateData
			})
		},
		getInfo() {
			const { formData } = this
			const landlordItem = this.landlorOptions.find(
				item => item.code === formData.landlordNo
			)
			const landlorName =
				landlordItem && landlordItem.cnName
					? landlordItem.cnName.split('/')[0]
					: ''
			const landlorNum =
				landlordItem && landlordItem.value ? landlordItem.value : ''

			const cardItem = this.cardOptions.find(
				item => item.code === formData.cardInfoNo
			)
			const cardName = cardItem && cardItem.cnName ? cardItem.cnName : ''
			return {
				rentFormInfo: {
					...formData,
					amountNum: numberFormat(formData.amount),
					landlorName,
					landlorNum,
					landlorCardTypeName: '银行账号',
					payoutMethodName: 'ACH',
					cardName,
					expectedSettleDate: this.expectedSettleDate,
					expectedSettleDateStr: this.expectedSettleDateStr,
					paymentItemName: '房租'
				},
				otherInfo: {
					...this.rateInfo,
					expectedSettleDate: this.expectedSettleDate,
					expectedSettleDateStr: this.expectedSettleDateStr
				}
			}
		},
		submit() {
			return new Promise((resolve, reject) => {
				const { bizOrder, formData } = this
				if (!formData.landlordNo) {
					this.$toast('请选择房东')
					reject(false)
					return
				}
				if (!formData.amount) {
					this.$toast('请输入金额')
					reject(false)
					return
				}
				if (!formData.cardInfoNo) {
					this.$toast('请选择付款银行卡')
					reject(false)
					return
				}
				if (!this.checkAmount()) {
					reject(false)
					return
				}
				resolve({
					bizOrder,
					...this.getInfo()
				})
			})
		}
	}
}
</script>

<style scoped lang="less">
/deep/ .form-item .label {
	min-width: 110px;
}
.flex {
	display: flex;
	align-items: center;
	// 下拉框
	.input-form {
		flex: 1;
	}
	.block {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: 10px;
		width: 40px;
		height: 40px;
		font-size: 24px;
		background: var(--main-08);
		border-radius: 4px;
		cursor: pointer;
	}
	// 提示
	.tip-wrapper {
		position: relative;
		z-index: 999;
		.overlay {
			position: absolute;
			top: 0;
			right: -10px;
			transform: translateY(32px);
			width: 214px;
			padding: 6px 14px;
			background: var(--main-02);
			border-radius: 4px;
			&::before {
				position: absolute;
				top: 0;
				right: 0;
				transform: translate(-16px, -11px);
				content: '';
				width: 0;
				height: 0;
				border: 6px solid transparent;
				border-bottom-color: var(--main-02);
			}
			.tip-text {
				font-size: 12px;
				color: var(--white);
				line-height: 20px;
			}
		}
	}
}
.reference-rate-wrapper {
	&.row {
		align-items: flex-start;
		/deep/ .label {
			line-height: 30px;
		}
		.reference-rate-container {
			position: relative;
			flex: 1;
			display: flex;
			justify-content: center;
			align-items: flex-start;
			margin-bottom: 25px;

			.flex {
				width: 100%;
				flex-wrap: wrap;
				.reference-rate {
					position: absolute;
					top: 30px;
					width: 100%;
					color: var(--green-01);
				}
			}
		}
	}
}
.ml-6 {
	margin-left: 6px;
}
.ml-10 {
	margin-left: 10px;
}
.flex-start {
	line-height: 24px;
	justify-content: flex-start;
}
.space-between {
	line-height: 24px;
	justify-content: space-between;
}
.icon {
	font-size: 24px;
	cursor: pointer;
}
.message-wrapper {
	margin-top: 20px;
	padding-top: 24px;
	border-top: 1px dashed var(--main-07);
	.message-tip {
		font-size: 12px;
		color: var(--main-04);
		line-height: 20px;
	}
}
</style>
