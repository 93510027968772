<template>
	<van-tabbar route>
		<van-tabbar-item to="/home">
			<span>卡中心</span>
			<template #icon="props">
				<van-icon
					size="24px"
					:name="
						props.active
							? require('assets/image/tabbar/cardActive.png')
							: require('assets/image/tabbar/card.png')
					" />
			</template>
		</van-tabbar-item>
		<van-tabbar-item
			to="/me"
			icon="contact">
			<span>我的</span>
			<template #icon="props">
				<van-icon
					size="24px"
					:name="
						props.active
							? require('@/assets/image/tabbar/userActive.png')
							: require('@/assets/image/tabbar/user.png')
					" />
			</template>
		</van-tabbar-item>
	</van-tabbar>
</template>

<script>
export default {
	name: 'Tabbar',
	data() {
		return {}
	},
	methods: {}
}
</script>

<style scoped lang="less"></style>
