<template>
	<div class="step-container">
		<div class="title">请添加 {{ userInfo.cstNameCn || '' }} 的银行卡</div>
		<VanValidForm @submit="handleSubmit">
			<VanValidFormItem label="银行卡号">
				<FieldInput
					v-model="formData.bankCardNo"
					type="digit"
					placeholder="输入信用卡号或借记卡号 (信用卡可提升成功率)"
					clearable />
			</VanValidFormItem>
			<div class="button">
				<van-button
					round
					block
					type="black"
					:disabled="!disabledBtn"
					:loading="submitLoading"
					native-type="submit">
					下一步
				</van-button>
			</div>
		</VanValidForm>
	</div>
</template>

<script>
import { bankCardInfo } from '@/views/card/api/index.js'
import { mapMutations, mapState } from 'vuex'

export default {
	name: 'CardAddSelfCardStep1Cache',
	data() {
		return {
			submitLoading: false,
			formData: {
				bankCardNo: ''
			},
			queryInfo: {}
		}
	},
	computed: {
		...mapState('app', ['userInfo']),
		...mapState('card', ['selfCardInfo']),
		disabledBtn() {
			const { bankCardNo } = this.formData
			return bankCardNo
		}
	},
	created() {
		this.queryInfo = this.$route.query
	},
	activated() {
		this.formData.bankCardNo = this.selfCardInfo.cardNum || ''
	},
	methods: {
		...mapMutations('card', ['SET_ALL']),
		handleSubmit() {
			this.submitLoading = true
			const { bankCardNo } = this.formData
			bankCardInfo({
				bankCardNo
			})
				.then(res => {
					this.SET_ALL({
						selfCardInfo: res
					})
					this.$router.push({
						name: 'CardAddSelfCardStep2'
					})
				})
				.catch(console.log)
				.finally(() => {
					this.submitLoading = false
				})
		}
	}
}
</script>

<style lang="less" scoped>
.title {
	margin-bottom: 20px;
}
.button {
	margin-top: 30px;
}
</style>
