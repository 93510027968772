<template>
	<div>
		<div class="info-wrapper">
			<Card
				type="content"
				:list="list"
				:info="rentFormInfo" />
			<van-button
				type="black"
				round
				class="submit-btn"
				@click="showDialog">
				{{ smsType === 'TRADE_VERIFY_CANCEL' ? '确认取消' : '确认账单' }}
			</van-button>
		</div>
		<PaymentSubmitDialog
			ref="dialog"
			:sms-type="smsType"
			@submit="handleSubmit" />
	</div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import {
	rentPaymentSubmit,
	// cancelPaymentSubmit,
	rentRemitAgain
} from '@/views/rent/api/index.js'
import PaymentSubmitDialog from './components/PaymentSubmitDialog'

export default {
	components: { PaymentSubmitDialog },
	data() {
		return {
			smsType: '',
			list: [
				{
					title: '银行收款账户信息',
					list: [
						{ label: '汇出方式', value: 'payoutMethodName' },
						{ label: '房东', value: 'landlorName' },
						{ label: '房东账号类型', value: 'landlorCardTypeName' },
						{ label: '房东账号', value: 'landlorNum' },
						{ label: '支付项目', value: 'paymentItemName' },
						{ label: '汇出金额', value: 'amountNum' },
						{ label: '费用', value: 'feeAmount', type: 'number' },
						{ label: '扣款总金额', value: 'totalAmount', type: 'number' },
						{ label: '付款银行卡', value: 'cardName' },
						{ label: '预估结算日期', value: 'expectedSettleDateStr' }
					]
				}
			],
			resInfo: {},
			// 获取结果用
			bizOrder: ''
		}
	},
	computed: {
		...mapState('app', ['userInfo']),
		...mapState('rent', ['rentFormInfo', 'otherInfo'])
	},
	created() {
		this.smsType = this.$route.query.type
		console.log(this.rentFormInfo)
	},
	methods: {
		...mapMutations('rent', ['INIT_RENT_FORM_INFO']),
		showDialog() {
			this.$refs.dialog.showDialog()
		},
		handleSubmit(formData) {
			const { retaData: referenceRate, expectedSettleDate } = this.otherInfo
			const data = {
				...this.$route.query,
				...this.rentFormInfo,
				...formData,
				referenceRate,
				expectedSettleDate
			}
			console.log(data)
			const loading = this.$toast.loading()
			let fn = null
			const { smsType } = this

			// if (this.smsType == 'TRADE_VERIFY_CANCEL') {
			// 	// 取消
			// 	fn = cancelPaymentSubmit
			// } else
			if (smsType == 'TRADE_VERIFY_REMIT_AGAIN') {
				// 换房东
				fn = rentRemitAgain
			} else {
				fn = rentPaymentSubmit
			}
			fn(data)
				.then(res => {
					const { cnName: message = '提交成功', bizOrder } = res
					if (smsType === 'TRADE_VERIFY_SUBMIT') {
						this.bizOrder = bizOrder
						this.initForm()
						this.$router.replace({
							name: 'RentPaymentResult',
							query: {
								bizOrder
							},
							params: {
								type: 'gopage'
							}
						})
					} else {
						this.$dialog
							.alert({
								title: '提示',
								message
							})
							.then(_ => {
								this.success()
							})
					}
				})
				.catch(console.error)
				.finally(_ => {
					loading.clear()
				})
		},

		initForm() {
			this.INIT_RENT_FORM_INFO()
		},
		success() {
			this.initForm()
			this.$router.push({
				name: 'Home'
			})
		}
	}
}
</script>
