<template>
	<div class="card-container">
		<slot name="header" />
		<template v-if="type === 'content'">
			<p
				v-if="title"
				class="title">
				{{ title }}
			</p>
			<ul class="card-list">
				<li
					v-for="(subInfo, arrIndex) in list"
					:key="arrIndex"
					class="card-item">
					<p
						v-if="subInfo.title"
						class="sub-title">
						{{ subInfo.title }}
					</p>
					<div
						v-for="(item, index) in subInfo.list"
						:key="index"
						class="card-item-row">
						<p class="card-item-row-label">{{ item.label }}</p>
						<p class="card-item-row-value">
							{{ transformValue(info, item.value, item) }}
						</p>
					</div>
				</li>
			</ul>
		</template>
		<template v-else-if="type === 'default'">
			<slot />
		</template>
	</div>
</template>

<script>
import { numberFormat } from '@/utils/utils'
export default {
	props: {
		// 支持 default/content
		type: {
			type: String,
			default: 'default'
		},
		// 在content模式下生效
		title: {
			type: String,
			default: ''
		},
		info: {
			type: Object,
			default: () => ({})
		},
		list: {
			type: Array,
			default() {
				return []
			}
		}
	},
	data() {
		return {}
	},
	methods: {
		transformValue(info, value, item) {
			if (value) {
				if (Array.isArray(value) && value.length) {
					return Object.values(value)
						.map(v => {
							if (item.numberFormatName && item.numberFormatName == v) {
								return numberFormat(info[v])
							} else {
								return info[v]
							}
						})
						.join(' ')
				}
				if (info[value]) {
					if (Array.isArray(info[value])) {
						return info[value].join('、')
					} else {
						if (item.type === 'number') {
							return numberFormat(info[value])
						}
						return info[value]
					}
				} else {
					return '-'
				}
			}
			return '-'
		}
	}
}
</script>

<style scoped lang="less">
.card-container {
	width: 100%;
	height: 100%;
	padding: 30px 16px;
	background: var(--white);
	border-radius: 20px;
	.title {
		margin-bottom: 30px;
		font-size: 20px;
		font-weight: bold;
		color: var(--main-01);
		text-align: center;
	}
	.card-list {
		width: 100%;
		.card-item {
			padding: 24px 0;
			border-bottom: 1px dashed var(--main-07);
			&:first-child {
				padding-top: 0;
			}
			&:last-child {
				padding-bottom: 0;
				border-bottom: none;
			}
			.sub-title {
				margin-bottom: 20px;
			}
			.card-item-row {
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				margin-bottom: 12px;
				font-size: 14px;
				&:last-child {
					margin-bottom: 0;
				}
				.card-item-row-label {
					max-width: 125px;
					color: var(--main-03);
					white-space: nowrap;
				}
				.card-item-row-value {
					max-width: calc(100% - 150px);
					line-height: 18px;
					word-break: break-all;
				}
			}
		}
	}
}
</style>
