const initData = {
	landlordNo: '',
	paymentItem: 'RENT',
	amount: '',
	payoutMethod: 'TELEGRAPHIC_TRANSFER',
	cardInfoNo: '',
	charPwd: '',
	code: '',
	feeAmount: '',
	// 总金额
	totalAmount: '',
	currency: 'USD'
}
export default {
	namespaced: true,
	state: {
		rentFormInfo: JSON.parse(JSON.stringify(initData)),
		rentDetailsInfo: {},
		// 汇率相关
		rateInfo: {}
	},
	mutations: {
		SET_ALL(state, data) {
			for (const k in data) {
				state[k] = data[k]
			}
		},
		INIT_RENT_FORM_INFO(state, data) {
			state.rentFormInfo = Object.assign({}, initData, data)
		}
	},
	actions: {}
}
