<template>
	<div class="payment-statement-container">
		<Card
			type="content"
			title="Payment Statement"
			:list="list"
			:info="paymentStatementDetail" />
		<van-button
			class="download-btn"
			type="black"
			round
			@click="handleDownload">
			Download PDF
		</van-button>
	</div>
</template>

<script>
import { getPaymentStatement, downloadPaymentOrder } from '@/views/rent/api'
import { downloadBlob } from '@/utils/utils'

export default {
	data() {
		return {
			bizOrder: '',
			paymentStatementDetail: {},
			list: [
				{
					list: [
						{ label: 'Creation Date:', value: 'createTimeStr' },
						{ label: 'User:', value: 'cstNameEn' },
						{ label: 'Payment Item:', value: 'paymentItem' },
						{ label: 'Amount:', value: ['payeeAmount', 'currency'] },
						{ label: 'Payment Method:', value: 'payeeReceiveMethodEn' }
					]
				},
				{
					list: [
						{ label: 'Payout Date:', value: 'payoutDate' },
						{ label: 'Success Date:', value: 'remitSuccessTimeStr' },
						{ label: 'Landlord:', value: 'payeeName' },
						{ label: 'Landlord Account:', value: 'payeeAccountNo' }
					]
				},
				{
					list: [
						{ label: 'Card Payment Date:', value: 'creditSuccessTimeStr' },
						{ label: 'Funding Source Card:', value: 'payerBankNo' },
						{ label: 'Funding Source Cardholder:', value: 'payerName' }
					]
				}
			]
		}
	},
	created() {
		const { bizOrder } = this.$route.query
		this.bizOrder = bizOrder
		this.getPaymentStatement()
	},
	methods: {
		getPaymentStatement() {
			const { bizOrder } = this
			return getPaymentStatement({
				bizOrder
			})
				.then(res => {
					if (res) {
						this.paymentStatementDetail = res
					}
				})
				.catch(console.error)
		},
		handleDownload() {
			const { bizOrder } = this
			const loading = this.$toast.loading()
			return downloadPaymentOrder({
				bizOrder
			})
				.then(res => {
					if (res) {
						downloadBlob(res, {
							filename: 'Payment_Statement',
							ext: 'pdf'
						})
					}
				})
				.catch(console.error)
				.finally(_ => {
					loading.clear()
				})
		}
	}
}
</script>

<style scoped lang="less">
.payment-statement-container {
	padding-bottom: 60px;
	.download-btn {
		margin-top: 30px;
	}
}
</style>
