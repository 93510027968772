<template>
	<div class="payment-container">
		<PaymentForm
			ref="form"
			:config="config"
			sms-type="TRADE_VERIFY_REMIT_AGAIN"
			@setInfo="setInfo">
			<van-button
				type="black"
				round
				class="submit-btn"
				@click="handleSubmit">
				确认付款
			</van-button>
		</PaymentForm>
	</div>
</template>

<script>
import { mapMutations } from 'vuex'
import PaymentForm from './components/PaymentForm'

export default {
	components: {
		PaymentForm
	},
	data() {
		return {
			config: {
				landlordNo: {
					disabled: false
				},
				paymentItem: {
					disabled: true
				},
				amount: {
					disabled: true
				},
				cardInfoNo: {
					disabled: true
				}
			}
		}
	},
	created() {},
	methods: {
		...mapMutations('rent', ['SET_ALL']),
		setInfo(data) {
			this.SET_ALL(data)
		},
		handleSubmit() {
			this.$refs.form
				.submit()
				.then(({ formData, otherInfo }) => {
					this.setInfo({
						rentFormInfo: formData,
						otherInfo
					})
					this.$router.push({
						name: 'RentPaymentConfirm',
						query: {
							...this.$route.query,
							type: 'TRADE_VERIFY_REMIT_AGAIN'
						}
					})
				})
				.catch(console.error)
		}
	}
}
</script>

<style scoped lang="less">
.payment-container {
	.submit-btn {
		margin-top: 20px;
	}
}
</style>
