export default [
	{
		name: 'Bills',
		path: '/bills',
		redirect: '/bills/payment-statement',
		meta: {
			title: '账单'
		},
		component: () => import('@/views/bills/Index'),
		children: [
			{
				name: 'BillsPaymentStatement',
				path: 'payment-statement',
				meta: {
					title: '支付凭证',
					navBarBg: 'grey'
				},
				component: () => import('@/views/bills/PaymentStatement')
			},
			{
				name: 'BillsPaymentDetail',
				path: 'payment-detail',
				meta: {
					title: '账单详情',
					navBarBg: 'grey'
				},
				component: () => import('@/views/bills/PaymentDetail')
			},
			{
				name: 'BillsPaymentCancelDetail',
				path: 'payment-cancel-detail',
				meta: {
					title: '账单详情',
					navBarBg: 'grey'
				},
				component: () => import('@/views/bills/PaymentCancelDetaill')
			}
		]
	}
]
