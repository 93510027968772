<template>
	<div class="payment-detail-container">
		<Card
			type="content"
			:list="list"
			:info="paymentDetail">
			<DetailsHeaderInfo
				slot="header"
				:info="paymentDetail" />
		</Card>
	</div>
</template>

<script>
import DetailsHeaderInfo from './components/DetailsHeaderInfo.vue'
import { getRentTradeOrderDetail } from '@/views/rent/api'

export default {
	components: { DetailsHeaderInfo },
	data() {
		return {
			bizOrder: '',
			paymentDetail: {},
			list: [
				{
					list: [
						{ label: '汇出方式：', value: 'payeeReceiveMethodEn' },
						{ label: '房东：', value: 'payeeName' },
						{ label: '房东账号类型', value: 'landlorCardTypeName' },
						{ label: '房东账号', value: 'landlordBankNo' },
						{
							label: '汇出金额',
							value: ['payeeAmount', 'currency']
							// numberFormatName: 'payeeAmount'
						},
						{
							label: '扣款总金额',
							value: ['payerAmount', 'currency']
							// numberFormatName: 'payerAmount'
						},
						{
							label: '费用：',
							value: ['serviceFee', 'currency']
							// numberFormatName: 'serviceFee'
						},
						{ label: '付款银行卡：', value: ['payerName', 'payerBankNo'] },
						{ label: '账单号：', value: 'bizOrder' },
						{ label: '创建时间：', value: 'createTimeStr' },
						{ label: '银行卡扣款时间：', value: 'creditSuccessTimeStr' },
						{ label: '结算时间：', value: 'payoutDate' },
						{ label: '结算完成时间：', value: 'remitSuccessTimeStr' }

						// 	{ label: '支付项目：', value: 'paymentItem' },
						// 	{ label: '房租金额：', value: ['payeeAmount', 'currency'] },
						// 	{ label: '扣款总金额：', value: ['payerAmount', 'currency'] }
					]
				}
				// {
				// 	list: [
				// 		{ label: '创建时间：', value: 'createTimeStr' },
				// 		{ label: '状态：', value: 'statusCn' },
				// 		{ label: '银行卡扣款时间：', value: 'creditSuccessTimeStr' },
				// 		{ label: '汇出时间：', value: 'payoutDate' },
				// 		{ label: '汇出完成时间：', value: 'remitSuccessTimeStr' }
				// 	]
				// }
			]
		}
	},
	created() {
		const { bizOrder } = this.$route.query
		this.bizOrder = bizOrder
		this.getRentTradeOrderDetail()
	},
	methods: {
		getRentTradeOrderDetail() {
			const { bizOrder } = this
			return getRentTradeOrderDetail({
				bizOrder
			})
				.then(res => {
					if (res) {
						this.paymentDetail = { ...res, landlorCardTypeName: '银行账号' }
					}
				})
				.catch(console.error)
		}
	}
}
</script>

<style scoped lang="less"></style>
