export default [
	{
		path: '/card',
		name: 'Card',
		meta: {
			title: '银行卡'
		},
		redirect: 'card/list',
		component: () => import('@/views/card/index.vue'),
		children: [
			{
				path: 'list',
				name: 'CardList',
				meta: {
					title: '银行卡'
				},
				component: () => import('@/views/card/List.vue')
			},
			{
				path: 'details',
				name: 'CardDetails',
				meta: {
					title: '银行卡'
				},
				component: () => import('@/views/card/Details.vue')
			},
			{
				path: 'add-add-valide',
				name: 'CardAddValide',
				meta: {
					title: '添加银行卡'
				},
				component: () => import('@/views/card/AddValide.vue')
			},
			{
				path: 'add-type-checked',
				name: 'CardAddTypeChecked',
				meta: {
					title: '添加银行卡'
				},
				component: () => import('@/views/card/AddTypeChecked.vue')
			},
			{
				path: 'add-self-card',
				name: 'CardAddSelfCard',
				meta: {
					title: '添加银行卡'
				},
				redirect: 'add-self-card/add-self-card-step-1',
				component: () => import('@/views/card/selfCard/index.vue'),
				children: [
					{
						path: 'add-self-card-step-1',
						name: 'CardAddSelfCardStep1',
						meta: {
							title: '添加银行卡'
						},
						component: () => import('@/views/card/selfCard/Step/Step1.vue')
					},
					{
						path: 'add-self-card-step-2',
						name: 'CardAddSelfCardStep2',
						meta: {
							title: '添加银行卡'
						},
						component: () => import('@/views/card/selfCard/Step/Step2.vue')
					}
				]
			},
			{
				path: 'add-other-person-card',
				name: 'CardAddOtherPersonCard',
				meta: {
					title: '添加银行卡'
				},
				redirect: 'add-other-person-card/add-other-person-card-step-1',
				component: () => import('@/views/card/otherPersonCard/index.vue'),
				children: [
					{
						path: 'add-other-person-card-step-1',
						name: 'CardAddOtherPersonCardStep1',
						meta: {
							title: '添加银行卡'
						},
						component: () =>
							import('@/views/card/otherPersonCard/Step/Step1.vue'),
						children: []
					},
					{
						path: 'add-other-person-card-step-2',
						name: 'CardAddOtherPersonCardStep2',
						meta: {
							title: '添加银行卡'
						},
						component: () =>
							import('@/views/card/otherPersonCard/Step/Step2.vue'),
						children: []
					},
					{
						path: 'add-other-person-card-step-3',
						name: 'CardAddOtherPersonCardStep3',
						meta: {
							title: '添加银行卡'
						},
						component: () =>
							import('@/views/card/otherPersonCard/Step/Step3.vue'),
						children: []
					}
				]
			},
			{
				path: 'protocol',
				name: 'CardProtocol',
				meta: {
					title: '协议',
					hideNavBarRight: true
				},
				component: () => import('@/components/protocol/Protocol.vue')
			}
		]
	}
]
