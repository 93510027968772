export default [
	{
		name: 'Landlord',
		path: '/landlord',
		redirect: '/landlord/list',
		meta: {
			title: '房东列表'
		},
		component: () => import('@/views/landlord/Index'),
		children: [
			{
				name: 'LandlordList',
				path: '/landlord/list',
				meta: {
					title: '房东列表',
					hideNavBarRight: true
				},
				component: () => import('@/views/landlord/List')
			},
			{
				name: 'LandlordDetail',
				path: '/landlord/detail',
				meta: {
					title: '房东信息'
				},
				component: () => import('@/views/landlord/Detail')
			},
			{
				name: 'LandlordOperationStep1',
				path: '/landlord/operation/step1',
				meta: {
					title: '填写房东信息'
				},
				component: () => import('@/views/landlord/operation/Step1')
			},
			{
				name: 'LandlordOperationStep2',
				path: '/landlord/operation/step2',
				meta: {
					title: '填写房东信息'
				},
				component: () => import('@/views/landlord/operation/Step2')
			},
			{
				name: 'LandlordOperationStep3',
				path: '/landlord/operation/step3',
				meta: {
					title: '填写房东信息'
				},
				component: () => import('@/views/landlord/operation/Step3')
			},
			{
				name: 'LandlordOperationStep4',
				path: '/landlord/operation/step4',
				meta: {
					title: '确认房东信息'
				},
				component: () => import('@/views/landlord/operation/Step4')
			},
			{
				name: 'LandlordOperationResult',
				path: '/landlord/operation/result',
				meta: {
					title: '提交结果'
				},
				component: () => import('@/views/landlord/operation/Result')
			}
		]
	}
]
