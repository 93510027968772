import axios from 'axios'
import qs from 'qs'
import { Dialog, Toast } from 'vant'
import store from '@/store'
import router from '@/router'
import { forOwn } from 'lodash-es'
import { checkIsJSON } from '@/utils/utils'
import { CryptoHelper } from '@cbibank/axios'
import secure from '@/utils/http/secure'

// 定义配置
const { encryptRequest, decryptResponse } = new CryptoHelper({
	// 必传
	requestSource: 'cbimart-app',
	// PROD环境下必传或者通过函数自定义配置
	config: {
		key: '7e471aa7248a2d0c',
		iv: '9343f8bacfe235be'
	}
})

const toast = function (message) {
	return Toast({
		message,
		duration: 2500
	})
}
const alert = function (message) {
	return Dialog.alert({
		title: '提示',
		message
	})
}

const service = axios.create({
	// 项目采用的是history路由 BASE_URL会与publicPath保持一致
	baseURL: process.env.BASE_URL,
	timeout: 60000, // 请求超时
	retryTimes: 3, // 重发次数
	retryDelay: 600 // 重发间隔
})

// request拦截器
service.interceptors.request.use(
	request => {
		// 错误重试直接复用上一个请求
		if (request.isRetry) {
			return request
		}
		const {
			userInfo: { sessionId }
		} = store.state.app
		if (sessionId) {
			request.headers['x-session-id'] = sessionId
		}
		// request.headers.source = 'H5_VC'
		// request.headers['Accept-Language'] = 'zhs-CN'
		request.headers['request-source'] = 'cbimart-app'
		// if (request.method === 'post' && request.data) {
		// 	request.data.channel = '1201'
		// }
		// if (request.method === 'get' && request.params) {
		// 	request.params.channel = '1201'
		// }
		request = encryptRequest(request)
		return request
	},
	error => {
		console.warn(error) // for debug
		Promise.reject(error)
	}
)

// response 拦截器
service.interceptors.response.use(
	async response => {
		response = decryptResponse(response)
		const { data } = response
		if (data.constructor === Blob) {
			if (data.text() && checkIsJSON(await data.text())) {
				const text = JSON.parse(await data.text())
				if (text.retCode !== '0000') {
					toast(text.retMsg)
					// eslint-disable-next-line prefer-promise-reject-errors
					return Promise.reject(false)
				}
			}
			return data
		} else {
			// 白名单内状态码直接返回
			const whiteList = ['P1056']
			if (data.retCode === '0000') {
				return data.body || true
			} else if (data && ['20000', 'NOT_LOGIN'].includes(data.retCode)) {
				store.commit('app/CLEAR_ALL_INFO')
				toast('登录超时，请重新登录')
				router.push({
					name: 'Login'
				})
				return Promise.reject(data)
			} else if (whiteList.includes(data.retCode)) {
				return Promise.reject(data)
			} else {
				// toast(data.retMsg || data.em, data.ec === 'TO_APP_LOGIN')
				alert(data.retMsg || data.em, data.ec === 'TO_APP_LOGIN')
				return Promise.reject(data)
			}
		}
	},
	error => {
		const errorFn = () => {
			if (error.message.includes('timeout')) {
				toast('Network timeout')
				return Promise.reject(error)
			}
			toast(error.message || '系统开小差')
			return Promise.reject(error)
		}
		const config = error.config
		// 如果没有配置 重发次数 则不处理
		if (!config || !config.retryTimes) {
			return errorFn()
		}
		// _retryCount 用来记录重发了几次
		let { _retryCount = 0, retryTimes, retryDelay } = config
		config._retryCount = _retryCount
		// 如果 重复次数到达设置的值 则 报错
		if (config._retryCount >= retryTimes) {
			return errorFn()
		}
		// 重复次数自增
		config._retryCount++
		// 利用 Promise  立即执行  setTimeout 中的代码
		// 也就是 固定间隔重发  resolve 是 .then 传递进来的函数
		// 也就是  固定间隔 执行 instance（config） 来重发请求
		// 我配置了 现在instance  就是axios
		const backOff = new Promise(resolve => {
			setTimeout(() => {
				resolve()
			}, retryDelay)
		})
		// 调用axios 重发
		return backOff.then(() => {
			return service({
				...config,
				isRetry: true
			})
		})
	}
)

// 由于axios的get和post方法传递参数有差异 进行二次封装下
const get = (url, params, config) => {
	return service({
		url,
		method: 'get',
		params,
		// 由于部分后端接口的get形式需要声明'Content-Type': 'application/json'，这里默认传一个data，axios会默认设置JSON。而如果不传data的话，axios会默认把自定义的Content-Type删除
		data: {},
		...config
	})
}
const post = (url, params = {}, config = {}, dataType = 'JSON') => {
	// 密码脱敏和交易签名
	let data = secure.transformPostParams(params, config)
	// 这里做一层FormData URLEncoded兼容判断
	if (dataType === 'URLEncoded') {
		// 利用qs对参数进行编码，并设置Content-Type为application/x-www-form-urlencoded
		if (Object.values(params).some(Array.isArray)) {
			// 存在值为数组的参数
			let queryString = ''
			forOwn(params, (value, key) => {
				if (Array.isArray(value)) {
					value.forEach(subValue => {
						queryString += `${encodeURIComponent(key)}=${encodeURIComponent(
							subValue
						)}&`
					})
				} else {
					queryString += `${encodeURIComponent(key)}=${encodeURIComponent(
						value
					)}&`
				}
			})
			// 去除末尾&
			data = queryString.slice(0, -1)
		} else {
			data = qs.stringify(params)
		}

		const headers = config.headers || {}
		config = {
			...config,
			headers: {
				...headers,
				'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
			}
		}
	}
	if (dataType === 'FormData') {
		data = new FormData()
		for (const k in params) {
			data.append(k, params[k])
		}
	}
	return service({
		url,
		method: 'post',
		data,
		...config
	})
}
// 文件
const file = (url, formData, config) => {
	return service({
		url,
		method: 'post',
		// 如果是formData形式的post请求 注意这里的参数形式。因为new FormData()后即使执行append，实例formData依然是一个空对象。如果利用上面es6的语法，参数data自然也是空对象。理想情况应该是file：(binary)。
		data: formData,
		...config
	})
}

export default {
	get,
	post,
	file
}
