<template>
	<div class="active-container">
		<div
			v-if="faceResult === 'OK'"
			class="face-container">
			<FaceAuth
				status="SUCCESS"
				:info="info"
				@start="handleStart" />
		</div>
		<div
			v-else-if="faceResult === 'FAILED'"
			class="face-container">
			<FaceAuth
				status="FAILED"
				:info="info"
				@start="handleStart">
				<div
					slot="btnList"
					class="button-list">
					<van-button
						round
						type="black"
						class="btn"
						:loading="submitLoading"
						@click="handleStart">
						重新授权
					</van-button>
				</div>
			</FaceAuth>
		</div>
		<div
			v-else-if="faceResult === 'UNBIND'"
			class="face-container">
			<FaceAuth
				status="UNBIND"
				:info="info">
				<div
					slot="btnList"
					class="button-list">
					<van-button
						round
						type="black"
						class="btn"
						@click="handleLink">
						进入官网
					</van-button>
				</div>
			</FaceAuth>
		</div>

		<div v-else>
			<SvgIcon
				name="loading-animate"
				class="loading" />
		</div>
		<!-- <div
			v-else
			class="face-container">
			<FaceAuth
				status="INITIAL"
				:info="info"
				@start="handleStart" />
		</div> -->
	</div>
</template>

<script>
export default {
	data() {
		return {
			bizNo: '',
			fieldType: '',
			// 人脸识别是否通过
			faceResult: '',
			loading: false,
			// 人脸剩余次数
			residueNum: 3,
			submitLoading: false
		}
	},
	computed: {
		info() {
			return {
				// 人脸认证
				INITIAL: {
					img: 'successPic',
					title: '人脸识别',
					desc: '请正脸对准框内，并保持光线充足！',
					btnText: '马上开始'
				},
				// 人脸认证失败
				FAILED: {
					img: 'failedPic',
					title: '授权失败',
					desc: '人脸校验未通过，请重新授权',
					btnText: ''
				},
				// 修改成功
				SUCCESS: {
					img: 'successPic',
					title: '授权完成',
					desc: `${this.queryInfo.name} 已可以使用您的银行卡缴纳租赁房屋所产生的费用`,
					btnText: ''
				},
				// 修改成功
				UNBIND: {
					img: 'distancePic',
					title: '申请人已解除绑定',
					desc: `可进入官网了解更多信息`,
					btnText: ''
				}
			}
		}
	},
	created() {
		this.queryInfo = this.$route.query
		const { bizNo, authorizeKey, faceResult } = this.queryInfo
		if (faceResult === 'UNBIND') {
			this.faceResult = faceResult
			return
		}
		this.bizNo = bizNo
		if (bizNo) {
			this.loading = true
			this.$face
				.getResult({
					authorizeKey,
					bizNo
				})
				.then(res => {
					if (res) {
						const { faceResult, residueNum } = res
						this.residueNum = residueNum
						// if (faceResult === 'OK') {
						// 	this.submit(faceResult)
						// } else {
						// 	this.loading = false
						// 	this.faceResult = faceResult
						// }
						this.loading = false
						this.faceResult = faceResult
					}
				})
				.catch(err => {
					console.log(err)
					this.loading = false
				})
		}
	},

	methods: {
		handleStart() {
			this.submitLoading = true
			const { authorizeKey } = this.queryInfo
			this.$face.start('NOT_LOGIN', {
				authorizeKey,
				businessType: 'AGENT_PAYEE_FACE',
				returnUrl: `${location.origin}/face-validate/card-holder-result`
			})
			this.submitLoading = false
		},
		handleLink() {
			window.location.href = 'https://www.cbimart.com/'
		}
	}
}
</script>

<style scoped lang="less">
.active-container {
	display: flex;
	min-height: calc(100vh - var(--navbar-height));
	justify-content: center;
	padding: 60px 24px;
	background-color: var(--white);
	.face-container {
		width: 100%;
	}
	.loading {
		font-size: 100px;
	}
}
</style>
