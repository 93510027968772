<template>
	<div class="confirm-container">
		<Card
			type="content"
			:list="list"
			:info="queryInfo" />

		<div class="confirm-checkbox">
			<van-checkbox v-model="checked">
				<span>
					本人声明，我与绑卡申请人是近亲属关系【父母与子女，（外）祖父母与（外）孙子或孙女，兄弟姐妹或配偶】。本人已阅读井同意
				</span>
				<span
					class="protocol-link"
					@click.stop="clickProtocol()">
					《CBiMart绑卡支付协议》
				</span>
				<span>，授权绑卡申请人使用银行卡用于平台代缴费。</span>
				<template #icon="props">
					<van-image
						width="14px"
						height="14px"
						fit="cover"
						:src="
							props.checked
								? require('@/assets/image/common/checked-active.png')
								: require('@/assets/image/common/checked.png')
						" />
				</template>
			</van-checkbox>
		</div>
		<van-button
			class="confirm-btn"
			type="black"
			round
			:disabled="!disabledBtn"
			@click="handleConfirm">
			确认授权
		</van-button>
	</div>
</template>

<script>
import commonApi from '@/api/common'

export default {
	data() {
		return {
			checked: true,
			fileUrl: '',
			queryInfo: {},
			list: [
				{
					list: [
						{ label: '银行卡号', value: 'bankCardNo' },
						{ label: '所属银行', value: 'bankName' },
						{ label: '持卡人', value: 'cardHolderName' },
						{ label: '银行卡使用人', value: 'cstNameCn' }
					]
				}
			]
		}
	},
	computed: {
		disabledBtn() {
			return this.checked
		}
	},
	created() {
		this.queryInfo = this.$route.query
		this.getProtocol()
	},
	methods: {
		getProtocol() {
			commonApi
				.getProtocol({
					bizType: 'BANK_CARD_AGENT_PAYOR_PAYMENT_PROTOCOL'
				})
				.then(res => {
					this.fileUrl = res[0].fileUrl
				})
				.catch(console.log)
				.finally(() => {})
		},
		clickProtocol() {
			this.$router.push({
				name: 'Protocol',
				query: {
					url: encodeURIComponent(this.fileUrl),
					name: '绑卡支付协议'
				}
			})
		},
		handleConfirm() {
			this.$face.start('NOT_LOGIN', {
				authorizeKey: this.queryInfo.authorizeKey,
				businessType: 'AGENT_PAYEE_FACE',
				returnUrl: `${location.origin}/face-validate/card-holder-result`
			})
		}
	}
}
</script>

<style lang="less" scoped>
.confirm-container {
	padding: 14px 24px;
	.protocol-link {
		color: var(--primary-90);
	}
	.van-checkbox {
		display: flex;
		:deep(.van-checkbox__icon) {
			display: flex;
			align-items: center;
			top: 2px;
		}
	}
	.confirm-checkbox {
		margin-top: 20px;
	}
	.confirm-btn {
		margin-top: 20px;
	}
}
</style>
