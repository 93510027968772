<template>
	<div class="landlord-result">
		<div class="status-img">
			<img
				src="@/assets/image/common/img-success.png"
				alt="" />
		</div>
		<p class="title">提交成功</p>
		<p class="desc">房东信息已提交成功，我们将在三个工作日检查相关信息。</p>
		<van-button
			type="black"
			round
			class="add-btn"
			@click="handleAdd">
			添加新房东
		</van-button>
		<van-button
			type="black"
			plain
			round
			class="back-btn"
			@click="handleBack">
			返回房东列表
		</van-button>
	</div>
</template>

<script>
export default {
	data() {
		return {}
	},
	methods: {
		handleAdd() {
			this.$router.push({
				name: 'LandlordOperationStep1'
			})
		},
		handleBack() {
			this.$router.push({
				name: 'LandlordList'
			})
		}
	}
}
</script>

<style scoped lang="less">
.landlord-result {
	text-align: center;
	.status-img {
		margin: 60px auto 30px;
		width: 100px;
		height: 100px;
	}
	.title {
		margin-bottom: 16px;
		font-size: 20px;
		font-weight: bold;
		color: var(--main-01);
		line-height: 20px;
	}
	.desc {
		margin-bottom: 30px;
		padding: 0 30px;
		font-size: 14px;
		color: var(--main-02);
		line-height: 24px;
	}
	.add-btn {
		margin-bottom: 20px;
	}
}
</style>
