<template>
	<van-dialog
		v-model="visible"
		class-name="submit-dialog"
		:get-container="getContainer"
		overlay-class="submit-overlay"
		:show-confirm-button="false"
		:close-on-click-overlay="false"
		@close="closeDialog">
		<div class="dialog-container">
			<div
				class="close-btn"
				@click="visible = false">
				<SvgIcon name="close" />
			</div>
			<VanValidForm>
				<VanValidFormItem label="密码">
					<FieldInput
						v-model="formData.charPwd"
						clearable
						type="password"
						maxlength="20"
						autocomplete="off"
						:allow-space="false"
						:capitalize="false"
						valid-type=""
						placeholder="请输入密码" />
				</VanValidFormItem>
			</VanValidForm>
			<van-button
				type="black"
				round
				class="submit-btn"
				@click="handleSubmit">
				确认解绑
			</van-button>
		</div>
	</van-dialog>
</template>

<script>
export default {
	props: {
		smsType: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			visible: false,
			formData: {
				charPwd: ''
			}
		}
	},
	computed: {},
	methods: {
		closeDialog() {
			this.visible = false
			this.formData.charPwd = ''
		},
		showDialog() {
			this.visible = true
		},
		getContainer() {
			return document.querySelector('#app')
		},
		submit() {
			return new Promise((resolve, reject) => {
				const { formData } = this
				resolve({
					formData
				})
			})
		},
		handleSubmit() {
			const { formData } = this
			if (!formData.charPwd) {
				this.$toast('请输入密码')
				return
			}
			this.$emit('submit', this.formData)
		}
	}
}
</script>
<style lang="less" scoped>
.submit-overlay {
	position: absolute;
}
.close-btn {
	position: absolute;
	right: 20px;
	top: 15px;
	font-size: 16px;
	cursor: pointer;
}
.submit-dialog {
	position: absolute;
	padding: 30px 20px;
	// 验证码
	/deep/.input-timeout {
		.timeout-btn {
			height: 40px;
		}
	}
}
</style>
