<template>
	<div
		class="navbar-container"
		:class="{
			'navbar-padding-top': !$route.meta.hideNavBar,
			'navbar-grey': $route.meta.navBarBg === 'grey',
			'navbar-black': $route.meta.navBarBg === 'black'
		}">
		<van-nav-bar
			v-if="!$route.meta.hideNavBar"
			:title="$route.meta.title"
			fixed
			:border="false"
			@click-left="handleClickLeft"
			@click-right="handleClickRight">
			<template #left>
				<SvgIcon
					v-if="!$route.meta.hideNavBarLeft"
					name="arrow-left"
					class="icon" />
			</template>
			<template #right>
				<SvgIcon
					v-if="!$route.meta.hideNavBarRight"
					name="home"
					class="icon" />
			</template>
		</van-nav-bar>
	</div>
</template>

<script>
export default {
	name: 'NavBar',
	data() {
		return {}
	},
	methods: {
		handleClickLeft() {
			if (window.history.length <= 1) {
				this.$router.push({
					name: 'Home'
				})
			} else {
				this.$router.go(-1)
			}
		},
		handleClickRight() {
			this.$router.push({
				name: 'Home'
			})
		}
	}
}
</script>

<style scoped lang="less">
.navbar-container {
	max-width: var(--max-layout-width);
	&.navbar-padding-top {
		padding-top: var(--navbar-height);
	}
	&.navbar-grey {
		.van-nav-bar {
			background-color: var(--black-5);
		}
	}
	&.navbar-black {
		.van-nav-bar {
			background-color: var(--main-01);
			color: #fff;
		}
	}
	/deep/.van-nav-bar {
		.van-nav-bar__title {
			font-weight: bold;
		}
	}
	.icon {
		font-size: 24px;
	}
}
</style>
