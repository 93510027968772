<template>
	<van-dialog
		v-model="visible"
		class-name="time-progress-dialog"
		:get-container="getContainer"
		:show-confirm-button="false"
		:close-on-click-overlay="false"
		@close="closeDialog">
		<div
			v-if="title"
			class="title">
			{{ title }}
		</div>
		<div class="progress-contianer">
			<div
				class="progress"
				:class="[
					'progress--' + type,
					{
						'progress--without-text': !showText,
						'progress--text-inside': textInside
					}
				]"
				role="progressbar"
				:aria-valuenow="percentage"
				aria-valuemin="0"
				aria-valuemax="100">
				<div
					v-if="type === 'line'"
					class="progress-bar">
					<div
						class="progress-bar__outer"
						:style="{
							height: strokeWidth + 'px',
							backgroundColor: defineBackColor
						}">
						<div
							class="progress-bar__inner"
							:style="barStyle">
							<div
								v-if="showText && textInside"
								class="progress-bar__innerText"
								:style="{ color: textColor }">
								{{ content }}
							</div>
						</div>
					</div>
				</div>
				<div
					v-else
					class="progress-circle"
					:style="{ height: width + 'px', width: width + 'px' }">
					<svg viewBox="0 0 100 100">
						<path
							class="progress-circle__track"
							:d="trackPath"
							:stroke="defineBackColor"
							:stroke-width="relativeStrokeWidth"
							fill="none"
							:style="trailPathStyle" />
						<path
							class="progress-circle__path"
							:d="trackPath"
							:stroke="stroke"
							fill="none"
							:stroke-linecap="strokeLinecap"
							:stroke-width="percentage ? relativeStrokeWidth : 0"
							:style="circlePathStyle" />
					</svg>
				</div>
				<div
					v-if="showText && !textInside"
					class="progress-text-container">
					<div
						class="progress-text"
						:style="{ fontSize: progressTextSize + 'px', color: textColor }">
						{{ content }}
					</div>
				</div>
			</div>
		</div>
	</van-dialog>
</template>
<script>
export default {
	name: 'ElProgress',
	props: {
		time: {
			type: Number,
			default: 5
		},
		title: {
			type: String,
			default: ''
		},
		type: {
			type: String,
			default: 'circle',
			validator: val => ['line', 'circle', 'dashboard'].indexOf(val) > -1
		},
		strokeWidth: {
			type: Number,
			default: 6
		},
		strokeLinecap: {
			type: String,
			default: 'round'
		},
		textInside: {
			type: Boolean,
			default: false
		},
		width: {
			type: Number,
			default: 126
		},
		showText: {
			type: Boolean,
			default: true
		},
		color: {
			type: [String, Array, Function],
			default: ''
		},
		defineBackColor: {
			type: [String, Array, Function],
			default: '#fff'
		},
		textColor: {
			type: [String, Array, Function],
			default: '#1460FF'
		},
		format: Function
	},
	data() {
		return {
			visible: false,
			percentage: 0,
			initTime: 5,
			timer: null
		}
	},
	computed: {
		barStyle() {
			const style = {}
			style.width = this.percentage + '%'
			style.backgroundColor = this.getCurrentColor(this.percentage)
			return style
		},
		relativeStrokeWidth() {
			return ((this.strokeWidth / this.width) * 100).toFixed(1)
		},
		radius() {
			if (this.type === 'circle' || this.type === 'dashboard') {
				return parseInt(50 - parseFloat(this.relativeStrokeWidth) / 2, 10)
			} else {
				return 0
			}
		},
		trackPath() {
			const radius = this.radius
			const isDashboard = this.type === 'dashboard'
			return `
          M 50 50
          m 0 ${isDashboard ? '' : '-'}${radius}
          a ${radius} ${radius} 0 1 1 0 ${isDashboard ? '-' : ''}${radius * 2}
          a ${radius} ${radius} 0 1 1 0 ${isDashboard ? '' : '-'}${radius * 2}
          `
		},
		perimeter() {
			return 2 * Math.PI * this.radius
		},
		rate() {
			return this.type === 'dashboard' ? 0.75 : 1
		},
		strokeDashoffset() {
			const offset = (-1 * this.perimeter * (1 - this.rate)) / 2
			return `${offset}px`
		},
		trailPathStyle() {
			return {
				strokeDasharray: `${this.perimeter * this.rate}px, ${this.perimeter}px`,
				strokeDashoffset: this.strokeDashoffset
			}
		},
		circlePathStyle() {
			return {
				strokeDasharray: `${
					this.perimeter * this.rate * (this.percentage / 100)
				}px, ${this.perimeter}px`,
				strokeDashoffset: this.strokeDashoffset,
				transition: 'stroke-dasharray 1s ease 0s, stroke 1s ease'
			}
		},
		stroke() {
			let ret
			if (this.color) {
				ret = this.getCurrentColor(this.percentage)
			} else {
				ret = '#1460FF'
			}
			return ret
		},
		progressTextSize() {
			return this.type === 'line' ? 12 + this.strokeWidth * 0.4 : 46
		},
		content() {
			if (typeof this.format === 'function') {
				return this.format(this.percentage) || ''
			} else {
				return this.initTime
			}
		}
	},
	watch: {
		time: {
			immediate: true,
			handler(val) {
				this.initTime = val
			}
		}
	},
	methods: {
		showDialog() {
			this.visible = true
			this.start()
		},
		closeDialog() {
			this.visible = false
		},
		getContainer() {
			return document.querySelector('#app')
		},

		start() {
			this.timer = setInterval(() => {
				this.countdown()
			}, 1000)
		},
		countdown() {
			if (this.initTime <= 0) {
				clearInterval(this.timer)
				this.$emit('finish')
			} else {
				this.percentage += 100 / this.time
				this.initTime -= 1
			}
		},
		getCurrentColor(percentage) {
			if (typeof this.color === 'function') {
				return this.color(percentage)
			} else if (typeof this.color === 'string') {
				return this.color
			} else {
				return this.getLevelColor(percentage)
			}
		},
		getLevelColor(percentage) {
			const colorArray = this.getColorArray().sort(
				(a, b) => a.percentage - b.percentage
			)

			for (let i = 0; i < colorArray.length; i++) {
				if (colorArray[i].percentage > percentage) {
					return colorArray[i].color
				}
			}
			return colorArray[colorArray.length - 1].color
		},
		getColorArray() {
			const color = this.color
			const span = 100 / color.length
			return color.map((seriesColor, index) => {
				if (typeof seriesColor === 'string') {
					return {
						color: seriesColor,
						percentage: (index + 1) * span
					}
				}
				return seriesColor
			})
		}
	}
}
</script>
<style lang="less" scoped>
.time-progress-dialog {
	width: 305px;
	height: 232px;
	padding: 30px 30px 40px;
	.title {
		font-weight: 400;
		font-size: 16px;
		color: #131523;
		line-height: 16px;
		text-align: center;
		margin-bottom: 32px;
	}
	.progress-contianer {
		text-align: center;
		.progress {
			position: relative;
			display: inline-block;
			&.progress--circle {
				.progress-text-container {
					position: absolute;
					top: 50%;
					left: 50%;
					width: 90%;
					height: 90%;
					display: flex;
					justify-content: center;
					align-items: center;
					transform: translate(-50%, -50%);
					border: 8px solid #dae6ff;
					border-radius: 100%;
					.progress-text {
						width: 100%;
						height: 100%;
						display: flex;
						justify-content: center;
						align-items: center;
						box-shadow: 0px 0px 10px 0px rgba(56, 63, 124, 0.22);
						border-radius: 100%;
					}
				}
			}
		}
	}
}
</style>
