<template>
	<div class="add-valide-container">
		<div class="title">请输入密码，进行身份验证</div>
		<VanValidForm>
			<VanValidFormItem label="">
				<InputPassword
					v-model="formData.charPwd"
					clearable
					size="large"
					maxlength="20"
					placeholder="请输入6-20位数字和字母的登录密码" />
			</VanValidFormItem>
			<div class="button">
				<van-button
					round
					block
					type="black"
					:disabled="formData.charPwd.length < 6"
					@click="handleValidate">
					下一步
				</van-button>
			</div>
		</VanValidForm>
	</div>
</template>
<script>
import { bindCardCheckCharPwd } from '@/views/card/api/index.js'

export default {
	data() {
		return {
			formData: {
				charPwd: ''
			}
		}
	},
	methods: {
		handleValidate() {
			const { charPwd } = this.formData
			const loading = this.$toast.loading()
			bindCardCheckCharPwd({
				charPwd
			})
				.then(res => {
					this.$toast('验证成功')
					this.$router.replace({
						name: 'CardAddTypeChecked'
					})
				})
				.catch(console.log)
				.finally(() => {
					loading.clear()
				})
		}
	}
}
</script>
<style lang="less" scoped>
.add-valide-container {
	height: calc(100vh - var(--navbar-height));
	background: var(--white);

	padding: 80px 24px 0;
	.title {
		font-size: 18px;
		line-height: 18px;
		text-align: center;
		margin-bottom: 30px;
	}
	.button {
		margin-top: 30px;
	}
}
</style>
