<template>
	<div class="kyc-index-page">
		<div class="kyc-index-icon">
			<van-image :src="require('@/assets/image/kyc/kyc_index_icon.png')" />
		</div>
		<div class="kyc-wrapper">
			<div class="kyc-index-content">
				<div class="kyc-index-content-subtitle">
					<span>全球缴费，不占用外汇额度</span>
				</div>
			</div>
			<div class="kyc-index-btn">
				<van-button
					type="red"
					round
					@click="toOpen">
					立即体验
				</van-button>
			</div>
			<div class="logo">
				<van-image
					fit="cover"
					width="169px"
					height="38px"
					lazy-load
					:src="require('assets/image/logo.png')">
					<template #loading>
						<van-loading
							type="spinner"
							size="20" />
					</template>
				</van-image>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {}
	},
	methods: {
		toOpen() {
			this.$router.replace({
				name: 'KycBaseInfo'
			})
		}
	}
}
</script>

<style scoped lang="less">
.kyc-index-page {
	height: 100vh;
	padding-bottom: 90px;
	position: relative;
	&:before {
		content: '';
		position: absolute;
		background: url('~@/assets/image/kyc/kyc_index_bg.png');
		width: 100%;
		height: 100%;
	}
}
.kyc-index-icon {
	//padding-bottom: 40px;
}
.kyc-wrapper {
	position: absolute;
	left: 0;
	// bottom: 60px;
	top: 68%;

	width: 100%;
	.kyc-index-content {
		text-align: center;
		.kyc-index-content-subtitle {
			// padding-top: 30px;
			font-size: 20px;
			line-height: 20px;
			text-align: center;
		}
	}
	.kyc-index-btn {
		padding: 24px 24px 0;
	}
	.logo {
		text-align: center;
		margin-top: 35px;
	}
}
</style>
