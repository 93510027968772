export default {
	namespaced: true,
	state: {
		selfCardInfo: {},
		selfChecked: false,
		otherCardInfo: {},
		otherCehecked: false
	},
	mutations: {
		SET_ALL(state, data) {
			for (const k in data) {
				state[k] = data[k]
			}
		},
		SET_INIT(state) {
			state.selfCardInfo = {}
			state.otherCardInfo = {}
			state.selfChecked = false
			state.otherCehecked = false
		}
	},
	actions: {}
}
