<template>
	<div class="help-container">
		<router-view />
	</div>
</template>

<script>
export default {
	data() {
		return {}
	},
	methods: {}
}
</script>

<style scoped lang="less">
.help-container {
	min-height: calc(100vh - var(--navbar-height));
	background-color: var(--white);
}
</style>
