<template>
	<div class="landlord-container">
		<router-view />
	</div>
</template>

<script>
export default {
	data() {
		return {}
	},
	methods: {}
}
</script>

<style scoped lang="less">
.landlord-container {
	min-height: calc(100vh - var(--navbar-height));
	padding: 16px 16px 30px;
	background-color: var(--white);
}
</style>
