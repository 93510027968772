<template>
	<div class="submit-container">
		<Card
			type="content"
			:list="list"
			:info="info" />
		<div class="file-list">
			<ViewFileItem
				v-for="(file, index) in info.fileList"
				:key="file.fileNo"
				:index="index"
				:file="file"
				:del="false" />
		</div>
		<van-button
			type="black"
			round
			class="submit-btn"
			@click="handleBack">
			返回
		</van-button>
	</div>
</template>

<script>
import getDetailMixins from './mixins/getDetail'

export default {
	mixins: [getDetailMixins],
	data() {
		return {
			info: {},
			formData: {}
		}
	},
	computed: {
		list2() {
			const list = [
				{ label: '状态', value: 'landlordStatusCn' },
				{ label: '提交时间', value: 'createTime' },
				{ label: '审核时间', value: 'auditFinishTime' }
			]

			if (this.info.landlordStatus === 'AUDIT_NOT_PASS') {
				list.push({
					label: '驳回原因',
					value: 'auditRejectReasonList'
				})
			}
			return {
				title: '审核信息',
				list
			}
		},
		list() {
			return [
				// {
				// 	title: '房东',
				// 	list: [
				// 		{ label: 'First Name', value: 'firstName' },
				// 		{ label: 'Last Name', value: 'lastName' }
				// 	]
				// },
				// {
				// 	title: '房东地址 (仅限美国)',
				// 	list: [
				// 		{ label: 'State', value: 'landlordState' },
				// 		{ label: 'City', value: 'landlordCity' },
				// 		{ label: 'District', value: 'landlordDistrict' },
				// 		{ label: 'Address', value: 'landlordAddress' },
				// 		{ label: 'Zip Code', value: 'landlordZipCode' }
				// 	]
				// },
				{
					title: '收款账户信息',
					list: [
						{ label: '账号', value: 'bankCardNo' },
						{ label: '名', value: 'firstName' },
						{ label: '姓', value: 'lastName' },
						{ label: '开户银行', value: 'bankName' },
						{ label: 'ABA(ACH)', value: 'aba' }
					]
				},
				this.list2
			]
		}
	},
	watch: {
		list: {
			handler(list) {
				list.forEach(item => {
					item.list.forEach(row => {
						this.$set(this.formData, row.value, '')
					})
				})
				// 额外设置fileList
				this.$set(this.formData, 'fileList', [])
			},
			immediate: true
		}
	},
	created() {
		this.getLanglordDetail()
	},
	methods: {
		handleBack() {
			this.$router.push({
				name: 'LandlordList'
			})
		}
	}
}
</script>

<style scoped lang="less">
.submit-container {
	/deep/.card-container {
		padding: 0;
	}
	.file-list {
		display: flex;
		flex-wrap: wrapZ;
		margin-top: 24px;
	}
	.submit-btn {
		margin-top: 16px;
	}
}
</style>
