var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login"},[_c('div',{staticClass:"login-top"},[_c('van-image',{attrs:{"fit":"cover","width":"169px","height":"38px","lazy-load":"","src":require('assets/image/logo.png')},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('van-loading',{attrs:{"type":"spinner","size":"20"}})]},proxy:true}])})],1),_c('div',{ref:"content",staticClass:"login-content"},[_c('div',{class:[
				'login-tab',
				{
					'login-tab-right': _vm.loginType === 'password'
				}
			]},[_c('div',{class:{
					'login-tab-active': _vm.loginType === 'sms'
				},on:{"click":function($event){return _vm.changeLogin('sms')}}},[_vm._v(" 验证码登录 ")]),_c('div',{class:{
					'login-tab-active': _vm.loginType === 'password'
				},on:{"click":function($event){return _vm.changeLogin('password')}}},[_vm._v(" 密码登录 ")])]),_c('div',{staticClass:"login-input"},[_c('CountryCodeInput',{staticClass:"login-input-mobileNo",attrs:{"country-code":_vm.formData.countryCode,"phone":_vm.formData.mobileNo,"show-search-input":true,"type":"digit","size":"large","placeholder":"请输入手机号","maxlength":"11","autocomplete":"off","clearable":""},on:{"update:countryCode":function($event){return _vm.$set(_vm.formData, "countryCode", $event)},"update:country-code":function($event){return _vm.$set(_vm.formData, "countryCode", $event)},"update:phone":function($event){return _vm.$set(_vm.formData, "mobileNo", $event)},"blurPhone":_vm.mobileNoBlur,"changCountryCode":_vm.changeCountry}}),_c('InputPassword',{directives:[{name:"show",rawName:"v-show",value:(_vm.loginType === 'password'),expression:"loginType === 'password'"}],attrs:{"clearable":"","size":"large","placeholder":"请输入6-20位数字和字母的登录密码"},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}}),_c('InputTimeout',{directives:[{name:"show",rawName:"v-show",value:(_vm.loginType === 'sms'),expression:"loginType === 'sms'"}],ref:"inputTimeout",attrs:{"phone":_vm.formData.mobileNo,"country-code":_vm.formData.countryCode,"border":false,"size":"large"},on:{"change":_vm.timeChange},model:{value:(_vm.formData.code),callback:function ($$v) {_vm.$set(_vm.formData, "code", $$v)},expression:"formData.code"}})],1),_c('div',{staticClass:"login-btn"},[_c('van-button',{class:{
					'van-button--disabled': !_vm.isLoginBtn
				},attrs:{"round":"","type":"red"},on:{"click":_vm.submitLogin}},[_vm._v(" "+_vm._s(_vm.loginType === 'password' ? '登 录' : '登 录/注 册')+" ")])],1),_c('div',{staticClass:"login-protocol",class:{ shaking: _vm.toShake && !_vm.checked }},[_c('van-checkbox',{scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('van-image',{attrs:{"width":"14px","height":"14px","fit":"cover","src":props.checked
								? require('@/assets/image/common/checked-active.png')
								: require('@/assets/image/common/checked.png')}})]}}]),model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}},[_c('span',[_vm._v(" "+_vm._s(_vm.loginType === 'sms' ? '未注册的手机号验证后自动创建账号，登录代表您阅读并同意' : '登录代表您阅读并同意')+" ")]),_vm._l((_vm.protocolList),function(item){return _c('span',{key:item.name,staticClass:"login-protocol-link",on:{"click":function($event){$event.stopPropagation();return _vm.clickProtocol(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])})],2)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }