var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"number-group-input-container"},[(_vm.isPC())?_c('ul',{staticClass:"flex-form"},_vm._l((_vm.originalCertList),function(item,index){return _c('li',{key:index,staticClass:"flex-form-item",on:{"keyup":_vm.handleKey}},[_c('van-field',{ref:`numInput${index}`,refInFor:true,attrs:{"type":_vm.inputType,"inputmode":_vm.inputmode,"disabled":_vm.activeIndex < index,"maxlength":1},on:{"focus":val =>
						_vm.handleFocus(val, {
							item,
							index,
							length: _vm.originalCertList.length
						}),"blur":val =>
						_vm.handleBlur(val, {
							item,
							index,
							length: _vm.originalCertList.length
						}),"input":val =>
						_vm.handleInput(val, {
							item,
							index,
							length: _vm.originalCertList.length
						})},model:{value:(item.label),callback:function ($$v) {_vm.$set(item, "label", $$v)},expression:"item.label"}})],1)}),0):[_c('van-password-input',{attrs:{"value":_vm.inputValue,"focused":_vm.showKeyboard,"length":_vm.maxLength,"gutter":"14"},on:{"focus":_vm.handleInputFocus}}),_c('van-number-keyboard',{attrs:{"show":_vm.showKeyboard,"extra-key":_vm.extraKey},on:{"blur":function($event){_vm.showKeyboard = false}},model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }