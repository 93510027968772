<template>
	<div class="result-container">
		<div class="img">
			<img src="@/assets/image/common/img-success.png" />
		</div>
		<div class="title">添加成功</div>
		<div class="desc">已向持卡人银行预留手机号发送授权短信</div>
		<van-button
			class="btn"
			round
			block
			type="black"
			@click="handleSuccess">
			完成
		</van-button>
	</div>
</template>

<script>
export default {
	data() {
		return {}
	},
	created() {
		console.log('result')
	},
	methods: {
		handleSuccess() {
			this.$router.push({
				name: 'CardList'
			})
		}
	}
}
</script>
<style lang="less" scoped>
.result-container {
	padding: 44px 0 0;
	text-align: center;
	.img {
		width: 100px;
		height: 100px;
		margin: 0 auto;
	}
	.title {
		margin: 30px 0 16px;
		font-size: 20px;
		color: #131523;
		line-height: 20px;
	}
	.desc {
		font-size: 14px;
		color: #333752;
		line-height: 24px;
	}
	.btn {
		margin-top: 30px;
	}
}
</style>
