export default [
	{
		path: '/help',
		name: 'Help',
		meta: {
			title: '寻求帮助'
		},
		redirect: 'help/content-service',
		component: () => import('@/views/help/index'),
		children: [
			{
				path: 'content-service',
				name: 'HelpContentService',
				meta: {
					title: '寻求帮助'
				},
				component: () => import('@/views/help/Help.vue')
			}
		]
	}
]
