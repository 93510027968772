export default {
	methods: {
		validateForm(formData, rules) {
			if (rules) {
				return new Promise((resolve, reject) => {
					const rulesKeyList = Object.keys(rules)
					for (let i = 0; i < rulesKeyList.length; i++) {
						const rulesKey = rulesKeyList[i]
						const rulesValue = rules[rulesKey]
						if (rulesValue && rulesValue.some(item => item.required)) {
							const rules = rulesValue.find(item => item.required)
							const formDataValue = formData[rulesKey]
							// 空数组或者其他falsy值
							if (
								(Array.isArray(formDataValue) && !formDataValue.length) ||
								!formDataValue
							) {
								reject({
									rulesKey,
									rules
								})
								break
							} else {
								continue
							}
						} else {
							continue
						}
					}
					resolve(formData)
				})
			} else {
				return Promise.resolve(formData)
			}
		}
	}
}
