<template>
	<div class="step3-container">
		<p class="title">请上传租房合同</p>
		<p class="desc">
			请上传完整版协议PDF文件或手机拍照图片。文件格式支持PDF和图片（包括PNG，JPG，JPEG），单个文件最大不超过10M。
		</p>
		<div class="file-list">
			<UploadFile
				:file-list="formData.fileList"
				accept="image/png,image/jpeg,image/jpg,application/pdf" />
		</div>
		<van-button
			type="black"
			round
			class="submit-btn"
			native-type="submit"
			@click="handleNext">
			<!-- Save & Next -->
			保存并下一步
		</van-button>
	</div>
</template>

<script>
import operationApi from '../api/operation'
import getDetailMixins from '../mixins/getDetail'
import validateMixins from '../mixins/validate'

export default {
	mixins: [getDetailMixins, validateMixins],
	data() {
		return {
			formData: {
				fileList: []
			},
			rules: {
				fileList: [{ required: true, message: '请上传文件' }]
			}
		}
	},
	created() {
		this.getLanglordDetail()
	},
	methods: {
		handleNext() {
			const { landlordNo, formData, rules } = this
			this.validateForm(formData, rules)
				.then(_ => {
					const loading = this.$toast.loading()
					operationApi
						.addLandlordFile({
							landlordNo,
							...formData
						})
						.then(res => {
							if (res) {
								const { landlordNo } = res
								this.$router.push({
									name: 'LandlordOperationStep4',
									query: {
										landlordNo
									}
								})
							}
						})
						.catch(console.error)
						.finally(_ => {
							loading.clear()
						})
				})
				.catch(({ rules }) => {
					const { message } = rules
					this.$dialog.alert({
						title: '提示',
						message
					})
				})
		}
	}
}
</script>

<style scoped lang="less">
.step3-container {
	.title {
		margin-bottom: 14px;
	}
	.desc {
		font-size: 12px;
		line-height: 20px;
		color: var(--main-04);
		margin-bottom: 12px;
	}
	.file-list {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 12px;
	}
}
</style>
