<template>
	<div class="setting-container">
		<router-view />
	</div>
</template>

<script>
export default {
	data() {
		return {}
	},
	methods: {}
}
</script>

<style scoped lang="less"></style>
