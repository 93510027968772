export default [
	{
		path: 'card-holder',
		name: 'FaceValidateCardHolderIndex',
		meta: {
			title: '身份认证',
			hideNavBar: true
		},
		component: () => import('@/views/faceValidate/cardHolder/index.vue')
	},
	{
		path: 'card-holder-confirm',
		name: 'FaceValidateCardHolderConfirm',
		meta: {
			title: '授权认证'
		},
		component: () => import('@/views/faceValidate/cardHolder/Confirm.vue')
	},
	{
		path: 'card-holder-result',
		name: 'FaceValidateCardHolderResult',
		meta: {
			title: '完成',
			hideNavBar: true
		},
		component: () => import('@/views/faceValidate/cardHolder/Result.vue')
	}
]
