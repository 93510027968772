export default {
	namespaced: true,
	state: {
		countryCode: '',
		mobileNo: '',
		loginType: ''
	},
	mutations: {
		SET_COUNTRY_CODE: (state, countryCode) => {
			state.countryCode = countryCode
		},
		SET_MOBILE_NO: (state, mobileNo) => {
			state.mobileNo = mobileNo
		},
		SET_LOGIN_TYPE: (state, loginType) => {
			state.loginType = loginType
		}
	},
	actions: {}
}
