import { render, staticRenderFns } from "./BindingCardPaymentAgreement.vue?vue&type=template&id=2de3048d&scoped=true"
var script = {}
import style0 from "./BindingCardPaymentAgreement.vue?vue&type=style&index=0&id=2de3048d&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2de3048d",
  null
  
)

export default component.exports