import Vue from 'vue'
import VueRouter from 'vue-router'
import { CryptoQueryString } from '@cbibank/crypto'
import store from '@/store'

const { stringifyQuery, parseQuery } = new CryptoQueryString({
	enable: ['UAT', 'PROD'].includes(process.env.VUE_APP_TARGET),
	key: 'cbibankh5card000'
})
// 同步路由
import commonRoutes from './commonRoutes'

Vue.use(VueRouter)

const routes = [
	...commonRoutes,
	{
		path: '/404',
		meta: {
			hideNavBar: true
		},
		component: () => import('@/views/error/404.vue')
	},
	{
		path: '*',
		redirect: '/404'
	}
]

// 解决VueRouter报错 Avoided redundant navigation to current location
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function (location) {
	return originalPush.call(this, location).catch(err => err)
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function (location) {
	return originalReplace.call(this, location).catch(err => err)
}

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	stringifyQuery,
	parseQuery,
	scrollBehavior(to, from, savedPosition) {
		// ios环境下切换页面某些场景y:0时页面无法滚动到顶部，设置y:1来解决这个问题
		return { x: 0, y: 1 }
	}
})
const whiteList = [
	'/404',
	'/login',
	'/protocol',
	'/face-validate/card-holder',
	'/face-validate/card-holder-confirm',
	'/face-validate/card-holder-result'
]
// 路由守卫
router.beforeEach((to, from, next) => {
	/* eslint-disable-next-line */
	const { matched, ...options } = from
	// 存储回退路由信息
	store.commit('history/SET_HISTORY_ROUTE', options)

	const title = to.meta && to.meta.title
	if (title) {
		document.title = title
		if (to.name === 'Protocol' && to.query.name) {
			document.title = to.query.name
			to.meta.title = to.query.name
		}
	}
	let sessionId = store.state.app.userInfo.sessionId
	if (to.query.sessionId) {
		sessionId = to.query.sessionId
	}
	if (sessionId) {
		const loginNextInfo = store.state.app.loginNextInfo
		if (loginNextInfo !== 'pass') {
			switch (loginNextInfo) {
				case 'LoginPassword':
					if (to.name !== 'LoginPassword') {
						next({
							name: 'LoginPassword'
						})
					}
					break
				case 'KycIndex':
					if (
						!['KycIndex', 'KycBaseInfo', 'KycBaseInfoEditName'].includes(
							to.name
						)
					) {
						next({
							name: ['KycIndex', 'KycBaseInfo', 'KycBaseInfoEditName'].includes(
								from.name
							)
								? from.name
								: 'KycIndex'
						})
					}
					break
				case 'LoginSetTransactionPassword':
					if (to.name !== 'LoginSetTransactionPassword') {
						next({
							name: 'LoginSetTransactionPassword'
						})
					}
					break
			}
		} else {
			if (
				[
					'LoginPassword',
					'KycIndex',
					'KycBaseInfo',
					'KycBaseInfoEditName',
					'LoginSetTransactionPassword'
				].includes(to.name)
			) {
				next({
					path: '/'
				})
				return
			}
		}
		// 登陆状态下不可以直接跳转登陆页面
		if (to.name === 'Login') {
			next({
				path: '/'
			})
			return
		}
		next()
	} else {
		// 没有token
		if (whiteList.indexOf(to.path) !== -1) {
			// 在免登录白名单，直接进入
			next()
		} else {
			next({
				path: '/login'
			}) // 否则全部重定向到登录页
		}
	}
})

router.afterEach((to, from) => {})

export default router
