export default {
	namespaced: true,
	state: {
		userInfo: {}, // 用户信息
		loginNextInfo: '' // 登陆流程
	},
	mutations: {
		SET_USER_INFO: (state, userInfo) => {
			state.userInfo = userInfo
		},
		CHANGE_USER_INFO: (state, userInfo) => {
			Object.keys(userInfo).map(key => {
				state.userInfo[key] = userInfo[key]
			})
		},
		SET_TO_LOGIN_NEXT: (state, next) => {
			state.loginNextInfo = next
		},
		CLEAR_ALL_INFO: (state, userInfo) => {
			state.userInfo = {}
			state.loginNextInfo = ''
		}
	},
	actions: {}
}
