import api from 'utils/http/axios'
import { BASE_URL } from 'utils/http/base-url'

export default {
	// 房东列表
	getLandlordList(params = {}, config) {
		return api.post(BASE_URL + '/app/paymentRent/pageList', params, config)
	},
	// 删除房东
	deleteLandlord(params = {}, config) {
		return api.post(
			BASE_URL + '/app/paymentRent/deleteLandlord',
			params,
			config
		)
	},
	// 启用房东
	enableLandlord(params = {}, config) {
		return api.post(
			BASE_URL + '/app/paymentRent/enableLandlord',
			params,
			config
		)
	},
	// 停用房东
	disableLandlord(params = {}, config) {
		return api.post(
			BASE_URL + '/app/paymentRent/nullifyLandlord',
			params,
			config
		)
	}
}
