<template>
	<div class="login">
		<div class="login-top">
			<van-image
				fit="cover"
				width="169px"
				height="38px"
				lazy-load
				:src="require('assets/image/logo.png')">
				<template #loading>
					<van-loading
						type="spinner"
						size="20" />
				</template>
			</van-image>
		</div>
		<div
			ref="content"
			class="login-content">
			<div
				:class="[
					'login-tab',
					{
						'login-tab-right': loginType === 'password'
					}
				]">
				<div
					:class="{
						'login-tab-active': loginType === 'sms'
					}"
					@click="changeLogin('sms')">
					验证码登录
				</div>
				<div
					:class="{
						'login-tab-active': loginType === 'password'
					}"
					@click="changeLogin('password')">
					密码登录
				</div>
			</div>
			<div class="login-input">
				<CountryCodeInput
					class="login-input-mobileNo"
					:country-code.sync="formData.countryCode"
					:phone.sync="formData.mobileNo"
					:show-search-input="true"
					type="digit"
					size="large"
					placeholder="请输入手机号"
					maxlength="11"
					autocomplete="off"
					clearable
					@blurPhone="mobileNoBlur"
					@changCountryCode="changeCountry" />
				<!--<FieldInput-->
				<!--	v-model="formData.mobileNo"-->
				<!--	class="login-input-mobileNo"-->
				<!--	clearable-->
				<!--	type="digit"-->
				<!--	size="large"-->
				<!--	placeholder="请输入手机号"-->
				<!--	maxlength="11"-->
				<!--	:border="false"-->
				<!--	@blur="mobileNoBlur">-->
				<!--	<template #label>-->
				<!--		<div-->
				<!--			class="phone-label"-->
				<!--			@click="changeCountry">-->
				<!--			<div class="phone-label-value">+{{ formData.countryCode }}</div>-->
				<!--			<SvgIcon-->
				<!--				name="arrow-down"-->
				<!--				class="phone-icon" />-->
				<!--		</div>-->
				<!--	</template>-->
				<!--</FieldInput>-->
				<InputPassword
					v-show="loginType === 'password'"
					v-model="formData.password"
					clearable
					size="large"
					placeholder="请输入6-20位数字和字母的登录密码" />
				<InputTimeout
					v-show="loginType === 'sms'"
					ref="inputTimeout"
					v-model="formData.code"
					:phone="formData.mobileNo"
					:country-code="formData.countryCode"
					:border="false"
					size="large"
					@change="timeChange" />
			</div>
			<!--<div-->
			<!--	v-show="timeCount <= 25 && loginType === 'sms'"-->
			<!--	style=""-->
			<!--	class="sms-voice-tips">-->
			<!--	<span @click="showVoicePop = true">收不到验证码？</span>-->
			<!--</div>-->
			<div class="login-btn">
				<van-button
					round
					:class="{
						'van-button--disabled': !isLoginBtn
					}"
					type="red"
					@click="submitLogin">
					{{ loginType === 'password' ? '登 录' : '登 录/注 册' }}
				</van-button>
			</div>
			<div
				class="login-protocol"
				:class="{ shaking: toShake && !checked }">
				<van-checkbox v-model="checked">
					<span>
						{{
							loginType === 'sms'
								? '未注册的手机号验证后自动创建账号，登录代表您阅读并同意'
								: '登录代表您阅读并同意'
						}}
					</span>
					<span
						v-for="item in protocolList"
						:key="item.name"
						class="login-protocol-link"
						@click.stop="clickProtocol(item)">
						{{ item.name }}
					</span>
					<template #icon="props">
						<van-image
							width="14px"
							height="14px"
							fit="cover"
							:src="
								props.checked
									? require('@/assets/image/common/checked-active.png')
									: require('@/assets/image/common/checked.png')
							" />
					</template>
				</van-checkbox>
			</div>
			<!--<div-->
			<!--	v-if="loginType === 'password'"-->
			<!--	class="login-reset">-->
			<!--	<van-button-->
			<!--		type="text"-->
			<!--		size="small"-->
			<!--		@click="resetPassword">-->
			<!--		忘记密码？-->
			<!--	</van-button>-->
			<!--</div>-->
		</div>
		<!--<van-popup-->
		<!--	v-model="showVoicePop"-->
		<!--	closeable-->
		<!--	close-icon-position="top-right"-->
		<!--	round-->
		<!--	position="bottom">-->
		<!--	<div-->
		<!--		class="sheet-action-cell"-->
		<!--		@click="clickVoice">-->
		<!--		<div class="sheet-action-cell_title">语音验证码</div>-->
		<!--		<div class="sheet-action-cell_des">-->
		<!--			我们将给您拨打电话，通过语音播报验证码-->
		<!--		</div>-->
		<!--	</div>-->
		<!--</van-popup>-->
	</div>
</template>
<script>
import commonApi from '@/api/common'
import { login, smsLogin } from 'views/login/api'
import { mapMutations, mapState } from 'vuex'
import { checkPhone } from 'utils/utils'

export default {
	name: 'LoginCache',
	beforeRouteLeave(to, from, next) {
		if (to.name !== 'Protocol') {
			this.removeCachePage()
			this.formData.code = ''
		}
		next()
	},
	data() {
		return {
			isShowFocus: true,
			loginType: 'sms',
			protocolList: [],
			formData: {
				countryCode: '1',
				password: '',
				code: '',
				// riskCountry: 'CN',
				mobileNo: ''
			},
			checked: false,
			toShake: false,
			toShakeTime: null,
			passwordLength: 0,
			timeCount: 60,
			showVoicePop: false
		}
	},
	computed: {
		...mapState('app', ['userInfo']),
		...mapState('login', {
			storeLoginType: 'loginType',
			countryCode: 'countryCode',
			mobileNo: 'mobileNo'
		}),
		isLoginBtn() {
			const { password, code, mobileNo } = this.formData
			if (this.loginType === 'password') {
				return password && mobileNo && this.checked
			} else {
				return code.length === 6 && mobileNo && this.checked
			}
		}
	},
	mounted() {
		this.formData.countryCode = this.countryCode || '1'
		this.formData.mobileNo = this.mobileNo || ''
		this.loginType = this.storeLoginType || 'sms'
		this.getProtocol()
	},

	methods: {
		...mapMutations('app', [
			'SET_USER_INFO',
			'SET_TO_LOGIN_NEXT',
			'CLEAR_ALL_INFO'
		]),
		...mapMutations('login', [
			'SET_COUNTRY_CODE',
			'SET_MOBILE_NO',
			'SET_LOGIN_TYPE'
		]),
		getProtocol() {
			this.loading = true
			commonApi
				.getProtocol({
					bizType: 'LOGIN_AND_REGISTER'
				})
				.then(res => {
					this.protocolList = res
					console.log(res)
				})
				.catch(err => {
					console.log(err)
				})
				.finally(() => {
					this.loading = false
				})
		},
		removeCachePage() {
			this.$EventBus.$emit('removeCachePage', 'Login')
		},
		// resetPassword() {
		// 	this.$router.push({
		// 		name: 'LoginForgottenPassword',
		// 		query: {
		// 			mobileNo: this.formData.mobileNo
		// 		}
		// 	})
		// },
		mobileNoBlur() {
			this.SET_MOBILE_NO(this.formData.mobileNo)
		},
		submitLogin() {
			if (!this.isLoginBtn) {
				if (this.toShake) {
					return
				}
				this.toShake = true
				setTimeout(() => {
					this.toShake = false
				}, 1000)
				return
			}
			const { countryCode, mobileNo, code, password } = this.formData
			if (!checkPhone(mobileNo, countryCode)) {
				this.$toast('手机号输入错误')
				return
			}
			const isPassword = this.loginType === 'password'
			if (isPassword && !(password.length >= 6)) {
				this.$toast('密码长度至少为6位')
				return
			}
			const fn = isPassword ? login : smsLogin
			const toast = this.$toast.loading({
				message: '正在登录...'
			})
			const formData = {
				phoneCode: countryCode,
				phoneNum: mobileNo
			}
			if (isPassword) {
				formData.password = password
			} else {
				formData.code = code
			}
			fn({
				...formData
			})
				.then(res => {
					// 是否跳转设置密码，只有验证码登陆才会跳转
					const isLoginPassword = !isPassword && !res.charPwdFlag
					this.CLEAR_ALL_INFO()
					this.SET_USER_INFO({
						...res,
						countryCode,
						mobileNo
					})
					try {
						// Event snippet for 注册1-手机验证成功后 conversion page
						// eslint-disable-next-line no-undef
						gtag('event', 'conversion', {
							send_to: 'AW-16652125746/colxCIyY28UZELKMrYQ-'
						})
					} catch (err) {
						console.error(err)
					}
					try {
						// eslint-disable-next-line no-undef
						fbq('track', 'Contact')
					} catch (err) {
						console.error(err)
					}
					if (isLoginPassword) {
						this.SET_TO_LOGIN_NEXT('LoginPassword')
						this.$router.push({
							name: 'LoginPassword'
						})
						return
					}
					if (res.status === 'INIT') {
						this.SET_TO_LOGIN_NEXT('KycIndex')
						this.$router.push({
							name: 'KycIndex'
						})
						return
					}
					// if (!res.charPayPwdFlag) {
					// 	this.SET_TO_LOGIN_NEXT('LoginSetTransactionPassword')
					// 	this.$router.push({
					// 		name: 'LoginSetTransactionPassword'
					// 	})
					// 	return
					// }
					this.SET_TO_LOGIN_NEXT('pass')
					if (!res.haveBindCard) {
						this.$router.push({
							name: 'CardList'
						})
						return
					}
					this.$router.push({
						name: 'Home'
					})
				})
				.catch(err => {
					console.log(err)
				})
				.finally(() => {
					toast.clear()
				})
		},
		changeLogin(type) {
			this.loginType = type
			this.SET_LOGIN_TYPE(type)
		},
		changeCountry(value) {
			this.SET_COUNTRY_CODE(value)
		},
		timeChange(count) {
			this.timeCount = count
		},
		clickVoice() {
			this.showVoicePop = false
			this.$refs.inputTimeout.getVoiceSMS()
		},
		clickProtocol(item) {
			this.$router.push({
				name: 'Protocol',
				query: {
					url: encodeURIComponent(item.fileUrl),
					name: item.name
				}
			})
		}
	}
}
</script>
<style scoped lang="less">
.login {
	min-height: 100vh;
	background-color: #ffffff;
	.login-top {
		padding: 60px 0 40px 0;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.login-content {
		padding: 0 24px 24px;
		.login-title {
			padding: 30px 0 34px;
			text-align: center;
			font-size: 24px;
		}
		.login-tab {
			position: relative;
			display: flex;
			height: 50px;
			background-color: #f5f6fa;
			border: 4px solid #f5f6fa;
			border-radius: 16px;
			font-size: 16px;
			color: var(--main-01);
			&:before {
				z-index: 1;
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 50%;
				height: 100%;
				border-radius: 16px;
				background-color: #fff;
				transition: all 0.25s linear;
			}
			div {
				z-index: 2;
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 16px;
				cursor: pointer;
			}
			.login-tab-active {
				//color: #fff;
			}
		}
		.login-tab-right {
			&:before {
				transform: translateX(100%);
			}
		}
		.login-input {
			margin-top: 24px;
			border-radius: 16px;
			//border: 1px solid var(--black-10);
			//overflow: hidden;
			.login-input-mobileNo {
				margin-bottom: 20px;
			}
			.phone-label {
				display: flex;
				align-items: center;
				.phone-icon {
					width: 20px;
					height: 20px;
				}
				.phone-label-value {
					color: var(--main-01);
					font-size: 14px;
					padding-right: 5px;
				}
			}
		}
		.sms-voice-tips {
			text-align: right;
			span {
				display: inline-block;
				color: var(--main-02);
				font-size: 14px;
				padding: 24px 0 24px;
			}
		}
		.login-protocol {
			font-size: 14px;
			padding-top: 12px;
			.login-protocol-link {
				color: var(--primary-90);
			}
			.van-checkbox {
				display: flex;
				:deep(.van-checkbox__icon) {
					display: flex;
					align-items: center;
					top: 2px;
				}
			}
		}
		.login-btn {
			padding-top: 40px;
			.van-button--disabled {
				background-color: var(--red-03);
			}
		}
		.login-reset {
			text-align: right;
		}
	}

	.sheet-action-cell {
		padding: 40px 20px;
		text-align: center;
		.sheet-action-cell_title {
			font-size: 16px;
			font-weight: 600;
			padding-bottom: 20px;
		}
		.sheet-action-cell_des {
			font-size: 14px;
			font-weight: 400;
		}
	}
	.shaking {
		animation: shakeX 0.3s linear 3;
	}
	@keyframes shakeX {
		0% {
			transform: rotate(0);
		}

		20% {
			transform: rotate(-2deg);
		}

		60% {
			transform: rotate(0);
		}

		80% {
			transform: rotate(2deg);
		}

		100% {
			transform: rotate(0);
		}
	}
}
</style>
