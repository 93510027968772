import api from '@/utils/http/axios'
import { BASE_URL } from '@/utils/http/base-url'

/**
 * @desc 添加银行信息-代缴人
 * @param {string}
 */
export const addBankCardInfoAgentPayor = (params = {}, config = {}) => {
	return api.post(
		BASE_URL + '/app/frontend/api/bankCard/addBankCardInfoAgentPayor',
		params,
		{
			...config
		}
	)
}
/**
 * @desc 添加银行信息-注册人
 * @param {string}
 */
export const addBankCardInfoRegistrant = (params = {}, config = {}) => {
	return api.post(
		BASE_URL + '/app/frontend/api/bankCard/addBankCardInfoRegistrant',
		params,
		{
			...config
		}
	)
}

/**
 * @desc 查询银行信息
 * @param {string} bankCardNo
 */
export const bankCardInfo = (params = {}, config = {}) => {
	return api.post(
		BASE_URL + '/app/frontend/api/bankCard/bankCardInfo',
		params,
		{
			...config
		}
	)
}

/**
 * @desc 绑卡-验证支付密码
 * @param {string} charPayPwd	加密后的支付密码
 */
export const bindCardCheckPayPwd = (params = {}, config = {}) => {
	return api.post(
		BASE_URL + '/app/frontend/api/bankCard/bindCardCheckPayPwd',
		params,
		{
			md5Keys: 'charPayPwd',
			...config
		}
	)
}
/**
 * @desc 绑卡-验证登录密码
 * @param {string} charPayPwd	加密后的登录密码
 */
export const bindCardCheckCharPwd = (params = {}, config = {}) => {
	return api.post(
		BASE_URL + '/app/frontend/api/bankCard/bindCardCheckCharPwd',
		params,
		{
			md5Keys: 'charPwd',
			signKeys: ['charPwd'],
			...config
		}
	)
}

/**
 * @desc 绑卡-发送短信验证码 添加时候发送短信
 * @param {string} bankCardNo	银行卡号
 * @param {string} countryCode	手机国码
 * @param {string} reservePhone	预留手机号
 */
export const bindCardSendSms = (params = {}, config = {}) => {
	return api.post(
		BASE_URL + '/app/frontend/api/bankCard/bindCardSendSms',
		params,
		{
			...config
		}
	)
}

/**
 * @desc 校验持卡人
 * @param {string} cardHolderGavenName 持卡人名
 * @param {string} cardHolderSurName 持卡人姓
 * @param {string} idNo	身份证号
 */
export const checkCardHolder = (params = {}, config = {}) => {
	return api.post(
		BASE_URL + '/app/frontend/api/bankCard/checkCardHolder',
		params,
		{
			...config
		}
	)
}

/**
 * @desc 查询银行卡列表信息
 */
export const getBankCardInfoList = (params = {}, config = {}) => {
	return api.post(
		BASE_URL + '/app/frontend/api/bankCard/getBankCardInfoList',
		params,
		{
			...config
		}
	)
}

/**
 * @desc 查询手机号国码
 */
export const phoneAreaCode = (params = {}, config = {}) => {
	return api.post(
		BASE_URL + '/app/frontend/api/bankCard/phoneAreaCode',
		params,
		{
			...config
		}
	)
}

/**
 * @desc 发送授权认证短信
 * @param {string} cardInfoNo	卡编号
 */
export const sendAuthSms = (params = {}, config = {}) => {
	return api.post(BASE_URL + '/app/frontend/api/bankCard/sendAuthSms', params, {
		...config
	})
}

/**
 * @desc 银行卡解绑
 * @param {string} cardInfoNo	卡编号
 */
export const unBindCards = (params = {}, config = {}) => {
	return api.post(BASE_URL + '/app/frontend/api/bankCard/unBindCard', params, {
		...config
	})
}

/**
 * @desc 银行卡详情
 * @param {string} cardInfoNo	卡编号
 */
export const getBankCardInfoDetail = (params = {}, config = {}) => {
	return api.post(
		BASE_URL + '/app/frontend/api/bankCard/getBankCardInfoDetail',
		params,
		{
			...config
		}
	)
}
