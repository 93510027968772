import api from '@/utils/http/axios'
import { BASE_URL } from '@/utils/http/base-url'

export default {
	// /**
	//  * @desc 获取视频认证token
	//  * @param {string} source PC、H5
	//  */
	// getFaceToken(params = {}, config = {}) {
	// 	return api.post(BASE_URL + '/api/faceId/getToken', params, config)
	// },
	// /**
	//  * @desc 获取视频认证结果
	//  * @param {string} bizNo
	//  */
	// getFaceResult(params = {}, config = {}) {
	// 	return api.post(
	// 		BASE_URL + '/api/faceId/getFaceVerifyResult',
	// 		params,
	// 		config
	// 	)
	// },

	/**
	 * @desc 非登录状态获取视频认证token
	 * @param {string} authorizeKey 绑卡授权key
	 * @param {string} returnUrl 用户id
	 * @param {string} source PC、H5
	 * @param {string} webTitle 标题文字
	 */
	getFaceTokenByPass(params = {}, config = {}) {
		return api.post(BASE_URL + '/app/faceId/pass/getToken', params, config)
	},
	/**
	 * @desc 非登录状态获取视频认证结果
	 * @param {string} bizNo
	 */
	getFaceResultByPass(params = {}, config = {}) {
		return api.post(
			BASE_URL + '/app/faceId/pass/getFaceVerifyResult',
			params,
			config
		)
	}
}
