var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-info"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.info.paymentItemCn))]),_c('div',{staticClass:"amount-container"},[_vm._v(" "+_vm._s(_vm.info.payeeAmount)+" "+_vm._s(_vm.info.currency)+" ")]),_c('div',{staticClass:"status"},[_c('div',{staticClass:"text",class:_vm.info.status},[_vm._v(" "+_vm._s(_vm.info.statusCn)+" ")])]),(
			_vm.info.status === 'CARD_PAYMENT_PROCESSING' ||
			_vm.info.status === 'WAITING_FOR_PAYOUT'
		)?_c('div',{staticClass:"desc"},[_vm._v(" 预估结算日期："+_vm._s(_vm.info.expectedSettleDateStr)+" ")]):(
			_vm.info.status === 'PAYOUT_PROCESSING' ||
			_vm.info.status === 'PAYOUT_SUCCESS' ||
			_vm.info.status === 'PAYOUT_FAILED'
		)?_c('div',{staticClass:"desc"},[_vm._v(" 结算日期："+_vm._s(_vm.info.settleDate)+" ")]):(_vm.info.status === 'WAITING_FOR_AUTHORIZATION')?_c('div',{staticClass:"desc"},[_vm._v(" "+_vm._s(_vm.waitingText)+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }