<template>
	<div class="card-list-container">
		<div v-if="!cardList.length">
			<van-empty
				:image-size="160"
				:image="require('@/assets/image/common/empty.png')"
				description="暂无数据" />
		</div>
		<div
			v-else
			class="card-list">
			<CardItem
				v-for="item in cardList"
				:key="item.name"
				:info="item"
				@click="handleGoTo(item)">
				<van-button
					class="card-person-btn"
					type="black"
					size="small"
					@click.stop="handlePayment(item)">
					去付款
				</van-button>
				<template
					v-if="
						item.cardHolder == 'AGENT_PAYOR' &&
						item.authorizedStatus == 'TO_BE_AUTHORIZED'
					">
					<van-button
						class="send-btn"
						type="black"
						@click.stop="handleSend(item)">
						发送授权通知
					</van-button>
				</template>
			</CardItem>
		</div>
		<van-button
			class="add-btn"
			type="black"
			size="large"
			round
			@click="handleAddBankCard">
			<SvgIcon
				name="add-circle"
				class="icon" />
			添加银行卡
		</van-button>
		<AuthActionSheet
			v-model="showAuthActionSheet"
			@select="handleActionSheetSelect" />
	</div>
</template>
<script>
import CardItem from './components/CardItem.vue'
import { getBankCardInfoList, sendAuthSms } from '@/views/card/api/index.js'
export default {
	components: { CardItem },
	beforeRouteEnter(to, form, next) {
		// 设置完支付密码，绑定卡的时候，不需要进行验证支付密码
		// 1.2版本取消了支付密码，改了kyc直接跳转到cardList
		if (form.name === 'KycBaseInfo') {
			next(vm => {
				vm.addValide = false
			})
		} else {
			next(vm => {
				vm.addValide = true
			})
		}
	},
	data() {
		return {
			cardList: [],
			addValide: true,
			// Action Sheet
			showAuthActionSheet: false,
			actionSheetItem: {}
		}
	},
	created() {
		this.getList()
	},
	methods: {
		getList() {
			getBankCardInfoList()
				.then(res => {
					this.cardList = res
				})
				.catch(console.log)
		},
		handlePayment(item) {
			this.$router.push({
				name: 'RentPayment',
				query: {
					cardInfoNo: item.cardInfoNo
				}
			})
		},
		handleSend(item) {
			this.showAuthActionSheet = true
			this.authActionSheetItem = item
		},
		handleActionSheetSelect(actionSheet) {
			const {
				value,
				alert: { title, message }
			} = actionSheet
			const { cardInfoNo } = this.authActionSheetItem
			const hasSendSms = value === 'action-sms'
			const loading = this.$toast.loading()
			sendAuthSms({
				cardInfoNo,
				hasSendSms
			})
				.then(res => {
					if (hasSendSms) {
						this.$dialog.alert({
							title,
							message,
							confirmButtonText: '确 定'
						})
					} else {
						const { authorizeShortLink } = res
						const timer = window.setTimeout(() => {
							this.$copyText(authorizeShortLink)
								.then(_ => {
									this.$dialog.alert({
										title,
										message,
										confirmButtonText: '确 定'
									})
								})
								.catch(_ => {
									this.$dialog.alert({
										title: '提示',
										message: '复制授权信息失败，浏览器版本不支持',
										confirmButtonText: '确 定'
									})
								})
							window.clearTimeout(timer)
						}, 100)
					}
				})
				.catch(console.error)
				.finally(() => {
					loading.clear()
				})
		},
		handleAddBankCard() {
			let name = ''
			if (this.addValide) {
				name = 'CardAddValide'
			} else {
				name = 'CardAddTypeChecked'
			}
			this.$router.push({
				name
			})
		},
		handleGoTo(item) {
			console.log(item)
			this.$router.push({
				name: 'CardDetails',
				query: {
					cardInfoNo: item.cardInfoNo
				}
			})
		}
	}
}
</script>
<style lang="less" scoped>
.card-list-container {
	min-height: calc(100vh - var(--navbar-height));
	padding: 16px;
	background: var(--white);
	color: var(--main-01);
	.card-list {
		margin-bottom: 30px;
	}
	.add-btn {
		.icon {
			font-size: 20px;
			margin-right: 5px;
		}
	}
}
</style>
