export default [
	{
		path: '/kyc/index',
		name: 'KycIndex',
		meta: {
			title: '开通CBiMart',
			hideNavBar: true
		},
		component: () => import('@/views/kyc/Index')
	},
	{
		path: '/kyc/baseInfo',
		name: 'KycBaseInfo',
		meta: {
			title: '信息认证',
			hideNavBarLeft: true,
			hideNavBarRight: true
		},
		component: () => import('@/views/kyc/BaseInfo/Index.vue')
	},
	{
		path: '/kyc/baseInfo/editName',
		name: 'KycBaseInfoEditName',
		meta: {
			title: '姓名',
			hideNavBarRight: true
		},
		component: () => import('@/views/kyc/BaseInfo/Name.vue')
	}
]
