<template>
	<div class="me">
		<div class="me-card">
			<van-cell
				:border="false"
				is-link
				to="/setting/user">
				<template #title>
					<div class="me-card-cell">
						<SvgIcon
							name="setting-personal"
							class="me-card-cell-icon" />
						<div class="me-card-cell-title">用户信息管理</div>
					</div>
				</template>
			</van-cell>
			<!-- <van-cell
				:border="false"
				is-link
				to="/setting/tradePassword">
				<template #title>
					<div class="me-card-cell">
						<SvgIcon
							name="setting-lock"
							class="me-card-cell-icon" />
						<div class="me-card-cell-title">修改支付密码</div>
					</div>
				</template>
			</van-cell> -->
			<van-cell
				:border="false"
				is-link
				to="/setting/password">
				<template #title>
					<div class="me-card-cell">
						<SvgIcon
							name="setting-panel"
							class="me-card-cell-icon" />
						<div class="me-card-cell-title">修改登录密码</div>
					</div>
				</template>
			</van-cell>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {}
	},
	methods: {}
}
</script>

<style scoped lang="less">
.mt16 {
	margin-top: 16px;
}
.me {
	min-height: calc(100vh - var(--navbar-height));
	padding: 24px 24px calc(var(--tabbar-height) + 30px);
	background-color: var(--black-5);
	.me-card {
		overflow: hidden;
		background: #ffffff;
		box-shadow: 10px 24px 54px 0px rgba(15, 13, 35, 0.04);
		border-radius: 16px;
		.me-card-cell {
			display: flex;
			align-items: center;
			.me-card-cell-icon {
				width: 24px;
				height: 24px;
			}
			.me-card-cell-title {
				padding-left: 10px;
				color: var(--black-100);
				font-size: 14px;
			}
		}
	}
	.me-log-out {
		padding-top: 15px;
		text-align: center;
		.verify-reset-btn {
			font-size: 16px;
			color: var(--black-40);
		}
	}
}
</style>
