import api from 'utils/http/axios'
import { BASE_URL } from 'utils/http/base-url'

export default {
	// 查询房东详情
	getLandlordDetail(params = {}, config) {
		return api.post(
			BASE_URL + '/app/paymentRent/queryLandlordDetail',
			params,
			config
		)
	},
	/**
	 * @desc 新增房东账户 -- step1
	 * @return {*}
	 */
	addLandlordInfo(params = {}, config) {
		return api.post(
			BASE_URL + '/app/paymentRent/addLandlordAccount',
			params,
			config
		)
	},
	/**
	 * @desc 编辑房东账户 -- step1
	 * 相对上面的新增房东信息接口 此接口需要额外传参landlordNo
	 * @return {*}
	 */
	editLandlordInfo(params = {}, config) {
		return api.post(BASE_URL + '/app/paymentRent/editLandlord', params, config)
	},
	// 新增房东信息 -- step2
	addLandlordAccount(params = {}, config) {
		return api.post(
			BASE_URL + '/app/paymentRent/addLandlordInfo',
			params,
			config
		)
	},
	// 新增房东文件 -- step3
	addLandlordFile(params = {}, config) {
		return api.post(
			BASE_URL + '/app/paymentRent/addLandlordFile',
			params,
			config
		)
	},
	// 提交房东 -- step4
	submitLandlord(params = {}, config) {
		return api.post(
			BASE_URL + '/app/paymentRent/submitLandlord',
			params,
			config
		)
	},
	// 查询aba银行信息 -- step1
	selectAbaBankInfo(params = {}, config) {
		return api.post(
			BASE_URL + '/app/abaBankDirectory/selectAbaBankInfo',
			params,
			config
		)
	}
}
