<template>
	<div class="edit-user">
		<div class="edit-user-title">名字中带特殊字符</div>
		<div class="edit-user-input">
			<div class="edit-user-input-title">
				<span>中文姓氏</span>
				<span class="edit-user-input-title-right">如果姓名带有“·”可点击</span>
			</div>
			<div class="edit-user-input-content">
				<FieldInput
					v-model="form.surNameCn"
					maxlength="30"
					:formatter="nameFormatter"
					placeholder="请输入姓"
					@blur="surnameCnBlur" />
				<van-button
					class="edit-user-input-content-btn"
					@click="surnameCnDotAction">
					<SvgIcon
						name="dot"
						class="edit-user-input-content-btn-icon" />
				</van-button>
			</div>
		</div>
		<div class="edit-user-input">
			<div class="edit-user-input-title">
				<span>中文名字</span>
				<span class="edit-user-input-title-right">如果姓名带有“·”可点击</span>
			</div>
			<div class="edit-user-input-content">
				<FieldInput
					v-model="form.gavenNameCn"
					maxlength="30"
					:formatter="nameFormatter"
					placeholder="请输入名"
					@blur="firstNameCnBlur" />
				<van-button
					class="edit-user-input-content-btn"
					@click="firstNameCnDotAction">
					<SvgIcon
						name="dot"
						class="edit-user-input-content-btn-icon" />
				</van-button>
			</div>
		</div>
		<div class="edit-user-input">
			<div class="edit-user-input-title">
				<span>中文名称</span>
				<span />
			</div>
			<div class="edit-user-input-content">
				<FieldInput
					v-model="fullCnName"
					placeholder=" "
					disabled />
			</div>
		</div>
		<div class="edit-user-btn">
			<van-button
				type="red"
				round
				@click="onFinished">
				确定
			</van-button>
		</div>
	</div>
</template>

<script>
import Pinyin from 'pinyin'
import validate from 'utils/validate'
export default {
	name: 'CerInfoInfoCatch',
	data() {
		return {
			form: {
				surNameCn: '',
				gavenNameCn: ''
			}
		}
	},
	computed: {
		fullCnName() {
			return (this.form.surNameCn || '') + (this.form.gavenNameCn || '')
		}
	},
	created() {
		this.form.gavenNameCn = this.$route.query.gavenNameCn
		this.form.surNameCn = this.$route.query.surNameCn
		this.form.surNameEn = this.$route.query.surNameEn
		this.form.gavenNameEn = this.$route.query.gavenNameEn
	},
	methods: {
		nameFormatter(value) {
			return value
				.replace(new RegExp(validate.noZhAndPoint, 'g'), '')
				.toUpperCase()
		},
		surnameCnDotAction() {
			this.form.surNameCn = (this.form.surNameCn || '') + '·'
		},

		firstNameCnDotAction() {
			this.form.gavenNameCn = '·' + (this.form.gavenNameCn || '')
		},

		surnameCnBlur() {
			this.form.surNameEn = this.toPinyin(this.form.surNameCn)
		},
		firstNameCnBlur() {
			this.form.gavenNameEn = this.toPinyin(this.form.gavenNameCn)
		},
		toPinyin(cnStr = '') {
			//返回二维数组，第一维每个数组项位置对应每个中文字符串位置。
			//第二维是各个汉字的读音列表，多音字会有多个拼音项。
			//eg: [ [ 'zhōng' ], [ 'xīn' ] ]
			let result = Pinyin(cnStr, {
				heteronym: true, // 启用多音字模式
				style: Pinyin.STYLE_NORMAL
			})
			let pinyinName = this.joinPinyin(result)
			return pinyinName
		},

		joinPinyin(arr = []) {
			let result = ''
			arr.forEach(val => {
				result += val[0]
				if (val.length > 1) {
					this.heteronymSum++
				}
			})
			return result.toUpperCase()
		},

		onFinished() {
			this.$EventBus.$emit('EditUserNameOnFinished', this.form)
			this.$router.back()
		}
	}
}
</script>

<style lang="less" scoped>
.edit-user {
	min-height: calc(100vh - var(--navbar-height));
	background-color: var(--white);
	padding: 14px 24px;
	font-size: 14px;
	color: var(--main-03);
	.edit-user-title {
		color: var(--main-01);
		padding-bottom: 4px;
	}
	.edit-user-input {
		.edit-user-input-title {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-bottom: 12px;
			padding-top: 14px;
			.edit-user-input-title-right {
				color: var(--red-01);
			}
		}
		.edit-user-input-content {
			display: flex;
			align-items: center;
			justify-content: space-between;
			.edit-user-input-content-btn {
				margin-left: 10px;
				width: 40px;
				height: 40px;
				background-color: var(--main-08);
				.edit-user-input-content-btn-icon {
					width: 14px;
					height: 14px;
				}
			}
		}
	}
	.edit-user-btn {
		padding-top: 50px;
	}
}
</style>
