<template>
	<div class="result-wrapper">
		<div class="img">
			<img
				:src="imgObj[status] || imgObj['SUCCESS']"
				alt="" />
		</div>
		<p class="title">{{ info.title }}</p>
		<p class="desc">{{ info.desc }}</p>
		<template v-if="info.btnText">
			<van-button
				round
				type="black"
				class="btn"
				:loading="loading"
				@click="handleClick">
				{{ info.btnText || '返回' }}
			</van-button>
		</template>
		<slot name="btnList" />
	</div>
</template>

<script>
import successPic from '@/assets/image/common/img-success.png'
import failedPic from '@/assets/image/common/img-fail.png'
import distancePic from '@/assets/image/common/img-distance.png'

export default {
	props: {
		status: {
			type: String,
			default: 'SUCCESS'
		},
		loading: {
			type: Boolean,
			default: false
		},
		info: {
			type: Object,
			default() {
				return {
					title: '',
					desc: ''
				}
			}
		}
	},
	data() {
		return {
			imgObj: {
				SUCCESS: successPic,
				FAILED: failedPic,
				DISTANCE: distancePic
			}
		}
	},
	methods: {
		handleClick() {
			this.$router.replace('/')
		}
	}
}
</script>

<style scoped lang="less">
.result-wrapper {
	width: 100%;
	text-align: center;
	.img {
		width: 140px;
		height: 140px;
		margin: 0 auto 30px;
		> img {
			display: block;
			width: 100%;
			height: 100%;
		}
	}
	.title {
		margin-bottom: 16px;
		font-size: 20px;
		color: var(--main-01);
		line-height: 20px;
	}
	.desc {
		margin-bottom: 30px;
		font-size: 14px;
		color: var(--main-03);
		line-height: 24px;
		padding: 0 22px;
	}
}
</style>
