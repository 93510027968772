<template>
	<div
		class="card-item"
		:class="[
			{ person: info.cardHolder == 'REGISTRANT' },
			{
				'other-authorized':
					info.cardHolder == 'AGENT_PAYOR' &&
					info.authorizedStatus == 'AUTHORIZED'
			},
			{
				'other-unauthorized':
					info.cardHolder == 'AGENT_PAYOR' &&
					info.authorizedStatus == 'TO_BE_AUTHORIZED'
			}
		]"
		@click="$emit('click', info)">
		<div class="card-person">
			<div class="card-person-name">
				{{ info.cardHolderSurName }}{{ info.cardHolderGavenName }}
				<span class="type-name">
					({{ info.cardHolder == 'REGISTRANT' ? '本人' : '代缴人' }})
				</span>
				<span
					v-if="info.cardHolder == 'AGENT_PAYOR'"
					class="card-person-type"
					:class="[
						{ succss: info.authorizedStatus == 'AUTHORIZED' },
						{ warn: info.authorizedStatus == 'TO_BE_AUTHORIZED' }
					]">
					{{ info.authorizedStatus === 'AUTHORIZED' ? '已授权' : '未授权' }}
				</span>
			</div>
		</div>
		<div class="desc">{{ info.bankName }} | {{ info.cardTypeCn }}</div>
		<div class="card-num">
			<span class="num">{{ info.bankCardNo }}</span>
		</div>
		<slot />
	</div>
</template>
<script>
export default {
	props: {
		info: {
			type: Object,
			default: () => {}
		}
	}
}
</script>
<style lang="less" scoped>
.card-item {
	position: relative;
	background: var(--main-08);
	border-radius: 20px;
	font-weight: 400;
	font-size: 16px;
	line-height: 16px;
	padding: 17px 16px;
	&.person {
		background-image: url('~@/assets/image/card/bg-person.png');
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}
	&.other-authorized {
		background-image: url('~@/assets/image/card/bg-other-authorized.png');
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}
	&.other-unauthorized {
		background-image: url('~@/assets/image/card/bg-other-unauthorized.png');
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}
	& + .card-item {
		margin-top: 14px;
	}
	.card-person {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.card-person-name {
			padding: 4px 0;
			display: flex;
			align-items: center;
		}
		.type-name {
			font-size: 14px;
		}
		.card-person-type {
			margin-left: 10px;
			min-width: 56px;
			min-height: 24px;
			display: flex;
			justify-content: center;
			align-items: center;
			color: var(--white);
			border-radius: 200px;
			font-size: 12px;
			&.succss {
				background: var(--green-01);
			}
			&.warn {
				background: var(--yellow-01);
			}
		}
	}
	.desc {
		font-size: 12px;
		color: var(--main-03);
		line-height: 12px;
		margin: 12px 0;
	}
	.card-num {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		.num {
			font-size: 20px;
			color: var(--main-01);
			font-weight: bold;
			line-height: 20px;
		}
	}
	.card-person-btn {
		position: absolute;
		top: 16px;
		right: 16px;
		width: 88px;
		height: 30px;
	}
	.send-btn {
		position: absolute;
		bottom: 16px;
		right: 16px;
		width: 88px;
		height: 32px;
		font-size: 12px;
		padding: 10px 0;
	}
}
</style>
