<template>
	<div class="rent-container">
		<keep-alive>
			<router-view />
		</keep-alive>
	</div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
	beforeRouteLeave(to, form, next) {
		console.log(to, form)
		const whiteList = [
			'RentPayment',
			'RentPaymentConfirm',
			'Landlord',
			'LandlordList',
			'CardList'
		]
		if (!whiteList.includes(to.name)) {
			this.INIT_RENT_FORM_INFO()
		}
		next()
	},
	data() {
		return {}
	},
	methods: {
		...mapMutations('rent', ['INIT_RENT_FORM_INFO'])
	}
}
</script>

<style scoped lang="less">
.rent-container {
	min-height: calc(100vh - var(--navbar-height));
	padding: 0 24px 30px;
	background-color: var(--white);
}
</style>
