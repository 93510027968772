<template>
	<van-action-sheet
		class="auth-action-sheet"
		v-bind="$attrs"
		:actions="actions"
		cancel-text="取消"
		close-on-click-action
		v-on="$listeners" />
</template>

<script>
export default {
	data() {
		return {
			// Action Sheet
			showActionSheet: false,
			actions: [
				{
					name: '发送授权短信',
					subname: '（向持卡人银行卡预留手机号发送授权短信）',
					value: 'action-sms',
					alert: {
						// title: '通知代缴人授权付款卡',
						title: '提示',
						message: '该银行卡的授权链接已发送持卡人，请联系持卡人做授权处理。'
					}
				},
				{
					name: '复制授权信息',
					subname: '（复制后可通过社交平台发给持卡人）',
					value: 'action-copy',
					alert: {
						title: '提示',
						message: '授权信息复制成功'
					}
				}
			]
		}
	},
	created() {
		this.showActionSheet = true
	},
	methods: {}
}
</script>

<style scoped lang="less">
.auth-action-sheet {
	max-width: var(--max-layout-width);
	margin: 0 auto;
	left: 0;
	right: 0;
	bottom: 0;
	// left: calc((100vw - max(100vw, 640px)) / 2);
	// left: 50%;
	// transform: translateX(-50%);
}
</style>
