import commonApi from '@/api/common'

export default {
	data() {
		return {
			// 下拉列表数据
			stateList: [],
			cityList: [],
			districtList: []
		}
	},
	created() {
		this.queryStateAndCityList({ pareaCode: 'US', target: 'stateList' })
	},
	methods: {
		queryStateAndCityList({ pareaCode, target }) {
			return commonApi
				.queryStateAndCityList({
					pareaCode,
					countryCode: 'US'
				})
				.then(res => {
					this[target] = res
				})
				.catch(console.error)
		},
		// 选择state
		handleChangeState(areaCode, { changeType }) {
			this.cityList = []
			this.districtList = []
			if (changeType === 'bank') {
				this.formData.bankCity = ''
				this.formData.bankDistrict = ''
			} else if (changeType === 'landlord') {
				this.formData.landlordCity = ''
				this.formData.landlordDistrict = ''
			}
			if (areaCode) {
				this.queryStateAndCityList({ pareaCode: areaCode, target: 'cityList' })
			}
		},
		// 选择city
		handleChangeCity(areaCode, { changeType }) {
			this.districtList = []
			if (changeType === 'bank') {
				this.formData.bankDistrict = ''
			} else if (changeType === 'landlord') {
				this.formData.landlordDistrict = ''
			}
			if (areaCode) {
				this.queryStateAndCityList({
					pareaCode: areaCode,
					target: 'districtList'
				})
			}
		}
	}
}
