<template>
	<div class="content-service">
		<img
			class="qrcode"
			src="@/assets/image/help/qrcode.png"
			alt="" />
		<div class="tip-container">
			<div class="title">添加微信方法：</div>
			<ul class="desc-list">
				<li class="desc-item">
					<span>方法1:</span>
					将二维码截屏并存到手机相册；打开微信，点击右上角
					<SvgIcon
						name="add-circle"
						class="icon" />
					，选择扫一扫，然后从相册中选择已保存的二维码，即可添加客服人员微信。
				</li>
				<li class="desc-item">
					<span>方法2:</span>
					在电脑中打开此网页，使用微信扫一扫，即可添加客服人员微信。
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {}
	},
	methods: {}
}
</script>

<style scoped lang="less">
.content-service {
	.qrcode {
		width: 100%;
	}
	.tip-container {
		padding: 36px 40px;
		.title {
			font-size: 14px;
			color: var(--main-02);
			line-height: 20px;
			font-weight: bold;
			margin-bottom: 10px;
		}
		.desc-list {
			font-size: 12px;
			color: var(--main-03);
			line-height: 20px;
			.desc-item {
				& + .desc-item {
					margin-top: 10px;
				}
				.icon {
					font-size: 16px;
					vertical-align: sub;
				}
				.link {
					color: var(--blue-01);
				}
			}
		}
	}
}
</style>
