import api from '@/utils/http/axios'
import { BASE_URL } from '@/utils/http/base-url'
export default {
	// 注册极验滑块V4
	geeTestRegisterV4(params = {}, config = {}) {
		return api.post(BASE_URL + '/app/app/geeTestRegisterV4', params, config)
	},
	// 上传文件
	uploadFile(params = {}, config = {}) {
		return api.post(BASE_URL + '/app/file/upload', params, config, 'FormData')
	},
	// 下载文件
	downloadFile(params = {}, config = {}) {
		return api.post(BASE_URL + '/app/file/download', params, {
			...config,
			responseType: 'blob'
		})
	},
	// 获取登陆 - 短信验证码
	getSMSCode(params = {}, config = {}) {
		return api.post(BASE_URL + '/app/sendCode', params, config)
	},
	// 发送交易验证码
	sendTradeCode(params = {}, config = {}) {
		return api.post(BASE_URL + '/app/sendTradeCode', params, config)
	},

	// 获取协议
	getProtocol(params = {}, config = {}) {
		return api.post(BASE_URL + '/app/userProtocol', params, config)
	},
	// // 发送语音验证码-极验滑块
	// geeTestSendVoiceCode(params = {}, config) {
	// 	return api.post(
	// 		BASE_URL + '/v1/common/personal/voice/appcodeV4',
	// 		params,
	// 		config
	// 	)
	// },

	// 公共枚举接口
	getCommonEnums(params = {}, config) {
		return api.post(BASE_URL + '/app/app/commonEnums', params, config)
	},
	// 国码接口
	queryCountryCodeList(params = {}, config) {
		return api.post(BASE_URL + '/app/app/countryAreaCodeOption', params, config)
	},
	// 查询省市区
	queryStateAndCityList(params = {}, config) {
		return api.post(BASE_URL + '/app/common/dict/queryAreaList', params, config)
	}
}
