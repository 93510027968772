import Login from '@/views/login/Index'
export default [
	{
		path: '/login',
		name: 'Login',
		meta: {
			title: '登录',
			hideNavBar: true
		},
		component: Login
	},
	// {
	// 	path: '/CountryList',
	// 	name: 'CountryList',
	// 	meta: {
	// 		title: '国码',
	// 		hideNavBar: true
	// 	},
	// 	component: () => import('@/views/login/CountryList.vue')
	// },
	// {
	// 	path: '/login/forgotten',
	// 	name: 'LoginForgottenPassword',
	// 	meta: {
	// 		title: '忘记密码'
	// 	},
	// 	component: () => import('@/views/login/resetPassword/Index.vue')
	// },
	// {
	// 	path: '/login/reset-password',
	// 	name: 'LoginResetPassword',
	// 	meta: {
	// 		title: '重置密码'
	// 	},
	// 	component: () => import('@/views/login/resetPassword/ResetPassword.vue')
	// },
	{
		path: '/login/password',
		name: 'LoginPassword',
		meta: {
			title: '设置登陆密码',
			hideNavBarLeft: true,
			hideNavBarRight: true
		},
		component: () => import('views/login/setPassword/SetPassword.vue')
	},
	{
		path: '/login/transactionPassword',
		name: 'LoginSetTransactionPassword',
		meta: {
			title: '设置支付密码',
			hideNavBarLeft: true,
			hideNavBarRight: true
		},
		component: () =>
			import('views/login/setPassword/SetTransactionPassword.vue')
	}
]
