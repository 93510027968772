<template>
	<van-form
		class="van-form vertical"
		v-bind="$attrs"
		v-on="$listeners">
		<slot />
	</van-form>
</template>

<script>
export default {}
</script>

<style lang="less">
.van-form {
	&.vertical {
		.form-item {
			margin-bottom: 12px;
		}
	}
}
</style>
