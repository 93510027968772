<template>
	<div
		v-loading="downLoading"
		class="view-container"
		@click="handleViewFile">
		<div
			v-if="del"
			class="delete-icon"
			@click="handleDeleteFile">
			<SvgIcon
				class="icon"
				name="close" />
		</div>
		<!-- 占位图 -->
		<div class="view-img">
			<div :class="'partly'">
				<img
					v-if="placeholderImg"
					:src="placeholderImg" />
			</div>
			<!-- <div class="file-name text-overflow-line-2">
				{{ file.fileName || file.name }}
			</div> -->
		</div>
		<div class="index">{{ index + 1 }}</div>
		<!-- 查看按钮 -->
		<SvgIcon
			class="view-icon"
			name="search"
			@click="handleViewFile" />
		<!--<div-->
		<!--  class="view-icon"-->
		<!--  @click="handleViewFile"-->
		<!--&gt;</div>-->
		<el-image-viewer
			v-if="showViewer"
			:z-index="imageZindex"
			:on-close="closeViewer"
			:url-list="[url]" />
	</div>
</template>

<script>
import commonApi from '@/api/common'
import { openBlob, downloadBlob, isPC } from '@/utils/utils'

import picturePng from '@/assets/image/files/img-picture.png'
import pdfPng from '@/assets/image/files/img-pdf.png'
import excelPng from '@/assets/image/files/img-excel.png'
// 导入组件
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
	components: {
		ElImageViewer
	},
	props: {
		/*
      type mime类型
      name 文件名 如“CBTest.pdf”
        - fileName (庐山相关接口要转化)
      path 文件路径
        - 前端项目路径，需要额外使用staticAssets标识
        - 后端项目路径（企业网银旧接口）
        - fileNo (庐山相关接口要转化)
      staticAssets 文件是否是前端服务器上的静态资源
    */
		file: {
			type: Object,
			default() {
				return {}
			}
		},
		index: {
			type: Number,
			default: 0
		},
		// 是否可删除
		del: {
			type: Boolean,
			default: true
		},
		// 特殊接口展示格式不同，需要兼容
		uploadType: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			downLoading: false,
			// 占位图
			placeholderImg: picturePng,
			mimes: [
				{
					mime: 'application/pdf',
					regExp: /\.pdf$/,
					openInBrowser: true
				},
				{
					mime: 'image/jpg',
					regExp: /\.jpg$/,
					openInBrowser: true
				},
				{
					mime: 'image/jpeg',
					regExp: /\.jpeg$/,
					openInBrowser: true
				},
				{
					mime: 'image/png',
					regExp: /\.png$/,
					openInBrowser: true
				},
				{
					mime: 'application/vnd.ms-excel',
					regExp: /\.xls$/
				},
				{
					mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					regExp: /\.xlsx$/
				}
			],
			showViewer: false,
			url: '',
			imageZindex: 2040
		}
	},
	watch: {
		file: {
			handler(val) {
				// const { type, name, path, staticAssets, uploadType: uploadTypeItem } = val
				val.name = val.name || val.fileName
				val.path = val.path || val.fileNo
				const { type, name, path } = val
				if (!path) return
				// 保证文件都有type 如果没有type 则根据文件名兼容
				if (!type) {
					const target = this.mimes.filter(item =>
						item.regExp.test(name.toLowerCase())
					)
					if (target && target.length) {
						// 如果有满足条件的mime的话
						val.type = target[0].mime
					} else {
						// 否则，先暂且按 application/pdf 处理
						val.type = 'application/pdf'
					}
				}
				switch (val.type) {
					case 'application/pdf':
						this.placeholderImg = pdfPng
						break
					case 'application/vnd.ms-excel':
					case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
						this.placeholderImg = excelPng
						break
					case 'image/jpg':
					case 'image/jpeg':
					case 'image/png':
						this.placeholderImg = picturePng
						break
					default:
						this.placeholderImg = picturePng
						break
				}
				// 根据mime处理
				// if (val.type === 'application/pdf') {
				//   this.placeholderImg = pdfPng
				// } else if (val.type === 'application/vnd.ms-excel' || val.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
				//   this.placeholderImg = excelPng
				// } else {
				//   console.log(val, staticAssets, uploadTypeItem)
				/*
            如果是静态，标明此资源是在前端服务器上，不再请求后端。
            pdf、excel、img均可以使用该属性。
            但由于此处watch监听，只有img要调用接口，所以只在此处图片的判断逻辑中添加。
          */
				// if (staticAssets) {
				//   this.placeholderImg = path
				//   return
				// }
				// this.download({ path, uploadTypeItem }).then(res => {
				//   if (res) {
				//     // blobUrl在IE浏览器上能正常展示 所以这里不作额外处理
				//     this.placeholderImg = URL.createObjectURL(res)
				//   }
				// }).catch(err => {
				//   console.error(err)
				// })
				// }
			},
			immediate: true
		}
	},
	mounted() {
		this.getZindex()
	},
	methods: {
		getZindex() {
			this.$nextTick(() => {
				this.imageZindex = 2040
			})
		},
		// 增加单个文件的类型，由于后端存储服务迁移，导致老数据会出现多个下载源的情况，所以这里先判断单个文件的类型
		download({ path, fileNo, uploadTypeItem }) {
			return commonApi.downloadFile({
				fileNo
			})
		},
		handleDeleteFile() {
			const { file } = this
			this.$emit('delete', file)
		},
		handleViewFile() {
			const {
				type,
				path,
				fileNo,
				fileName,
				staticAssets,
				uploadType: uploadTypeItem
			} = this.file
			console.log(this.file)
			if (!type || !path) return
			// 如果文件没在后端资源服务器上 不再请求后端 直接利用浏览器打开
			if (staticAssets) {
				// this.url = path
				// this.showViewer = true
				window.open(path)
			} else {
				this.downLoading = true
				this.download({ path, fileNo, uploadTypeItem })
					.then(res => {
						if (res) {
							// const file = new Blob([res], { type })
							// const href = URL.createObjectURL(file)
							// this.url = href
							// this.showViewer = true
							if (isPC()) {
								openBlob(res, type, this.file)
							} else {
								downloadBlob(
									res,
									{
										filename: fileName,
										ext: type
									},
									this.file
								)
							}
						}
					})
					.catch(err => {
						console.error(err)
					})
					.finally(() => {
						this.downLoading = false
					})
			}
		},
		// 关闭查看器
		closeViewer() {
			this.showViewer = false
		}
	}
}
</script>

<style scoped lang="less">
.view-container {
	position: relative;
	width: 90px;
	height: 90px;
	margin-right: 14px;
	margin-bottom: 28px;
	border-radius: 4px;
	border: 1px solid #e6e9f4;
	&:hover {
		border: 1px solid #4a6aff;
	}
	.delete-icon {
		position: absolute;
		right: 0;
		top: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		transform: translate(30%, -30%);
		width: 24px;
		height: 24px;
		font-size: 20px;
		cursor: pointer;
		background: var(--white);
		border: 1px solid var(--main-07);
		border-radius: 100px;
		&:hover {
			.icon {
				fill: var(--red-01);
			}
		}
		.icon {
			fill: var(--main-03);
		}
	}
	.view-img {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		width: 100%;
		height: 100%;
		padding: 10px;
		.partly {
			width: 70px;
			height: 70px;
			img {
				display: block;
				width: 100%;
				height: 100%;
			}
		}
		.file-name {
			flex: 1;
			padding-top: 4px;
			margin-left: 14px;
			font-size: 14px;
			font-family:
				PingFang SC-Medium,
				PingFang SC;
			font-weight: 400;
			color: var(--main-01);
			line-height: 20px;
		}
	}
	.index {
		padding: 5px 0;
		text-align: center;
		font-size: 14px;
		color: var(--main-04);
	}
	.view-icon {
		position: absolute;
		right: 42px;
		bottom: 5px;
		width: 24px;
		height: 24px;
		cursor: pointer;
		fill: #7e84a3;
		&:hover {
			fill: #1460ff;
		}
	}
}
</style>
