<template>
	<input
		accept="image/*"
		type="file"
		v-bind="$attrs"
		v-on="$listeners"
		@click="handleClick"
		@focus="handleFocus" />
</template>
<script>
export default {
	name: 'CameraUpload',
	methods: {
		handleClick() {
			this.startTime = new Date().getTime()
		},
		handleFocus() {
			this.EndTime = new Date().getTime()
			if (this.EndTime - this.startTime < 700) {
				this.$dialog
					.alert({
						title: '读取权限失败',
						message:
							'浏览器未能获取访问相册或摄像头权限，请在设置>权限管理中启用',
						closeOnPopstate: false,
						confirmButtonText: '确 定'
					})
					.then(() => {})
			}
		}
	}
}
</script>
<style scoped lang="less"></style>
