import CardHolder from './cardHolder'
export default [
	{
		path: '/face-validate',
		name: 'FaceValidate',
		meta: {
			title: '',
			hideNavBar: true
		},
		redirect: 'face-validate/card-holder',
		component: () => import('@/views/faceValidate/index.vue'),
		children: [...CardHolder]
	}
]
