<template>
	<div class="payment-container">
		<Result
			v-if="finish"
			:status="status"
			:info="info" />
		<TimeProcessDialog
			ref="timeDialog"
			title="正在从银行卡扣款..."
			@finish="timeFinish" />
	</div>
</template>

<script>
import Result from '@/components/common/Result.vue'

import { queryDeductResult } from '@/views/rent/api/index.js'
export default {
	components: {
		Result
	},
	data() {
		return {
			finish: false,
			status: 'SUCCESS',
			info: {
				title: '',
				desc: ''
			},
			bizOrder: ''
		}
	},
	mounted() {
		this.init()
	},
	methods: {
		init() {
			const { bizOrder } = this.$route.query
			const { type } = this.$route.params
			this.bizOrder = bizOrder
			if (type) {
				this.$refs.timeDialog.showDialog()
			} else {
				this.getResult()
			}
		},

		getResult() {
			const { bizOrder } = this
			if (!bizOrder) return
			queryDeductResult({
				bizOrder
			})
				.then(res => {
					const { status, tipsCn, statusCn } = res
					this.status = status == 'CARD_PAYMENT_FAILED' ? 'FAILED' : 'SUCCESS'
					this.info = {
						title: statusCn,
						desc: tipsCn,
						message: statusCn
					}
					this.finish = true
				})
				.catch(console.log)
		},
		timeFinish() {
			this.getResult()
			this.$refs.timeDialog.closeDialog()
		}
	}
}
</script>

<style scoped lang="less">
.payment-container {
	padding-top: 16px;
	.submit-btn {
		margin-top: 20px;
	}
}
</style>
