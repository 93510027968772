<template>
	<div class="form-module-container">
		<p class="title">{{ title }}</p>
		<slot />
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: ''
		}
	},
	data() {
		return {}
	},
	methods: {}
}
</script>

<style scoped lang="less">
.form-module-container {
	padding-bottom: 12px;
	.title {
		margin-bottom: 20px;
		font-size: 14px;
		color: var(--main-01);
	}
}
</style>
