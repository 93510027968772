<template>
	<div class="home-container">
		<HomeNavBar />
		<ul class="tab-list">
			<li
				class="tab-item"
				@click="$router.push({ name: 'RentPayment' })">
				<div class="icon-wrapper">
					<SvgIcon name="house" />
				</div>
				<p class="text">付房租</p>
			</li>
			<li
				class="tab-item"
				@click="$router.push({ name: 'LandlordList' })">
				<div class="icon-wrapper">
					<SvgIcon name="landlord" />
				</div>
				<p class="text">房东</p>
			</li>
			<li
				class="tab-item"
				@click="$router.push({ name: 'CardList' })">
				<div class="icon-wrapper">
					<SvgIcon name="card" />
				</div>
				<p class="text">银行卡</p>
			</li>
		</ul>
		<div class="bills">
			<p class="title">账单</p>
			<ul class="bills-filter-list">
				<li
					v-for="(item, index) in billsStatusList"
					:key="index"
					class="bills-filter-item"
					:class="item.actived ? 'actived' : ''"
					@click="handleBillsStatusClick(item)">
					<div class="bills-filter-item-container">
						<SvgIcon
							:name="item.icon"
							class="icon" />
						<p class="text">{{ item.text }}</p>
					</div>
				</li>
			</ul>
		</div>
		<div class="bills-wrapper">
			<div class="separation">
				<img
					src="@/assets/image/common/separation.png"
					alt="" />
			</div>
			<van-list
				v-if="billsList && billsList.length > 0"
				v-model="loading"
				:finished="finished"
				finished-text="没有更多了"
				class="bills-list"
				@load="getBillsList">
				<div
					v-for="(item, index) in billsList"
					:key="index"
					class="bills-item"
					@click.stop="handleClickDetail(item)">
					<div
						class="row flex space-between border-bottom-dashed padding-top-20 padding-bottom-14">
						<p class="text-14">{{ item.createTimeStr }}</p>
						<p
							class="status"
							:class="[item.status]">
							{{ item.statusCn }}
						</p>
					</div>
					<div class="row border-bottom-solid">
						<div
							class="row flex space-between text-12 padding-top-14 padding-bottom-12">
							<p>{{ item.payeeAccountNo }}</p>
							<p>{{ item.payeeAmount }}</p>
						</div>
						<div class="row flex space-between text-12 padding-bottom-14">
							<p>{{ item.payeeName }}</p>
							<p>{{ item.currency }}</p>
						</div>
					</div>
					<div class="bills-item-footer row flex space-between">
						<div class="left">
							<div
								v-if="item.status === 'PAYOUT_FAILED'"
								class="operation row flex"
								@click.stop="handleClickChangeLandlord(item)">
								<SvgIcon
									name="bills-list-switch"
									class="icon" />
								<p>换房东再汇款</p>
							</div>
							<div
								v-if="item.status === 'WAITING_FOR_AUTHORIZATION'"
								class="operation row flex"
								@click.stop="handleClickNotify(item)">
								<SvgIcon
									name="bills-list-notify"
									class="icon" />
								<p>发送授权通知</p>
							</div>
						</div>
						<div class="right">
							<div
								v-if="item.status === 'PAYOUT_SUCCESS'"
								class="operation row flex"
								@click.stop="handleClickStatement(item)">
								<SvgIcon
									name="bills-list-statement"
									class="icon" />
								<p>支付凭证</p>
							</div>
							<div
								v-if="
									item.status === 'PAYOUT_FAILED' ||
									item.status === 'WAITING_FOR_PAYOUT' ||
									item.status === 'WAITING_FOR_AUTHORIZATION'
								"
								class="operation row flex"
								@click.stop="handleClickCancel(item)">
								<SvgIcon
									name="bills-list-cancel"
									class="icon" />
								<p>取消账单</p>
							</div>
							<div
								v-if="item.status === 'CARD_PAYMENT_FAILED'"
								class="operation row flex"
								@click.stop="handleClickAgain(item)">
								<SvgIcon
									name="bills-list-again"
									class="icon" />
								<p>再试一次</p>
							</div>
						</div>
					</div>
				</div>
			</van-list>
			<!-- 空数据 -->
			<div
				v-else
				class="bills-list-empty">
				<div class="empty-img">
					<img
						src="@/assets/image/common/empty.png"
						alt="" />
				</div>
				<p class="empty-text">暂无账单信息～</p>
			</div>
			<AuthActionSheet
				v-model="showAuthActionSheet"
				@select="handleActionSheetSelect" />
		</div>
	</div>
</template>

<script>
import { getBillsList } from '@/views/rent/api'
import { sendAuthSms } from '@/views/card/api'
import { mapState, mapActions } from 'vuex'

export default {
	data() {
		return {
			billsStatusList: [
				{ icon: 'bills-all', text: '全部', code: '', actived: true },
				{
					icon: 'bills-auth',
					text: '待授权',
					code: 'WAITING_FOR_AUTHORIZATION',
					actived: false
				},
				{
					icon: 'bills-transfer',
					text: '待结算',
					code: 'WAITING_FOR_PAYOUT',
					actived: false
				},
				{
					icon: 'bills-deduct-error',
					text: '扣款失败',
					code: 'CARD_PAYMENT_FAILED',
					actived: false
				},
				{
					icon: 'bills-transfer-error',
					text: '结算失败',
					code: 'PAYOUT_FAILED',
					actived: false
				},
				{
					icon: 'bills-transfer-success',
					text: '结算成功',
					code: 'PAYOUT_SUCCESS',
					actived: false
				}
			],
			billsStatus: '',
			pageNo: 1,
			pageSize: 5,

			// 账单列表
			loading: false,
			finished: false,
			billsList: [],
			// Action Sheet
			showAuthActionSheet: false,
			authActionSheetItem: {}
		}
	},
	computed: {
		...mapState('app', ['userInfo'])
	},
	watch: {
		billsStatus: {
			handler() {
				this.pageNo = 1
				this.billsList = []
				this.getBillsList()
			},
			immediate: true
		}
	},
	created() {
		// this.getEnumsList()
	},
	methods: {
		...mapActions('enums', ['getEnumsList']),
		getBillsList() {
			const { cstNo } = this.userInfo
			const { billsStatus: status, pageNo, pageSize } = this
			return getBillsList({
				cstNo,
				status,
				pageNo,
				pageSize
			})
				.then(res => {
					const { records, total } = res
					this.billsList = this.billsList.concat(records)
					this.loading = false
					if (this.billsList.length >= total) {
						this.finished = true
						return
					} else {
						// 必需的
						this.finished = false
					}
					this.pageNo += 1
				})
				.catch(console.error)
		},
		// 点击账单状态
		handleBillsStatusClick(item) {
			this.billsStatusList.forEach(billsItem => {
				billsItem.actived = false
			})
			item.actived = true
			this.billsStatus = item.code
		},
		// 支付凭证
		handleClickStatement(item) {
			const { bizOrder } = item
			this.$router.push({
				name: 'BillsPaymentStatement',
				query: {
					bizOrder
				}
			})
		},
		// 发送授权通知
		handleClickNotify(item) {
			this.showAuthActionSheet = true
			this.authActionSheetItem = item
		},
		// 选择授权方式
		handleActionSheetSelect(actionSheet) {
			const {
				value,
				alert: { title, message }
			} = actionSheet
			const { cardInfoNo } = this.authActionSheetItem
			const loading = this.$toast.loading()
			const hasSendSms = value === 'action-sms'
			sendAuthSms({
				cardInfoNo,
				hasSendSms
			})
				.then(res => {
					if (hasSendSms) {
						this.$dialog.alert({
							title,
							message,
							confirmButtonText: '确 定'
						})
					} else {
						const { authorizeShortLink } = res
						const timer = window.setTimeout(() => {
							this.$copyText(authorizeShortLink)
								.then(_ => {
									this.$dialog.alert({
										title,
										message,
										confirmButtonText: '确 定'
									})
								})
								.catch(_ => {
									this.$dialog.alert({
										title: '提示',
										message: '复制授权信息失败，浏览器版本不支持',
										confirmButtonText: '确 定'
									})
								})
							window.clearTimeout(timer)
						}, 100)
					}
				})
				.catch(console.error)
				.finally(() => {
					loading.clear()
				})
		},
		// 账单详情
		handleClickDetail(item) {
			const { bizOrder, status } = item
			if (status === 'CANCEL') {
				this.$router.push({
					name: 'BillsPaymentCancelDetail',
					query: {
						bizOrder
					}
				})
			} else {
				this.$router.push({
					name: 'BillsPaymentDetail',
					query: {
						bizOrder
					}
				})
			}
		},
		// 取消账单
		handleClickCancel(item) {
			const { bizOrder } = item
			this.$router.push({
				name: 'RentPaymentCancel',
				query: {
					bizOrder
				}
			})
		},
		// 修改房东
		handleClickChangeLandlord(item) {
			const { bizOrder } = item
			this.$router.push({
				name: 'RentPaymentChangeLandlord',
				query: {
					bizOrder
				}
			})
		},
		// 再试一次
		handleClickAgain(item) {
			const { bizOrder } = item
			this.$router.push({
				name: 'RentPaymentAgain',
				query: {
					bizOrder
				}
			})
		}
	}
}
</script>

<style scoped lang="less">
.home-container {
	.tab-list {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 12px;
		padding: 12px 20px;
		background: linear-gradient(180deg, #ffffff 0%, #f5f6fa 100%);
		box-shadow: 0px 4px 20px -10px rgba(0, 0, 0, 0.02);
		.tab-item {
			width: 100px;
			padding: 12px 0;
			text-align: center;
			cursor: pointer;
			border-radius: 10px;
			&.actived {
				background: var(--white);
			}
			.icon-wrapper {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 38px;
				height: 38px;
				margin: 0 auto 10px;
				font-size: 24px;
				border-radius: 50%;
			}
			&:nth-child(1) {
				.icon-wrapper {
					background-color: var(--red-04);
				}
			}
			&:nth-child(2) {
				.icon-wrapper {
					background-color: var(--blue-05);
				}
			}
			&:nth-child(3) {
				.icon-wrapper {
					background-color: var(--yellow-04);
				}
			}
			.text {
				font-size: 14px;
				color: var(--main-01);
				line-height: 14px;
			}
		}
	}
	.bills {
		padding: 0 16px 18px;
		.title {
			margin-bottom: 12px;
			font-size: 16px;
			font-weight: bold;
			color: var(--main-01);
			line-height: 16px;
		}
		.bills-filter-list {
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
			padding: 15px 24px;
			font-size: 14px;
			color: var(--main-01);
			line-height: 14px;
			text-align: center;
			background: var(--white);
			border-radius: 16px;
			.bills-filter-item {
				width: 33.33%;
				display: flex;
				justify-content: center;
				align-items: center;
				&.actived {
					.bills-filter-item-container {
						background: var(--main-08);
					}
				}

				&:nth-last-child(1),
				&:nth-last-child(2),
				&:nth-last-child(3) {
					.bills-filter-item-container {
						margin-bottom: 0;
					}
				}
				.bills-filter-item-container {
					width: 80px;
					padding: 7px 0;
					border-radius: 10px;
					margin-bottom: 6px;
					cursor: pointer;

					.icon {
						margin-bottom: 12px;
						font-size: 24px;
					}
				}
			}
		}
	}
	.bills-wrapper {
		.separation {
			width: 100%;
			height: 14px;
			padding: 0 18px;
		}
		.bills-list {
			.bills-item {
				margin-bottom: 4px;
				padding: 0 20px;
				background-color: var(--white);
				&:first-child {
					border-radius: 20px 20px 0 0;
				}
				&:last-child {
					margin-bottom: 0;
				}
				.row {
					align-items: center;
					&.flex {
						display: flex;
					}
					&.space-between {
						justify-content: space-between;
					}
					&.border-bottom-dashed {
						border-bottom: 1px dashed var(--main-07);
					}
					&.border-bottom-solid {
						border-bottom: 1px solid var(--main-08);
					}
				}
				.text-12 {
					font-size: 12px;
					color: var(--main-02);
				}
				.text-14 {
					font-size: 14px;
					color: var(--main-01);
				}
				.padding-top-20 {
					padding-top: 20px;
				}
				.padding-top-14 {
					padding-top: 14px;
				}
				.padding-bottom-14 {
					padding-bottom: 14px;
				}
				.padding-bottom-12 {
					padding-bottom: 12px;
				}
				.status {
					font-size: 14px;
					&.PAYOUT_SUCCESS {
						color: var(--green-01);
					}
					&.CARD_PAYMENT_FAILED,
					&.PAYOUT_FAILED {
						color: var(--red-01);
					}
					&.WAITING_FOR_AUTHORIZATION,
					&.WAITING_FOR_PAYOUT {
						color: var(--yellow-01);
					}
				}
				.bills-item-footer {
					.left {
						flex: 1;
					}
					.right {
						min-width: 100px;
						padding: 0 10px 0 8px;
						border-left: 1px solid var(--main-08);
					}
					.operation {
						padding: 12px 0;
						cursor: pointer;
						.icon {
							margin-right: 6px;
							font-size: 20px;
						}
					}
				}
			}
		}
		.bills-list-empty {
			min-height: 328px;
			height: calc(100vh - 390px);
			padding-top: 60px;
			border-radius: 20px 20px 0 0;
			background: var(--white);
			.empty-img {
				width: 120px;
				height: 120px;
				margin: 0 auto 30px;
			}
			.empty-text {
				font-size: 14px;
				color: var(--main-03);
				line-height: 14px;
				text-align: center;
			}
		}
	}
}
</style>
