<template>
	<div class="step-container">
		<div class="title">请填写持卡人信息</div>
		<VanValidForm>
			<VanValidFormItem label="中文姓氏">
				<div class="tip-container">
					<div class="tip-title-container">如果姓名带有“·”可点击</div>
					<FieldInput
						v-model="formData.cardHolderSurName"
						placeholder="姓"
						clearable />
					<van-button
						class="btn-dotted"
						@click.stop.native="addDot('cardHolderSurName')">
						<SvgIcon
							name="dot"
							class="btn-icon" />
					</van-button>
				</div>
			</VanValidFormItem>
			<VanValidFormItem label="中文名字">
				<div class="tip-container">
					<div class="tip-title-container">如果姓名带有“·”可点击</div>
					<FieldInput
						v-model="formData.cardHolderGavenName"
						placeholder="名"
						clearable />
					<van-button
						class="btn-dotted"
						@click.stop.native="addDot('cardHolderGavenName')">
						<SvgIcon
							name="dot"
							class="btn-icon" />
					</van-button>
				</div>
			</VanValidFormItem>
			<VanValidFormItem label="身份证号">
				<FieldInput
					v-model="formData.idNo"
					placeholder="请输入身份证号"
					clearable />
			</VanValidFormItem>
			<VanValidFormItem label="银行卡号">
				<FieldInput
					v-model="formData.bankCardNo"
					type="digit"
					placeholder="输入信用卡号或借记卡号（信用卡可提升成功率）"
					clearable />
			</VanValidFormItem>
			<div class="bottom-tip">
				* 银行卡添加成功后，会向持卡人发送确认授权短信
			</div>

			<div class="button">
				<van-button
					round
					block
					type="black"
					:loading="submitLoading"
					:disabled="!disabledBtn"
					@click="handleSubmit">
					下一步
				</van-button>
			</div>
			<div class="confirm-checkbox">
				<van-checkbox v-model="checked">
					<span>
						本人声明，我与持卡人是近亲属关系【父母与子女，(外)祖父母与(外)孙子或孙女，兄弟姐妹或配偶】
					</span>
					<template #icon="props">
						<van-image
							width="14px"
							height="14px"
							fit="cover"
							:src="
								props.checked
									? require('@/assets/image/common/checked-active.png')
									: require('@/assets/image/common/checked.png')
							" />
					</template>
				</van-checkbox>
			</div>
		</VanValidForm>
	</div>
</template>

<script>
import { checkCardHolder, bankCardInfo } from '@/views/card/api/index.js'
import { mapMutations, mapState } from 'vuex'
export default {
	name: 'CardAddOtherPersonCardStep1Cache',
	data() {
		return {
			checked: false,
			submitLoading: false,
			formData: {
				cardHolderSurName: '',
				cardHolderGavenName: '',
				idNo: '',
				bankCardNo: ''
			}
		}
	},
	computed: {
		...mapState('card', ['otherCardInfo', 'otherChecked']),

		disabledBtn() {
			const { cardHolderSurName, cardHolderGavenName, idNo, bankCardNo } =
				this.formData
			return (
				cardHolderSurName &&
				cardHolderGavenName &&
				idNo &&
				bankCardNo &&
				this.checked
			)
		}
	},
	activated() {
		this.formData = this.otherCardInfo
	},
	methods: {
		...mapMutations('card', ['SET_ALL']),
		addDot(type) {
			this.formData[type] = (this.formData[type] || '') + '·'
		},
		handleSubmit() {
			const { bankCardNo, cardHolderSurName, cardHolderGavenName, idNo } =
				this.formData
			this.submitLoading = true
			// 校验接口
			checkCardHolder({
				cardHolderSurName,
				cardHolderGavenName,
				idNo
			})
				.then(res => {
					bankCardInfo({
						bankCardNo
					})
						.then(res => {
							this.SET_ALL({
								otherCardInfo: {
									...res,
									...this.formData
								}
							})
							this.$router.push({
								name: 'CardAddOtherPersonCardStep2'
							})
						})
						.catch(console.log)
						.finally(() => {
							this.submitLoading = false
						})
				})
				.catch(() => {
					this.submitLoading = false
				})
		}
	}
}
</script>

<style lang="less" scoped>
.title {
	margin-bottom: 20px;
}
.button {
	margin-top: 30px;
}
.tip-container {
	position: relative;
	display: flex;
	.tip-title-container {
		position: absolute;
		top: -60%;
		right: 0;
		font-size: 12px;
		line-height: 12px;
		color: var(--red-01);
	}
	.btn-dotted {
		margin-left: 10px;
		width: 40px;
		height: 40px;
		background-color: var(--main-08);
		.btn-icon {
			width: 14px;
			height: 14px;
		}
	}
}
.confirm-checkbox {
	margin-top: 20px;
}
.bottom-tip {
	font-size: 14px;
	color: var(--main-04);
	line-height: 14px;
}
</style>
