var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-item",class:[
		{ person: _vm.info.cardHolder == 'REGISTRANT' },
		{
			'other-authorized':
				_vm.info.cardHolder == 'AGENT_PAYOR' &&
				_vm.info.authorizedStatus == 'AUTHORIZED'
		},
		{
			'other-unauthorized':
				_vm.info.cardHolder == 'AGENT_PAYOR' &&
				_vm.info.authorizedStatus == 'TO_BE_AUTHORIZED'
		}
	],on:{"click":function($event){return _vm.$emit('click', _vm.info)}}},[_c('div',{staticClass:"card-person"},[_c('div',{staticClass:"card-person-name"},[_vm._v(" "+_vm._s(_vm.info.cardHolderSurName)+_vm._s(_vm.info.cardHolderGavenName)+" "),_c('span',{staticClass:"type-name"},[_vm._v(" ("+_vm._s(_vm.info.cardHolder == 'REGISTRANT' ? '本人' : '代缴人')+") ")]),(_vm.info.cardHolder == 'AGENT_PAYOR')?_c('span',{staticClass:"card-person-type",class:[
					{ succss: _vm.info.authorizedStatus == 'AUTHORIZED' },
					{ warn: _vm.info.authorizedStatus == 'TO_BE_AUTHORIZED' }
				]},[_vm._v(" "+_vm._s(_vm.info.authorizedStatus === 'AUTHORIZED' ? '已授权' : '未授权')+" ")]):_vm._e()])]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(_vm.info.bankName)+" | "+_vm._s(_vm.info.cardTypeCn))]),_c('div',{staticClass:"card-num"},[_c('span',{staticClass:"num"},[_vm._v(_vm._s(_vm.info.bankCardNo))])]),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }