<template>
	<div id="app">
		<NavBar />
		<keep-alive :include="/Cache/">
			<router-view ref="routerView" />
		</keep-alive>
		<van-overlay
			v-if="showWeiXin"
			:show="showWeiXin"
			z-index="999">
			<div class="wx">
				<van-image
					class="wx-info"
					:src="require('@/assets/image/common/wx-info.png')" />
				<van-image
					class="wx-info3"
					:src="require('@/assets/image/common/wx-info3.png')" />
			</div>
		</van-overlay>
		<van-overlay
			v-if="showBrowser"
			:show="showBrowser"
			z-index="999">
			<div class="showPC">
				<div class="showPC-content">
					<van-image
						class="pc-info"
						:src="require('@/assets/image/common/pc-info.png')" />
					暂不支持当前浏览器
				</div>
				<div class="showPC-btn">
					<van-button
						round
						type="black"
						@click="handleCopy">
						复制链接
					</van-button>
				</div>
			</div>
		</van-overlay>
	</div>
</template>
<script>
import {
	isWeixinBrowser,
	isOpera,
	isBaiduApp,
	isQuarkBrowser,
	isFirefox
} from '@/utils/utils'

export default {
	data() {
		return {
			showWeiXin: false,
			showBrowser: false
		}
	},
	mounted() {
		// 页面刷新时 不停留在当前位置 返回页面顶部。该方式主要是为了使得getBoundingClientRect方法获取的距离是正确的初始化数值。
		if (history.scrollRestoration) {
			history.scrollRestoration = 'manual'
		}
		if (isOpera() || isBaiduApp() || isQuarkBrowser() || isFirefox()) {
			this.showBrowser = true
			return
		}
		if (isWeixinBrowser()) {
			// this.showWeiXin = true
			return
		}
		// 添加清除页面缓存方法,下面为如何调用，数组为删除多个页面缓存
		// 传入的key值为路由的name
		// this.$EventBus.$emit('removeCachePage') // 删除所有
		// this.$EventBus.$emit('removeCachePage', ['Home']) // 删除多个
		// this.$EventBus.$emit('removeCachePage', 'Home') // 删除一个
		this.$EventBus.$on('removeCachePage', key => {
			this.$nextTick(() => {
				const { cache, keys } =
					this.$refs.routerView.$vnode.parent.componentInstance
				const arrKey = key ? (typeof key === 'string' ? [key] : key) : [...keys]
				if (arrKey.length) {
					arrKey.forEach(item => {
						if (Object.prototype.hasOwnProperty.call(cache, item)) {
							delete cache[item]
							if (keys.includes(item)) {
								keys.splice(keys.indexOf(item), 1)
							}
						}
					})
				}
			})
		})
	},
	beforeDestroy() {
		this.$EventBus.$off('removeCachePage')
	},
	methods: {
		handleCopy() {
			this.$copyText(window.location.href).then(() => {
				this.$toast('已复制链接')
			})
		}
	}
}
</script>

<style lang="less">
html,
body {
	width: 100%;
	// 去除移动端点击元素时产生的阴影效果
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-tap-highlight-color: transparent;
	scroll-behavior: smooth;
	background-color: var(--black-5);
	color: var(--main-01);
	overscroll-behavior: none;
}
#app {
	position: relative;
	font-size: 14px;
	font-family: PingFang SC;
	font-weight: 400;
	max-width: var(--max-layout-width);
	margin: 0 auto;
}
.wx {
	position: relative;
	height: 100%;
	.wx-info {
		position: absolute;
		top: 10px;
		right: 30px;
		width: 145px;
		height: 98px;
	}
	.wx-info3 {
		position: absolute;
		left: 0;
		bottom: 0;
		&:after {
			content: '';
			position: absolute;
			right: 16px;
			top: -70px;
			width: 131px;
			height: 72px;
			background: url('~@/assets/image/common/wx-info2.png') no-repeat center/
				cover;
		}
	}
}
.showPC {
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%;
	height: 100%;
	background-color: #fff;
	margin: 0 auto;
	padding: 120px 26px 0;
	.showPC-content {
		text-align: center;
		width: 325px;
		height: 148px;
		font-size: 14px;
		padding-top: 33px;
		margin-bottom: 40px;
		border-radius: 16px;
		color: var(--black-60);
		background-color: var(--black-5);
		.pc-info {
			display: block;
			width: 48px;
			height: 48px;
			margin: 0 auto 20px;
		}
	}
	.showPC-btn {
		width: 145px;
	}
}
</style>
