export default [
	{
		name: 'Setting',
		path: '/setting',
		redirect: '/setting/menu',
		meta: {
			title: '设置'
		},
		component: () => import('@/views/setting/Index'),
		children: [
			{
				name: 'SettingMenu',
				path: 'menu',
				meta: {
					title: '设置',
					navBarBg: 'grey'
				},
				component: () => import('@/views/setting/Menu')
			},
			{
				name: 'SettingUser',
				path: 'user',
				meta: {
					title: '用户信息管理',
					navBarBg: 'grey'
				},
				component: () => import('@/views/setting/user/Index')
			},
			{
				name: 'SettingTradePassword',
				path: 'tradePassword',
				meta: {
					title: '修改支付密码'
				},
				component: () => import('@/views/setting/tradePassword/Index')
			},
			{
				name: 'SettingPassword',
				path: 'password',
				meta: {
					title: '修改登录密码'
				},
				component: () => import('@/views/setting/password/Index')
			}
		]
	}
]
