import api from '@/utils/http/axios'
import { BASE_URL } from '@/utils/http/base-url'

/**
 * @desc 计算费用
 * @param {string} amount	缴费金额
 * @param {string} currency	缴费币种
 */
export const calculateCosts = (params = {}, config = {}) => {
	return api.post(BASE_URL + '/app/paymentRent/calculateCosts', params, {
		...config
	})
}

/**
 * @desc 取消账单
 * @param {string} bizOrder	账单号
 * @param {string} sign	签名
 * @param {string} charPwd	登录密码
 */
export const cancelPaymentSubmit = (params = {}, config = {}) => {
	return api.post(BASE_URL + '/app/paymentRent/cancelPaymentSubmit', params, {
		md5Keys: 'charPwd',
		signKeys: ['bizOrder', 'code', 'charPwd'],
		...config
	})
}

/**
 * @desc 下载缴费凭证
 */
export const downloadPaymentOrder = (params = {}, config = {}) => {
	return api.post(
		BASE_URL + '/app/paymentRent/downloadPaymentStatement',
		params,
		{
			...config,
			responseType: 'blob'
		}
	)
}

/**
 * @desc 付款卡选择
 */
export const getCardPayList = (params = {}, config = {}) => {
	return api.post(BASE_URL + '/app/paymentRent/getCardPayList', params, {
		...config
	})
}

/**
 * @desc 房东选择
 */
export const getLandlordList = (params = {}, config = {}) => {
	return api.post(BASE_URL + '/app/paymentRent/getLandlordList', params, {
		...config
	})
}

/**
 * @desc 缴费业务单支付凭证查询
 * @param {string} bizOrder	账单号
 */
export const getPaymentStatement = (params = {}, config = {}) => {
	return api.post(BASE_URL + '/app/paymentRent/getPaymentStatement', params, {
		...config
	})
}

/**
 * @desc 缴费业务单详情查询
 * @param {string} bizOrder	账单号
 */
export const getRentTradeOrderDetail = (params = {}, config = {}) => {
	return api.post(
		BASE_URL + '/app/paymentRent/getRentTradeOrderDetail',
		params,
		{
			...config
		}
	)
}

/**
 * @desc 缴费业务单列表查询
 * @param {string} cstNo	客户号
 * @param {string} status	状态
 * @param {string} pageNo
 * @param {string} pageSize
 */
export const getBillsList = (params = {}, config = {}) => {
	return api.post(BASE_URL + '/app/paymentRent/pageMartTradeOrder', params, {
		...config
	})
}
/**
 * @desc 发起缴费
 * @param {string} amount	收款人金额
 * @param {string} cardInfoNo	卡编号（系统流水号）
 * @param {string} currency	币种
 * @param {string} feeAmount	费用
 * @param {string} landlordNo	房东编号
 * @param {string} paymentItem	支付项目
 * @param {string} payoutMethod	汇出方式（电汇、支票）
 * @param {string} sign	签名
 * @param {string} totalAmount	总金额
 * @param {string} charPwd	登录密码
 */
export const rentPaymentSubmit = (params = {}, config = {}) => {
	return api.post(BASE_URL + '/app/paymentRent/rentPaymentSubmit', params, {
		md5Keys: 'charPwd',
		signKeys: [
			'amount',
			'cardInfoNo',
			'currency',
			'feeAmount',
			'landlordNo',
			'paymentItem',
			'payoutMethod',
			'totalAmount',
			'charPwd'
		],

		...config
	})
}

/**
 * @desc 修改房东再次汇出
 * @param {string} bizOrder	缴费单号编号
 * @param {string} landlordNo	房东编号
 * @param {string} sign	签名
 * @param {string} charPwd	登录密码
 */
export const rentRemitAgain = (params = {}, config = {}) => {
	return api.post(BASE_URL + '/app/paymentRent/rentRemitAgain', params, {
		md5Keys: 'charPwd',
		signKeys: ['bizOrder', 'landlordNo', 'code', 'charPwd'],
		...config
	})
}

/**
 * @desc 限额查询:不传金额查范围，传金额校验金额限额结果
 * @param {string} amount
 */
export const limitInquiry = (params = {}, config = {}) => {
	return api.post(BASE_URL + '/app/paymentRent/limitInquiry', params, {
		...config
	})
}
/**
 * @desc 限制信息
 */
export const showLimitAndDesc = (params = {}, config = {}) => {
	return api.post(BASE_URL + '/app/paymentRent/showLimitAndDesc', params, {
		...config
	})
}

/**
 * @desc 预估向房东结算日期
 */
export const queryRentPageInfo = (params = {}, config = {}) => {
	return api.post(BASE_URL + '/app/paymentRent/queryRentPageInfo', params, {
		...config
	})
}

/**
 * @desc 查询当前汇率
 */
export const queryCurrentExchangeRate = (params = {}, config = {}) => {
	return api.post(BASE_URL + '/app/queryCurrentExchangeRate', params, {
		...config
	})
}

/**
 * @desc 缴房租：付款之后查询支付结果
 * @param {string} bizOrder
 */
export const queryDeductResult = (params = {}, config = {}) => {
	return api.post(BASE_URL + '/app/rent-payment/queryDeductResult', params, {
		...config
	})
}
