<template>
	<div class="country-input-container">
		<FieldInput
			v-bind="$attrs"
			:value="phone"
			class="input"
			@input="setPhone"
			@blur="blurPhone" />
		<div
			class="conuntry-code-container"
			@click="handleCountryShow">
			<span class="code">+{{ countryCode }}</span>
			<div>
				<SvgIcon
					name="arrow-down"
					class="icon-arrow"
					:class="{ down: showCountryList }" />
			</div>
		</div>
		<transition name="fade">
			<div
				v-show="showCountryList"
				v-clickoutside="handleHideCountry"
				class="country-list-container">
				<div
					v-if="showSearchInput"
					class="country-search-container">
					<div class="country-search-input-container">
						<input
							v-model="searchPhoneCodeValue"
							class="country-search-input"
							type="text"
							maxlength="50"
							:placeholder="'搜索国别码'"
							@input="getPhoneCodeSearchResult" />
					</div>
				</div>
				<ul class="country-list">
					<li
						v-for="item in phoneCountries"
						:key="item.zhShortName"
						:class="{ active: item.areaCode === countryCode }"
						@click="handleClickCountry(item)">
						<span class="li-left">
							<span>{{ item.zhShortName }} +{{ item.areaCode }}</span>
						</span>
						<!-- <span class="li-right">+{{ item.zhFullName }}</span> -->
					</li>
				</ul>
			</div>
		</transition>
	</div>
</template>

<script>
import { debounce } from 'lodash-es'
import { mapActions } from 'vuex'
// import commonApi from '@/api/common'
export default {
	props: {
		countryCode: {
			type: String,
			default: '86'
		},
		phone: {
			type: String,
			default: ''
		},
		showSearchInput: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			loading: false,
			allCountries: [],
			phoneCountries: [],
			searchPhoneCodeValue: '',
			showCountryList: false
		}
	},
	created() {
		this.getCountryList()
	},
	methods: {
		...mapActions({
			fetchCountryList: 'enums/getCountryList' // 映射action为组件方法
		}),
		getPhoneCodeSearchResult: debounce(function () {
			this.phoneCountries = this.allCountries.filter(item => {
				if (/^\d+$/.test(this.searchPhoneCodeValue)) {
					return (
						item.areaCode &&
						item.areaCode.indexOf(this.searchPhoneCodeValue) > -1
					)
				}
				return (
					item.zhShortName &&
					item.zhShortName.indexOf(this.searchPhoneCodeValue) > -1
				)
			})
		}, 500),
		getCountryList() {
			this.fetchCountryList().then(res => {
				this.allCountries = res
				this.phoneCountries = this.allCountries
			})
		},
		setPhone(val) {
			this.$emit('update:phone', val)
		},
		blurPhone(val) {
			this.$emit('blurPhone', val)
		},
		handleCountryShow() {
			if (this.showCountryList) return
			this.showCountryList = true
		},
		handleHideCountry() {
			this.showCountryList = false
			this.searchPhoneCodeValue = ''
			this.phoneCountries = this.allCountries
		},
		handleClickCountry(item) {
			this.$emit('update:countryCode', item.areaCode)
			this.$emit('changCountryCode', item.areaCode)
			this.handleHideCountry()
		}
	}
}
</script>
<style lang="less" scoped>
.country-input-container {
	position: relative;
	.input {
		padding-left: 80px;
	}
	.conuntry-code-container {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding: 0 0 0 16px;
		.code {
			min-width: 40px;
		}
		.icon-arrow {
			font-size: 18px;
			transition: all 0.3s ease-out;
			&.down {
				transform: rotate(-180deg);
			}
		}
	}
	.country-list-container {
		width: 100%;
		max-width: 80%;
		position: absolute;
		top: 100%;
		left: 0px;
		padding-top: 10px;
		z-index: 100;
		box-shadow: 0px 6px 24px 0px rgba(38, 44, 71, 0.16);
		border-radius: 5px;
		background: var(--white);

		// &:after {
		// 	content: '';
		// 	position: absolute;
		// 	left: 54px;
		// 	top: -5px;
		// 	width: 7px;
		// 	height: 7px;
		// 	border-top: 1px solid var(--main-08);
		// 	border-right: 1px solid var(--main-08);
		// 	border-bottom: 1px solid transparent;
		// 	border-left: 1px solid transparent;
		// 	background: #fff;
		// }

		.country-search-container {
			padding: 0 20px;
			width: 100%;
			background: var(--white);
			border-bottom: 1px solid var(--main-08);
			z-index: 1;

			.country-search-input-container {
				position: relative;

				&:after {
					content: '';
					position: absolute;
					right: 12px;
					top: 50%;
					width: 22px;
					height: 22px;
					// background-image: url('~@/views/adverts/img/icon-search.png');
					background-size: 100% 100%;
					transform: translateY(-50%);
				}
			}
		}

		.country-list {
			position: relative;
			width: 100%;
			min-height: 10px;
			max-height: 220px;
			overflow: scroll;
			cursor: pointer;

			li {
				position: relative;
				padding: 0 20px 0 20px;
				height: 40px;
				line-height: 40px;
				color: #000000;
				font-size: 14px;

				.li-left {
					float: left;
				}

				.li-right {
					float: right;
					color: #787993;
				}

				&.active {
					background: #ecf2ff;
					color: var(--blue-01);
				}

				&:hover {
					background: var(--main-08);
				}
			}
		}
	}
}
</style>
