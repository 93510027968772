<template>
	<div class="add-typeC-checked-container">
		<ul class="check-list">
			<li
				v-for="item in list"
				:key="item.title"
				class="check-item"
				@click="handleAddBankCard(item)">
				<div>
					<!-- <img
						class="img"
						:src="item.url" /> -->
					<SvgIcon
						:name="item.name"
						class="img" />
					<div class="title">
						{{ item.title }}
					</div>
					<div
						v-if="item.desc"
						class="desc">
						{{ item.desc }}
					</div>
				</div>
			</li>
		</ul>
		<div class="tip">
			提示：目前已支持绑定中国银联信用卡和中国银联借记卡，推荐您使用中国银联信用卡，成功率更高。
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			addValide: false,
			list: [
				{
					id: 1,
					title: '本人银行卡',
					url: require('@/assets/image/card/credit.png'),
					name: 'credit-card'
				},
				{
					id: 2,
					title: '亲属银行卡',
					desc: '需要持卡人确认授权',
					url: require('@/assets/image/card/devices.png'),
					name: 'wallet'
				}
			]
		}
	},
	methods: {
		handleAddBankCard(item) {
			let name = 'CardAddSelfCard'
			if (item.id == 2) {
				name = 'CardAddOtherPersonCard'
			}
			this.$router.replace({
				name
			})
		}
	}
}
</script>
<style lang="less" scoped>
.add-typeC-checked-container {
	padding: 16px 24px;
	min-height: calc(100vh - var(--navbar-height));
	background: var(--white);

	.check-list {
		height: 140px;
		display: flex;
		justify-content: center;
		align-items: center;
		.check-item {
			height: 100%;
			flex: 1;
			padding: 18px;
			background-color: var(--main-08);
			text-align: center;
			border-radius: 16px;
			cursor: pointer;
			& + .check-item {
				margin-left: 17px;
			}
			.img {
				display: block;
				width: 54px;
				margin: 0 auto;
				font-size: 54px;
			}
			.title {
				margin-top: 12px;
				font-size: 14px;
				color: #0d0d26;
				line-height: 14px;
			}
			.desc {
				margin-top: 10px;
				font-size: 12px;
				color: var(--main-04);
				line-height: 12px;
			}
		}
	}
	.tip {
		margin-top: 20px;
		font-size: 12px;
		color: var(--main-04);
		line-height: 20px;
	}
}
</style>
