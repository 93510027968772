import operationApi from '../api/operation'

export default {
	data() {
		return {
			// 存在表示编辑 否则表示新增
			landlordNo: ''
		}
	},
	created() {
		const { landlordNo } = this.$route.query
		this.landlordNo = landlordNo
	},
	methods: {
		getLanglordDetail() {
			const { landlordNo } = this
			if (!landlordNo) return
			return operationApi
				.getLandlordDetail({
					landlordNo
				})
				.then(res => {
					if (res) {
						for (const key in this.formData) {
							if (key in res && res[key]) {
								this.formData[key] = res[key]
							}
						}
						if (this.info) {
							this.info = res
						}
					}
				})
				.catch(console.error)
		}
	}
}
