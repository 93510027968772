<template>
	<div class="login">
		<div class="login-content">
			<div class="login-input">
				<InputPassword
					v-model="formData.password"
					size="large"
					:formatter="passwordFormatter"
					maxlength="6"
					placeholder="请输入6位数字的新修改的支付密码" />
			</div>
			<div class="login-input">
				<InputPassword
					v-model="formData.password1"
					size="large"
					:formatter="passwordFormatter"
					maxlength="6"
					placeholder="请再次输入新修改的支付密码" />
			</div>
			<div class="login-tips">
				<p>温馨提示：</p>
				<p>
					为保障交易安全，请妥善保管您的支付密码；安全的支付密码建议不要包含生日、手机号或连续字母数字等信息。
				</p>
			</div>
			<div class="login-btn">
				<van-button
					round
					:disabled="!isLoginBtn"
					type="red"
					@click="nextStep">
					确 定
				</van-button>
			</div>
		</div>
	</div>
</template>
<script>
import { setTradePwd } from 'views/login/api'
import { mapMutations } from 'vuex'

export default {
	data() {
		return {
			formData: {
				password: '',
				password1: ''
			}
		}
	},
	computed: {
		isLoginBtn() {
			const { password, password1 } = this.formData
			return password && password1
		},
		isPasswordLength() {
			return (
				this.formData.password.length >= 6 &&
				this.formData.password1.length >= 6
			)
		}
	},
	methods: {
		...mapMutations('app', ['SET_TO_LOGIN_NEXT']),
		passwordFormatter(value) {
			return value.replace(/[^0-9]/g, '')
		},
		nextStep() {
			if (!this.isPasswordLength) {
				this.$toast('密码长度至少为6位')
				return
			}
			const { password, password1 } = this.formData
			if (password !== password1) {
				this.$toast('两次密码输入不一致')
				return
			}
			const loading = this.$toast.loading()
			setTradePwd({
				password
			})
				.then(res => {
					this.SET_TO_LOGIN_NEXT('pass')
					this.$router.replace({
						name: 'CardList'
					})
				})
				.catch(err => {
					console.log(err)
				})
				.finally(() => {
					loading.clear()
				})
		}
	}
}
</script>
<style scoped lang="less">
.login {
	min-height: 100vh;
	background-color: #fff;
	padding-top: 45px;
	.login-content {
		padding: 24px;
		.login-title {
			padding: 34px 0;
			text-align: center;
			font-size: 24px;
		}
		.login-input {
			margin-top: 24px;
		}
		.login-btn {
			padding-top: 40px;
		}
	}
	.login-tips {
		padding-top: 24px;
		color: var(--main-02);
		font-size: 12px;
		line-height: 20px;
	}
}
</style>
