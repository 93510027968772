import faceApi from '@/api/face'
import { isPC } from '@/utils/utils'

const source = isPC() ? 'PC' : 'H5'
// 视频认证 分为登录态和非登录态
const types = ['LOGIN', 'NOT_LOGIN']

const $face = {
	/**
	 * faceToken必要参数
	 * @param {*} returnUrl 提交给后端的回调地址
	 */
	start(type = 'NOT_LOGIN', params = {}) {
		if (types.includes(type)) {
			// const api = type === 'LOGIN' ? faceApi.getFaceToken : faceApi.getFaceTokenByPass
			// 目前只有非登录的人脸接口
			const api = faceApi.getFaceTokenByPass
			return api({
				source,
				...params
			})
				.then(res => {
					if (res) {
						const { faceLiteDoUrl, status } = res
						// 直接返回结果，不需要跳转页面
            // 已解绑
						if (status === 'UNBIND') {
							return res
						} else {
							window.location.href = faceLiteDoUrl
						}
					}
				})
				.catch(console.error)
		}
	},
	getResult(type = 'NOT_LOGIN', params = {}) {
		if (typeof type !== 'string') {
			params = type
			type = 'NOT_LOGIN'
		}
		if (types.includes(type)) {
			// const api = type === 'LOGIN' ? faceApi.getFaceResult : faceApi.getFaceResultByPass
			// 目前只有非登录的人脸接口
			const api = faceApi.getFaceResultByPass
			return api({
				...params
			})
				.then(res => {
					return res
				})
				.catch(console.error)
		}
	}
}

export default {
	install(Vue, options) {
		Vue.prototype.$face = $face
	}
}
