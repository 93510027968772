<template>
	<div class="number-group-input-container">
		<ul
			v-if="isPC()"
			class="flex-form">
			<li
				v-for="(item, index) in originalCertList"
				:key="index"
				class="flex-form-item"
				@keyup="handleKey">
				<van-field
					:ref="`numInput${index}`"
					v-model="item.label"
					:type="inputType"
					:inputmode="inputmode"
					:disabled="activeIndex < index"
					:maxlength="1"
					@focus="
						val =>
							handleFocus(val, {
								item,
								index,
								length: originalCertList.length
							})
					"
					@blur="
						val =>
							handleBlur(val, {
								item,
								index,
								length: originalCertList.length
							})
					"
					@input="
						val =>
							handleInput(val, {
								item,
								index,
								length: originalCertList.length
							})
					" />
			</li>
		</ul>

		<template v-else>
			<!-- 密码输入框 -->
			<van-password-input
				:value="inputValue"
				:focused="showKeyboard"
				:length="maxLength"
				gutter="14"
				@focus="handleInputFocus" />
			<!-- 数字键盘 -->
			<van-number-keyboard
				v-model="inputValue"
				:show="showKeyboard"
				:extra-key="extraKey"
				@blur="showKeyboard = false" />
		</template>
	</div>
</template>
<script>
import { isPC } from '@/utils/utils'

export default {
	props: {
		inputType: {
			type: String,
			default: 'digit'
		},
		inputmode: {
			type: String,
			default: 'numeric'
		},
		extraKey: {
			type: String,
			default: ''
		},
		maxLength: {
			type: Number,
			default: 6
		}
	},
	data() {
		return {
			inputValue: '',
			showKeyboard: true,

			activeIndex: 0,
			originalCertList: []
		}
	},
	watch: {
		inputValue(val) {
			if (val.length === this.$props.maxLength) {
				this.$emit('inputComplete', val)
			}
		},
		maxLength: {
			immediate: true,
			handler(val) {
				if (isPC()) {
					const list = []
					for (let i = 0; i < val; i++) {
						list.push({ label: '' })
					}
					this.originalCertList = list
				}
			}
		}
	},
	methods: {
		isPC,
		initValue() {
			this.inputValue = ''

			this.originalCertList = this.originalCertList.map(item => {
				return { label: '' }
			})
			this.activeIndex = 0
		},
		handleInputFocus() {
			this.showKeyboard = true
		},

		/**
		 *  pc端开始
		 */
		handleKey(event) {
			// 监听删除键
			if (event.keyCode === 8 || event.keyCode === 46) {
				if (this.activeIndex > 0) {
					this.activeIndex -= 1
				}
				const target = 'numInput' + this.activeIndex
				const targetRef = this.$refs[target][0]
				if (!targetRef.disabled) {
					targetRef.focus()
				}
				this.handleFocus('', {
					index: this.activeIndex,
					length: this.originalCertList.length
				})
			}
		},
		handleFocus(val, { ref, item, index, length }) {
			this.activeIndex = index
			// 聚焦清空,当前以及以后
			for (let i = index; i < length; i++) {
				const element = this.originalCertList[i]
				element.label = ''
			}
		},
		handleBlur(val, { ref, item, index, length }) {},
		handleInput(val, { ref, item, index, length }) {
			// 输入后 聚焦于下一输入框
			if (index < length - 1) {
				this.activeIndex += 1
				const target = 'numInput' + (index + 1)
				const targetRef = this.$refs[target][0]
				this.$nextTick(() => {
					targetRef.focus()
				})
			} else {
				const target = 'numInput' + index
				const targetRef = this.$refs[target][0]
				targetRef.blur()
				if (this.originalCertList.every(item => item.label != '')) {
					let str = ''
					this.originalCertList.forEach(item => {
						str += item.label
					})
					this.$emit('inputComplete', str)
				}
			}
		}
	}
}
</script>

<style scoped lang="less">
.number-group-input-container {
	.flex-form {
		display: flex;
		.flex-form-item {
			text-align: center;
			/deep/ .van-field {
				padding: 7px 6px;
				.van-field__value {
					border-bottom: 1px solid var(--main-01);
					padding: 10px 0 10px;
					.van-field__control {
						height: 36px;
						text-align: center;
						font-size: 36px;
						color: #333333;
						line-height: 18px;
					}
				}
			}
		}
	}
	/deep/ .van-password-input__item {
		background-color: transparent;
		border-bottom: 1px solid var(--main-01);
		font-size: 36px;

		.van-password-input__cursor {
			background-color: var(--red-01);
		}
	}
}
</style>
