<template>
	<div class="nav-bar">
		<div class="nav-bar-wrapper">
			<SvgIcon
				name="mine"
				class="icon"
				@click.native="handleSetting" />
			<div
				class="logo"
				@click="handleHome">
				<img
					src="@/assets/image/logo.png"
					alt="" />
			</div>
			<SvgIcon
				name="logout"
				class="icon"
				@click.native="handleLogout" />
		</div>
	</div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
	data() {
		return {}
	},
	methods: {
		...mapMutations('app', ['CLEAR_ALL_INFO']),
		handleSetting() {
			this.$router.push({
				name: 'Setting'
			})
		},
		handleHome() {
			this.$router.push({
				name: 'Home'
			})
		},
		handleLogout() {
			this.$dialog
				.confirm({
					title: '提示',
					message: '确认退出登录么？',
					beforeClose: (action, done) => {
						if (action === 'confirm') {
							// TODO: 退出登录
							setTimeout(() => {
								done()
								this.CLEAR_ALL_INFO()
								this.$router.push({
									name: 'Login'
								})
							}, 1000)
						} else {
							done()
						}
					}
				})
				.catch(_ => {})
		}
	}
}
</script>

<style scoped lang="less">
.nav-bar {
	padding-top: var(--navbar-height);
	.nav-bar-wrapper {
		position: fixed;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		max-width: var(--max-layout-width);
		padding: 16px 20px;
		font-size: 24px;
		background-color: var(--white);
		.icon {
			cursor: pointer;
		}
		.logo {
			width: 109px;
			height: 24px;
		}
	}
}
</style>
