<template>
	<div class="card-details-container">
		<CardItem :info="info" />
		<div class="btn-list">
			<van-button
				class="remove-btn"
				type="black"
				size="large"
				round
				@click="handleRemoveBankCard">
				解除绑定
			</van-button>
		</div>
		<PasswordDialog
			ref="dialog"
			@submit="handleUnBind" />
	</div>
</template>
<script>
import { bindCardCheckCharPwd } from '@/views/card/api/index.js'

import CardItem from './components/CardItem.vue'
import PasswordDialog from './components/PasswordDialog.vue'
import { unBindCards, getBankCardInfoDetail } from '@/views/card/api/index'
export default {
	components: { CardItem, PasswordDialog },
	data() {
		return {
			cardInfoNo: '',
			info: {}
		}
	},
	created() {
		this.cardInfoNo = this.$route.query.cardInfoNo
		this.getDetails()
	},
	methods: {
		getDetails() {
			const { cardInfoNo } = this

			getBankCardInfoDetail({
				cardInfoNo
			}).then(res => {
				this.info = res
			})
		},
		handleRemoveBankCard() {
			this.$dialog
				.confirm({
					title: '提示',
					message: '确认解除绑定？'
				})
				.then(_ => {
					this.$refs.dialog.showDialog()
				})
				.catch(_ => {})
		},
		handleUnBind(formData) {
			const loading = this.$toast.loading()
			const { cardInfoNo } = this
			const { charPwd } = formData
			bindCardCheckCharPwd({
				charPwd
			})
				.then(res => {
					unBindCards({
						cardInfoNo
					})
						.then(res => {
							this.$dialog
								.alert({
									title: '',
									message: '银行卡解绑成功',
									closeOnPopstate: false
								})
								.then(() => {
									this.$router.go(-1)
								})
						})
						.catch(console.log)
						.finally(() => {
							loading.clear()
						})
				})
				.catch(() => {
					loading.clear()
				})
		}
	}
}
</script>

<style lang="less" scoped>
.card-details-container {
	min-height: calc(100vh - var(--navbar-height));
	padding: 16px;
	background: var(--white);
	color: var(--main-01);
	.btn-list {
		margin: 30px 0 0;
		padding: 0 8px;
	}
}
</style>
