<template>
	<div class="kyc-base">
		<div class="kyc-base-title">联系信息</div>
		<div class="kyc-base-input">
			<div class="kyc-base-input-title">电子邮箱</div>
			<FieldInput
				v-model="kycModelOneReq.email"
				placeholder="请输入您的电子邮箱" />
		</div>
		<div class="kyc-base-title pt24">身份信息</div>
		<div class="kyc-base-content">
			<div class="kyc-base-input">
				<div class="kyc-base-input-title">中文姓氏</div>
				<FieldInput
					v-model="kycModelOneReq.surNameCn"
					placeholder="请输入姓"
					maxlength="30"
					:formatter="nameFormatter"
					@blur="surnameCnBlur" />
			</div>
			<div class="kyc-base-input ml11">
				<div class="kyc-base-input-title">中文名字</div>
				<FieldInput
					v-model="kycModelOneReq.gavenNameCn"
					placeholder="请输入名"
					maxlength="30"
					:formatter="nameFormatter"
					@blur="firstNameCnBlur" />
			</div>
		</div>
		<div class="info-btn">
			<span @click="editName">姓名中有特殊符号？</span>
		</div>
		<div class="kyc-base-content">
			<div class="kyc-base-input">
				<div class="kyc-base-input-title">英文姓氏</div>
				<FieldInput
					v-model="kycModelOneReq.surNameEn"
					placeholder="请输入姓"
					:formatter="letterFormatter"
					maxlength="40" />
			</div>
			<div class="kyc-base-input ml11">
				<div class="kyc-base-input-title">英文名字</div>
				<FieldInput
					v-model="kycModelOneReq.gavenNameEn"
					placeholder="请输入名"
					maxlength="40"
					:formatter="letterFormatter" />
			</div>
		</div>
		<div class="info-btn">
			<span class="info-btn-text">如中文为多音字，可点击修改</span>
		</div>
		<div class="kyc-base-input">
			<div class="kyc-base-input-title">身份证号</div>
			<FieldInput
				v-model="kycModelOneReq.idNo"
				maxlength="18"
				:formatter="cardNoFormatter"
				placeholder="请输入您的身份证号" />
		</div>
		<div class="kyc-base-checked">
			<van-checkbox
				v-model="kycModelOneReq.checked"
				:class="{ shaking: toShake && !kycModelOneReq.checked }">
				<span>
					我确保本人为账户资金的合法所有人，保证账户资金的来源、用途及货币兑换流程符合所属国家或地区的相关法律法规之要求。
				</span>
				<template #icon="props">
					<SvgIcon
						:name="props.checked ? 'checked' : 'unchecked'"
						class="icon" />
				</template>
			</van-checkbox>
			<van-checkbox
				v-model="kycModelOneReq.checked1"
				:class="{ shaking: toShake && !kycModelOneReq.checked1 }">
				<span>
					我确保每笔资金交易都遵守本人所在地国家外汇管理局的规定和政策。
				</span>
				<template #icon="props">
					<SvgIcon
						:name="props.checked ? 'checked' : 'unchecked'"
						class="icon" />
				</template>
			</van-checkbox>
		</div>
		<div class="kyc-base-btn">
			<van-button
				round
				type="red"
				:class="{
					'van-button--disabled': isDisabled
				}"
				@click="submitNext">
				下一步
			</van-button>
		</div>
	</div>
</template>

<script>
import Pinyin from 'pinyin'
import { certAuth } from 'views/kyc/api'
import { mapMutations } from 'vuex'
import { isEmail } from 'utils/utils'
import validate from 'utils/validate'

export default {
	name: 'KycBaseInfoCache',
	data() {
		return {
			kycModelOneReq: {
				surNameCn: '',
				surNameEn: '',
				gavenNameCn: '',
				gavenNameEn: '',
				idNo: '',
				email: '',
				checked: false,
				checked1: false
			},
			toShake: false
		}
	},
	computed: {
		isDisabled() {
			return !(
				this.kycModelOneReq.surNameCn &&
				this.kycModelOneReq.gavenNameCn &&
				this.kycModelOneReq.idNo.length === 18 &&
				this.kycModelOneReq.email &&
				this.kycModelOneReq.checked &&
				this.kycModelOneReq.checked1
			)
		}
	},
	methods: {
		...mapMutations('app', ['CHANGE_USER_INFO', 'SET_TO_LOGIN_NEXT']),
		surnameCnBlur() {
			this.kycModelOneReq.surNameEn = this.toPinyin(
				this.kycModelOneReq.surNameCn
			)
		},
		firstNameCnBlur() {
			this.kycModelOneReq.gavenNameEn = this.toPinyin(
				this.kycModelOneReq.gavenNameCn
			)
		},
		nameFormatter(value) {
			return value
				.replace(new RegExp(validate.noZhAndPoint, 'g'), '')
				.toUpperCase()
		},
		letterFormatter(value) {
			return value.replace(/[^a-zA-Z']+/g, '').toUpperCase()
		},
		cardNoFormatter(value) {
			return value.replace(/[^\dXx]/g, '').toUpperCase()
		},
		toPinyin(cnStr = '') {
			if (!cnStr) {
				return ''
			}
			//返回二维数组，第一维每个数组项位置对应每个中文字符串位置。
			//第二维是各个汉字的读音列表，多音字会有多个拼音项。
			//eg: [ [ 'zhōng' ], [ 'xīn' ] ]
			let result = Pinyin(cnStr, {
				heteronym: true, // 启用多音字模式
				style: Pinyin.STYLE_NORMAL
			})
			let pinyinName = this.joinPinyin(result)
			return pinyinName
		},
		joinPinyin(arr = []) {
			let result = ''
			arr.forEach(val => {
				result += val[0]
				if (val.length > 1) {
					this.heteronymSum++
				}
			})
			return result.toUpperCase()
		},
		submitNext() {
			if (this.isDisabled) {
				if (this.toShake) {
					return
				}
				this.toShake = true
				setTimeout(() => {
					this.toShake = false
				}, 1000)
				return
			}
			if (!isEmail(this.kycModelOneReq.email)) {
				return this.$toast('邮箱格式错误')
			}
			const loading = this.$toast.loading()
			certAuth({
				...this.kycModelOneReq,
				cstNameCn:
					this.kycModelOneReq.surNameCn + this.kycModelOneReq.gavenNameCn,
				cstNameEn:
					this.kycModelOneReq.gavenNameEn + this.kycModelOneReq.surNameEn
			})
				.then(res => {
					try {
						// Event snippet for 注册2-基本信息注册完成后 conversion page
						// eslint-disable-next-line no-undef
						gtag('event', 'conversion', {
							send_to: 'AW-16652125746/KnhuCI-Y28UZELKMrYQ-'
						})
					} catch (err) {
						console.error(err)
					}
					try {
						// eslint-disable-next-line no-undef
						fbq('track', 'CompleteRegistration')
					} catch (err) {
						console.error(err)
					}
					this.CHANGE_USER_INFO({
						...res
					})
					/**
					 * 不设置支付密码，直接跳转到添加银行卡
					 */
					// this.SET_TO_LOGIN_NEXT('LoginSetTransactionPassword')
					// this.$router.replace({
					// 	name: 'LoginSetTransactionPassword'
					// })
					this.SET_TO_LOGIN_NEXT('pass')
					this.$router.replace({
						name: 'CardList'
					})
				})
				.catch(err => {
					console.log(err)
				})
				.finally(() => {
					loading.clear()
				})
		},
		editName() {
			this.$EventBus.$off('EditUserNameOnFinished')
			this.$EventBus.$once('EditUserNameOnFinished', data => {
				this.$nextTick(() => {
					this.kycModelOneReq.gavenNameEn = data.gavenNameEn
					this.kycModelOneReq.surNameEn = data.surNameEn
					this.kycModelOneReq.gavenNameCn = data.gavenNameCn
					this.kycModelOneReq.surNameCn = data.surNameCn
				})
				console.log(this.kycModelOneReq, 'this.kycModelOneReq')
			})
			this.$router.push({
				name: 'KycBaseInfoEditName',
				query: {
					surNameCn: this.kycModelOneReq.surNameCn,
					gavenNameCn: this.kycModelOneReq.gavenNameCn,
					surNameEn: this.kycModelOneReq.surNameEn,
					gavenNameEn: this.kycModelOneReq.gavenNameEn
				}
			})
		}
	}
}
</script>

<style scoped lang="less">
.kyc-base {
	min-height: calc(100vh - var(--navbar-height));
	background-color: var(--white);
	padding: 14px 24px;
	font-size: 14px;
	.kyc-base-title {
		padding-bottom: 20px;
	}
	.kyc-base-content {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.kyc-base-input {
		.kyc-base-input-title {
			color: var(--main-03);
			padding-bottom: 12px;
		}
	}
	.kyc-base-checked {
		padding-top: 20px;
	}
	.kyc-base-btn {
		padding-top: 30px;
		.van-button--disabled {
			background-color: var(--red-03);
		}
	}
	.pt24 {
		padding-top: 24px;
	}
	.ml11 {
		margin-left: 11px;
	}
	.info-btn {
		font-size: 12px;
		color: var(--blue-01);
		span {
			display: inline-block;
			padding: 10px 0 16px 0;
		}
		.info-btn-text {
			color: var(--main-04);
		}
	}
	.icon {
		width: 14px;
		height: 14px;
	}

	.van-checkbox {
		display: flex;
		:deep(.van-checkbox__icon) {
			display: flex;
			align-items: center;
			top: 2px;
		}
	}
	.shaking {
		animation: shakeX 0.3s linear 3;
	}
	@keyframes shakeX {
		0% {
			transform: rotate(0);
		}

		20% {
			transform: rotate(-2deg);
		}

		60% {
			transform: rotate(0);
		}

		80% {
			transform: rotate(2deg);
		}

		100% {
			transform: rotate(0);
		}
	}
}
</style>
