<template>
	<ElSelect
		ref="select"
		v-bind="$attrs"
		v-on="$listeners">
		<slot />
	</ElSelect>
</template>

<script>
export default {
	data() {
		return {}
	},
	mounted() {
		// 移动端去除readonly属性 唤起键盘
		if (this.$attrs.filterable) {
			const { select } = this.$refs
			const input = select.$el.querySelector('input.el-input__inner')
			input.removeAttribute('readonly')
		}
	},
	methods: {}
}
</script>

<style lang="less">
// [解决移动端ios双次点击下拉框 才能触发选中的问题](https://juejin.cn/post/7288509436900360251)
.el-scrollbar .el-scrollbar__bar {
	opacity: 1 !important;
}
</style>
