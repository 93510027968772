import Vue from 'vue'
import {
	Button,
	Toast,
	NavBar,
	Tabbar,
	TabbarItem,
	Icon,
	Image as VanImage,
	Lazyload,
	Cell,
	CellGroup,
	Field,
	List,
	PullRefresh,
	Overlay,
	Calendar,
	Popover,
	Dialog,
	NumberKeyboard,
	PasswordInput,
	Collapse,
	CollapseItem,
	Loading,
	RadioGroup,
	Radio,
	CheckboxGroup,
	Checkbox,
	Form,
	Grid,
	GridItem,
	DatetimePicker,
	Popup,
	Cascader,
	Picker,
	Uploader,
	Empty,
	Circle,
	Step,
	Steps,
	ActionSheet
} from 'vant'
Vue.use(Button)
	.use(Toast)
	.use(NavBar)
	.use(Tabbar)
	.use(TabbarItem)
	.use(VanImage)
	.use(Lazyload)
	.use(Cell)
	.use(CellGroup)
	.use(Icon)
	.use(Field)
	.use(PullRefresh)
	.use(List)
	.use(Overlay)
	.use(Calendar)
	.use(Popover)
	.use(PasswordInput)
	.use(NumberKeyboard)
	.use(Collapse)
	.use(CollapseItem)
	.use(Loading)
	.use(Form)
	.use(CheckboxGroup)
	.use(Checkbox)
	.use(Radio)
	.use(RadioGroup)
	.use(Dialog)
	.use(Picker)
	.use(Empty)
	.use(Grid)
	.use(GridItem)
	.use(DatetimePicker)
	.use(Popup)
	.use(Cascader)
	.use(Uploader)
	.use(Circle)
	.use(Step)
	.use(Steps)
	.use(ActionSheet)

Toast.allowMultiple()
Toast.setDefaultOptions('loading', {
	message: '加载中...',
	forbidClick: true,
	duration: 0
})
