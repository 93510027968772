<template>
	<div class="payment-cancel-container">
		<Card
			type="content"
			:list="list"
			:info="detailsInfo">
			<DetailsHeaderInfo
				slot="header"
				type="cancenl"
				:info="detailsInfo" />
		</Card>

		<div
			v-if="step == 1"
			class="message-wrapper">
			<p class="message-tip">
				* 提示：退款交易最快将在
				{{ detailsInfo.expectedRefundDate }} 处理，资金将退回原支付银行卡中。
			</p>
		</div>

		<van-button
			type="black"
			round
			class="submit-btn"
			@click="handleNext">
			{{ step == 1 ? '取消账单' : '确认取消' }}
		</van-button>
		<PaymentSubmitDialog
			ref="dialog"
			:sms-type="smsType"
			@submit="handleSubmit" />
	</div>
</template>

<script>
import { mapMutations } from 'vuex'
import DetailsHeaderInfo from '@/views/bills/components/DetailsHeaderInfo.vue'
import PaymentSubmitDialog from './components/PaymentSubmitDialog'

import { getRentTradeOrderDetail, cancelPaymentSubmit } from '@/views/rent/api'
export default {
	components: { DetailsHeaderInfo, PaymentSubmitDialog },
	data() {
		return {
			bizOrder: '',
			detailsInfo: {},
			smsType: 'TRADE_VERIFY_CANCEL',
			step: 1
		}
	},
	computed: {
		list() {
			return [
				{
					list: [
						{ label: '汇出方式：', value: 'payeeReceiveMethodEn' },
						{ label: '房东：', value: 'payeeName' },
						{ label: '房东账号类型', value: 'landlorCardTypeName' },
						{ label: '房东账号', value: 'landlordBankNo' },
						{
							label: '汇出金额',
							value: ['payeeAmount', 'currency']
							// numberFormatName: 'payeeAmount'
						},
						{
							label: '费用：',
							value: ['serviceFee', 'currency']
							// numberFormatName: 'serviceFee'
						},
						{
							label: '扣款总金额：',
							value: ['payerAmount', 'currency']
							// numberFormatName: 'payerAmount'
						},
						{ label: '付款银行卡：', value: ['payerName', 'payerBankNo'] },
						{ label: '账单号：', value: 'bizOrder' },
						{ label: '创建时间：', value: 'createTimeStr' },
						{ label: '银行卡扣款时间：', value: 'creditSuccessTimeStr' },
						{ label: '退款金额', value: ['returnAmount', 'currency'] }
					]
				}
			]
		}
	},
	created() {
		const { bizOrder } = this.$route.query
		this.bizOrder = bizOrder
		if (bizOrder) {
			this.getDetails()
		}
	},
	methods: {
		...mapMutations('rent', ['SET_ALL']),

		getDetails() {
			const { bizOrder } = this
			getRentTradeOrderDetail({
				bizOrder
			}).then(res => {
				this.detailsInfo = { ...res, landlorCardTypeName: '银行账号' }
			})
		},
		handleNext() {
			if (this.step == 1) {
				this.step = 2
			} else {
				this.$refs.dialog.showDialog()
			}
		},
		handleSubmit(formData) {
			const data = {
				...this.$route.query,
				...formData
			}
			const loading = this.$toast.loading()
			cancelPaymentSubmit(data)
				.then(res => {
					const { cnName: message = '提交成功' } = res
					this.$dialog
						.alert({
							title: '提示',
							message
						})
						.then(_ => {
							this.$router.go(-1)
						})
				})
				.catch(console.error)
				.finally(_ => {
					loading.clear()
				})
		}
	}
}
</script>

<style scoped lang="less">
.payment-cancel-container {
	min-height: calc(100vh - var(--navbar-height));
	padding: 16px 24px 30px;
	background-color: var(--black-5);
	.cancel-list {
		margin-top: 14px;
	}
	.message-wrapper {
		padding-top: 24px;
		.message-tip {
			font-size: 12px;
			color: var(--main-04);
			line-height: 20px;
		}
	}
	.submit-btn {
		margin-top: 30px;
	}
}
</style>
