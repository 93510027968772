<template>
	<div class="timeout">
		<FieldInput
			class="timeout-input"
			:placeholder="placeholder"
			v-bind="$attrs"
			v-on="$listeners">
			<!--<template #extra>-->
			<!--	<div-->
			<!--		:class="{-->
			<!--			'btn-text': true-->
			<!--		}"-->
			<!--		@click="getSMS">-->
			<!--		<van-loading-->
			<!--			v-show="loadingBtn"-->
			<!--			size="14px"-->
			<!--			color="var(&#45;&#45;black-100)" />-->
			<!--		{{ btnText }}-->
			<!--	</div>-->
			<!--</template>-->
		</FieldInput>
		<van-button
			class="timeout-btn"
			:type="btnType"
			:loading="geeTestLoading || loadingBtn"
			:disabled="timeCount > 0"
			@click.stop.native="getSMS">
			{{ btnText }}
		</van-button>
	</div>
</template>
<script>
import commonApi from 'api/common'
import { bindCardSendSms } from '@/views/card/api/index.js'

import { mapState } from 'vuex'
import { checkPhone } from 'utils/utils'
export default {
	name: 'InputTimeout',
	props: {
		placeholder: {
			type: String,
			default: '请输入验证码'
		},
		phone: {
			type: String,
			default: ''
		},
		countryCode: {
			type: String,
			default: ''
		},
		// LOGIN_REGISTER_APP card
		smsType: {
			type: String,
			default: 'LOGIN_AND_REGISTER'
		},
		btnType: {
			type: String,
			default: 'red'
		},
		sendParams: {
			type: Object,
			default: () => ({})
		}
	},
	data() {
		return {
			showPassword: false,
			loadingBtn: false,
			geeTestLoading: false,
			btnText: '获取验证码',
			time: null,
			timeCount: 0,
			startTime: Date.now()
		}
	},
	computed: {
		...mapState('app', ['userInfo'])
	},
	watch: {
		timeCount: function (value) {
			this.$emit('change', value)
		}
	},
	beforeDestroy() {
		this.resetTimer()
		document.removeEventListener(
			'visibilitychange',
			this.handleVisibilityChange
		)
	},
	mounted() {
		this.geeTest = this.$geeTest.create()
		document.addEventListener('visibilitychange', this.handleVisibilityChange)
	},
	methods: {
		getSMS() {
			if (this.timeCount > 0) {
				return
			}
			if (this.phone.length === 0) {
				this.$toast('请输入手机号')
				return
			}
			if (!checkPhone(this.phone, this.countryCode)) {
				this.$toast('手机号输入错误')
				return
			}
			this.geeTestLoading = true
			this.geeTest
				.validate()
				.then(verifyData => {
					if (!verifyData) return
					this.loadingBtn = true
					let fn = null
					let data = {}
					const { smsType } = this.$props
					if (smsType === 'bindCard') {
						fn = bindCardSendSms
						data = {
							countryCode: this.countryCode,
							reservePhone: this.phone,
							...this.$props.sendParams
						}
					} else if (
						smsType === 'TRADE_VERIFY_SUBMIT' ||
						smsType === 'TRADE_VERIFY_REMIT_AGAIN' ||
						smsType === 'TRADE_VERIFY_CANCEL'
					) {
						fn = commonApi.sendTradeCode
						data = {
							bizType: this.smsType,
							phoneNum: this.phone,
							phoneCode: this.countryCode,
							...verifyData
						}
					} else {
						fn = commonApi.getSMSCode
						data = {
							bizType: this.smsType,
							phoneNum: this.phone,
							phoneCode: this.countryCode,
							...verifyData
						}
					}
					fn(data)
						.then(res => {
							this.setTimer()
							this.$emit('success', res)
						})
						.catch(err => {
							console.warn(err)
						})
						.finally(() => {
							this.loadingBtn = false
						})
				})
				.catch(err => {
					console.warn(err)
					this.$toast(err.msg)
				})
				.finally(() => {
					this.geeTestLoading = false
				})
		},
		// getVoiceSMS() {
		// 	this.geeTest
		// 		.validate()
		// 		.then(verifyData => {
		// 			if (!verifyData) return
		// 			this.$toast.loading({
		// 				message: '发送中...'
		// 			})
		// 			commonApi
		// 				.geeTestSendVoiceCode({
		// 					type: this.smsType,
		// 					mobileNo: this.phone,
		// 					countryCode: this.countryCode,
		// 					...verifyData
		// 				})
		// 				.then(res => {})
		// 				.catch(err => {
		// 					console.log(err)
		// 				})
		// 				.finally(() => {
		// 					this.$toast.clear()
		// 				})
		// 		})
		// 		.catch(err => {
		// 			console.warn(err)
		// 			this.$toast(err.msg)
		// 		})
		// },
		setTimer() {
			clearInterval(this.time)
			this.timeCount = 60
			this.startTime = Date.now()
			this.btnText = `${this.timeCount}S`
			this.time = setInterval(() => {
				this.changeTimeCount()
			}, 1000)
		},
		changeTimeCount() {
			const elapsedTime = Math.floor((Date.now() - this.startTime) / 1000) // 计算已经过的时间
			this.timeCount = 60 - elapsedTime // 调整倒计时的值
			if (this.timeCount <= 0) {
				clearInterval(this.time)
				this.btnText = '获取验证码'
			} else {
				this.btnText = `${this.timeCount}S`
			}
		},
		handleVisibilityChange() {
			if (!document.hidden && this.timeCount > 0) {
				this.changeTimeCount()
			}
		},
		resetTimer() {
			clearInterval(this.time)
			this.timeCount = 0
			this.btnText = '获取验证码'
		}
	}
}
</script>

<style scoped lang="less">
.timeout {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	.timeout-input {
		flex: 1;
	}
	.timeout-btn {
		width: 110px;
		font-size: 14px;
		margin-left: 12px;
	}
}
</style>
