<template>
	<div class="protocol">
		<iframe
			ref="iframe"
			class="pay-iframe"
			width="100%"
			:src="url"
			:height="iframeHeight"
			frameborder="0"
			scrolling="yes"
			@load="handleLoad" />
	</div>
</template>

<script>
export default {
	data() {
		return {
			loading: false,
			iframeHeight: window.innerHeight - 85 + 'px',
			url: ''
		}
	},
	created() {
		this.$toast.loading({
			message: '加载中...',
			forbidClick: true,
			duration: 0
		})
		const { url } = this.$route.query
		this.url = decodeURIComponent(url)
	},
	mounted() {
		this.$nextTick(() => {
			console.log(11111)
			this.handleResize()
		})
		window.addEventListener('resize', this.handleResize)
	},
	beforeDestroy() {
		this.$toast.clear()
		window.removeEventListener('resize', this.handleResize)
	},
	methods: {
		handleLoad() {
			console.log('load', this)
			this.$toast.clear()
		},
		handleResize() {
			const iframe = this.$refs.iframe
			console.log('iframe', iframe)
			this.iframeHeight = iframe.scrollHeight - 85 + 'px'
			if (iframe.attachEvent) {
				iframe.attachEvent('onload', () => {
					console.log('html', iframe.document.body.innerHTML)
					iframe.innerHTML = iframe.document.body.innerHTML
					iframe.height =
						iframe.contentWindow.document.documentElement.scrollHeight
					return
				})
			} else {
				iframe.onload = () => {
					console.log('body', iframe.contentWindow.document.body)
					console.log('inner', iframe.contentWindow.document.body.innerHTML)
					iframe.height =
						iframe.contentWindow.document.documentElement.scrollHeight
					iframe.innerHTML = iframe.contentWindow.document.body.innerHTML

					return
				}
			}
		}
	}
}
</script>

<style lang="less" scoped>
.protocol {
	position: relative;
	min-height: calc(100vh - var(--navbar-height));
	background-color: #fff;
}
.pay-iframe {
	position: absolute;
	bottom: 0;
	height: 100%;
	width: 100%;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}
</style>
