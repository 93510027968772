<template>
	<div class="not-found">
		<van-empty
			:image-size="160"
			:image="require('@/assets/image/error/not-found.png')"
			description="URL不存在" />
		<van-button
			type="black"
			size="large"
			round
			@click="goHome('Home')">
			返回首页
		</van-button>
	</div>
</template>

<script>
export default {
	methods: {
		goHome(name) {
			this.$router.push({
				name
			})
		}
	}
}
</script>
<style scoped lang="less">
.not-found {
	min-height: 100vh;
	background-color: #fff;
	padding: 0 24px;
}
</style>
