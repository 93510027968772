<template>
	<div class="payment-container">
		<PaymentForm
			ref="form"
			sms-type="TRADE_VERIFY_SUBMIT"
			@setInfo="setInfo">
			<van-button
				type="black"
				round
				class="submit-btn"
				@click="handleSubmit">
				确认账单
			</van-button>
		</PaymentForm>
	</div>
</template>

<script>
import { mapMutations } from 'vuex'
import PaymentForm from './components/PaymentForm'

export default {
	components: {
		PaymentForm
	},
	data() {
		return {}
	},
	methods: {
		...mapMutations('rent', ['SET_ALL']),
		setInfo(data) {
			this.SET_ALL(data)
		},
		handleSubmit() {
			this.$refs.form
				.submit()
				.then(({ rentFormInfo, otherInfo }) => {
					this.setInfo({
						rentFormInfo,
						otherInfo
					})
					this.$router.push({
						name: 'RentPaymentConfirm',
						query: {
							type: 'TRADE_VERIFY_SUBMIT'
						}
					})
				})
				.catch(console.error)
		}
	}
}
</script>

<style scoped lang="less">
.payment-container {
	padding-top: 16px;
	.submit-btn {
		margin-top: 20px;
	}
}
</style>
