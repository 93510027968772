<template>
	<div class="header-info">
		<div class="title">{{ info.paymentItemCn }}</div>
		<div class="amount-container">
			{{ info.payeeAmount }} {{ info.currency }}
		</div>
		<div class="status">
			<div
				class="text"
				:class="info.status">
				{{ info.statusCn }}
			</div>
		</div>
		<div
			v-if="
				info.status === 'CARD_PAYMENT_PROCESSING' ||
				info.status === 'WAITING_FOR_PAYOUT'
			"
			class="desc">
			预估结算日期：{{ info.expectedSettleDateStr }}
		</div>
		<div
			v-else-if="
				info.status === 'PAYOUT_PROCESSING' ||
				info.status === 'PAYOUT_SUCCESS' ||
				info.status === 'PAYOUT_FAILED'
			"
			class="desc">
			结算日期：{{ info.settleDate }}
		</div>
		<div
			v-else-if="info.status === 'WAITING_FOR_AUTHORIZATION'"
			class="desc">
			{{ waitingText }}
		</div>
	</div>
</template>
<script>
import dayjs from 'dayjs'
import { numberFormat } from '@/utils/utils.js'

export default {
	//  // 扣款
	//   CARD_PAYMENT_PROCESSING("CARD_PAYMENT_PROCESSING", "扣款处理中", "Card Payment Processing"),
	//   CARD_PAYMENT_FAILED("CARD_PAYMENT_FAILED", "扣款失败", "Card Payment Failure"),
	//   // 汇款
	//   WAITING_FOR_AUTHORIZATION("WAITING_FOR_AUTHORIZATION", "待授权", "Waiting for Authorization"),
	//   WAITING_FOR_PAYOUT("WAITING_FOR_PAYOUT", "待结算", "Settlement Pending"),
	//   PAYOUT_PROCESSING("PAYOUT_PROCESSING", "结算处理中", "Settlement Processing"),
	//   PAYOUT_SUCCESS("PAYOUT_SUCCESS", "结算成功", "Settlement Success"),
	//   PAYOUT_FAILED("PAYOUT_FAILED", "结算失败", "Settlement Failure"),
	//   CANCEL("CANCEL", "取消", "Cancel"),
	//   ;
	props: {
		type: {
			// cancel为取消订单，其他默认为空
			type: String,
			default: ''
		},
		info: {
			type: Object,
			default: () => ({})
		}
	},
	computed: {
		waitingText() {
			const { info, type } = this.$props
			const {
				currentDate,
				expectedSettleDate,
				expectedSettleDateStr,
				expectedRefundDate
			} = info
			return dayjs(currentDate).isBefore(dayjs(expectedSettleDate))
				? `预估结算日期：${
						type === 'cancel' ? expectedRefundDate : expectedSettleDateStr
				  }`
				: '授权后结算'
		}
	},
	methods: {
		numberFormat
	}
}
</script>
<style lang="less" scoped>
.header-info {
	text-align: center;
	margin-bottom: 24px;
	padding-bottom: 30px;
	border-bottom: 1px dashed var(--main-07);
	.title {
		font-size: 16px;
		color: var(--main-01);
		line-height: 20px;
		margin-bottom: 14px;
	}

	.amount-container {
		font-size: 24px;
		color: var(--main-01);
		line-height: 24px;
		font-weight: bold;
		margin-bottom: 14px;
	}
	.status {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 14px;
		.text {
			padding: 6px 8px;
			border-radius: 4px 4px 4px 4px;
			font-size: 12px;
			line-height: 12px;
			&.PAYOUT_SUCCESS {
				color: var(--green-01);
				background-color: var(--green-04);
			}
			&.CARD_PAYMENT_FAILED,
			&.PAYOUT_FAILED {
				color: var(--red-01);
				background-color: var(--red-04);
			}
			&.WAITING_FOR_AUTHORIZATION,
			&.WAITING_FOR_PAYOUT {
				color: var(--yellow-01);
				background-color: var(--yellow-04);
			}
		}
	}
	.desc {
		font-size: 12px;
		color: var(--main-01);
		line-height: 12px;
	}
}
</style>
