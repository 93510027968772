<template>
	<div class="step-container">
		<div class="title">请填写银行卡信息</div>
		<VanValidForm>
			<VanValidFormItem label="银行卡号">
				<FieldInput
					v-model="formData.bankCardNo"
					type="digit"
					disabled
					clearable />
			</VanValidFormItem>
			<VanValidFormItem label="所属银行">
				<FieldInput
					:value="`${formData.bankName} ${selfCardInfo.cardType}`"
					disabled
					clearable />
			</VanValidFormItem>
			<div v-if="selfCardInfo.cardType === '贷记卡'">
				<VanValidFormItem label="安全识别码">
					<div class="flex-wrapper">
						<FieldInput
							v-model="formData.securityIdentifyCode"
							placeholder="请输入"
							type="digit" />
						<div
							class="tip-img"
							@click="showMask = true">
							<img src="@/assets/image/card/img-cvn-small.png" />
						</div>
					</div>
				</VanValidFormItem>
				<VanValidFormItem label="有效期">
					<FieldInput
						v-model="formData.expirationDate"
						type="digit"
						placeholder="填写月年，如0826" />
				</VanValidFormItem>
			</div>
			<VanValidFormItem label="银行预留手机号">
				<CountryCodeInput
					:country-code.sync="formData.countryCode"
					:phone.sync="formData.reservePhone"
					type="digit"
					placeholder="请输入银行预留手机号"
					clearable />
			</VanValidFormItem>
			<VanValidFormItem label="短信验证码">
				<InputTimeout
					ref="inputTimeout"
					v-model="formData.smsCode"
					:phone="formData.reservePhone"
					:country-code="formData.countryCode"
					:border="false"
					btn-type="black"
					sms-type="bindCard"
					valid-type="REG5"
					maxlength="6"
					:send-params="smsSendParams"
					@success="sendSuccess" />
			</VanValidFormItem>
			<div class="confirm-checkbox">
				<van-checkbox v-model="checked">
					<span>我已阅读并同意</span>
					<span
						v-for="item in protocolList"
						:key="item.name"
						class="protocol-link"
						@click.stop="clickProtocol(item)">
						{{ item.name }}
					</span>
					<template #icon="props">
						<van-image
							width="14px"
							height="14px"
							fit="cover"
							:src="
								props.checked
									? require('@/assets/image/common/checked-active.png')
									: require('@/assets/image/common/checked.png')
							" />
					</template>
				</van-checkbox>
			</div>
			<div class="button">
				<van-button
					round
					block
					type="black"
					:disabled="!disabledBtn"
					:loading="submitLoading"
					@click="handleSubmit">
					确定
				</van-button>
			</div>
		</VanValidForm>
		<div
			v-if="showMask"
			class="mark-container">
			<div class="mark-wrapper">
				<div
					class="close-btn"
					@click="showMask = false">
					<SvgIcon name="close" />
				</div>
				<img src="@/assets/image/card/img-cvn-large.png" />
			</div>
		</div>
	</div>
</template>

<script>
import commonApi from '@/api/common'

import { mapMutations, mapState } from 'vuex'
import { addBankCardInfoRegistrant } from '@/views/card/api/index.js'
export default {
	name: 'CardAddSelfCardStep2Cache',
	data() {
		return {
			submitLoading: false,
			checked: false,
			showMask: false,
			// timeCount: 60,
			sendInfo: {},
			protocolList: [],
			formData: {
				bankCardNo: '',
				bankName: '',
				securityIdentifyCode: '',
				expirationDate: '',
				countryCode: '86',
				reservePhone: '',
				smsCode: ''
			}
		}
	},
	computed: {
		...mapState('card', ['selfCardInfo', 'selfChecked']),
		disabledBtn() {
			const {
				smsCode,
				reservePhone,
				securityIdentifyCode,
				expirationDate,
				orderId
			} = this.formData

			if (this.selfCardInfo.cardType === '贷记卡') {
				return (
					smsCode.length === 6 &&
					reservePhone &&
					orderId &&
					securityIdentifyCode &&
					expirationDate &&
					this.checked
				)
			}

			return smsCode.length === 6 && reservePhone && orderId && this.checked
		},
		smsSendParams() {
			const { bankCardNo } = this.formData
			return {
				bankCardNo,
				cardHolder: 'REGISTRANT'
			}
		}
	},
	created() {
		this.checked = this.selfChecked
		this.getProtocol()
	},
	activated() {
		const { cardNum, bankName } = this.selfCardInfo
		this.formData = {
			...this.formData,
			...this.selfCardInfo,
			bankCardNo: cardNum,
			bankName: bankName
		}
	},
	methods: {
		...mapMutations('card', ['SET_ALL']),
		getProtocol() {
			commonApi
				.getProtocol({
					bizType: 'BANK_CARD_PAYMENT_PROTOCOL'
				})
				.then(res => {
					this.protocolList = res || []
					// this.fileUrl = res[0].fileUrl
				})
				.catch(console.log)
				.finally(() => {})
		},
		clickProtocol(item) {
			this.SET_ALL({
				selfCardInfo: {
					...this.selfCardInfo,
					...this.formData
				},
				selfChecked: this.checked
			})
			this.$router.push({
				name: 'CardProtocol',
				query: {
					url: encodeURIComponent(item.fileUrl),
					name: '绑卡支付协议'
				}
			})
		},
		sendSuccess(info) {
			this.sendInfo = info
			this.formData.orderId = info.orderId
			this.SET_ALL({
				selfCardInfo: {
					...this.selfCardInfo,
					...this.formData,
					...info
				}
			})
		},
		handleSubmit() {
			this.submitLoading = true
			addBankCardInfoRegistrant({
				cardHolder: 'REGISTRANT',
				...this.sendInfo,
				...this.selfCardInfo,
				...this.formData
			})
				.then(res => {
					try {
						// Event snippet for 注册3-绑定银行卡成功后 conversion page
						// eslint-disable-next-line no-undef
						gtag('event', 'conversion', {
							send_to: 'AW-16652125746/pNd0CIqZ28UZELKMrYQ-'
						})
					} catch (err) {
						console.error(err)
					}
					try {
						// eslint-disable-next-line no-undef
						fbq('track', 'SubmitApplication')
					} catch (err) {
						console.error(err)
					}
					this.$toast('绑定成功')
					this.$router.push({
						name: 'CardList'
					})
				})
				.catch(console.log)
				.finally(() => {
					this.submitLoading = false
				})
		}
	}
}
</script>

<style lang="less" scoped>
.step-container {
	position: relative;
	.title {
		margin-bottom: 20px;
	}
	.button {
		margin-top: 30px;
	}
	.flex-wrapper {
		display: flex;
		.form-item {
			& + .form-item {
				margin-left: 10px;
			}
		}
		.tip-img {
			min-width: 68px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-left: 10px;
			background: var(--main-08);
			border-radius: 4px 4px 4px 4px;
			cursor: pointer;
			img {
				width: auto;
				height: 30px;
			}
		}
	}
	.confirm-checkbox {
		.protocol-link {
			color: var(--primary-90);
		}
		.van-checkbox {
			display: flex;
			:deep(.van-checkbox__icon) {
				display: flex;
				align-items: center;
				top: 2px;
			}
		}
	}
	/deep/ .timeout .timeout-btn {
		height: 40px;
	}
	.mark-container {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 86%;
		height: auto;
		z-index: 100;
		.mark-wrapper {
			position: relative;
			background: rgba(19, 21, 35, 0.5);
			border-radius: 12px;
			padding: 73px 53px;
			.close-btn {
				position: absolute;
				top: 14px;
				right: 14px;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 28px;
				height: 28px;
				font-size: 20px;
				background: rgba(255, 255, 255, 0.2);
				color: var(--white);
				border-radius: 200px;
			}
		}
	}
}
</style>
