export default [
	{
		name: 'Rent',
		path: '/rent',
		redirect: '/rent/payment',
		meta: {
			title: '房租'
		},
		component: () => import('@/views/rent/Index'),
		children: [
			{
				name: 'RentPayment',
				path: '/rent/payment',
				meta: {
					title: '付房租'
				},
				component: () => import('@/views/rent/Payment')
			},
			{
				name: 'RentPaymentResult',
				path: '/rent/payment/result',
				meta: {
					title: '付房租'
				},
				component: () => import('@/views/rent/PaymentResult')
			},
			{
				name: 'RentPaymentAgain',
				path: '/rent/payment/again',
				meta: {
					title: '付房租'
				},
				component: () => import('@/views/rent/PaymentAgain')
			},
			{
				name: 'RentPaymentChangeLandlord',
				path: '/rent/payment/change-landlord',
				meta: {
					title: '换房东再付款'
				},
				component: () => import('@/views/rent/PaymentChangeLandlord')
			},

			{
				name: 'RentPaymentConfirm',
				path: '/rent/payment/confirm',
				meta: {
					title: '付房租'
				},
				component: () => import('@/views/rent/PaymentConfirm')
			}
		]
	},
	{
		name: 'RentPaymentCancel',
		path: '/rent/payment/cancel',
		meta: {
			title: '取消账单'
		},
		component: () => import('@/views/rent/PaymentCancel')
	}
]
