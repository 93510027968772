<template>
	<svg
		aria-hidden="true"
		class="svg-icon">
		<use :xlink:href="iconName" />
	</svg>
</template>

<script>
export default {
	name: 'SvgIcon',
	props: {
		name: {
			type: String,
			required: true
		}
	},
	computed: {
		iconName() {
			return `#icon-${this.name}`
		}
	}
}
</script>

<style scoped>
.svg-icon {
	width: 1em;
	height: 1em;
	vertical-align: -0.15em;
	fill: currentColor;
	overflow: hidden;
}
</style>
