<template>
	<div
		class="form-item"
		:class="[direction]"
		v-bind="$attrs"
		v-on="$listeners">
		<div class="label">
			{{ label }}
			<slot name="label-after" />
		</div>
		<slot />
	</div>
</template>

<script>
export default {
	props: {
		label: {
			type: String,
			default: ''
		},
		// 纵向column 横向row
		direction: {
			type: String,
			default: 'column'
		}
	}
}
</script>

<style lang="less" scoped>
.form-item {
	user-select: none;
	.label {
		min-width: 70px;
		font-size: 14px;
		color: var(--main-03);
		line-height: 20px;
		margin-bottom: 12px;
		white-space: nowrap;
	}
	&.row {
		display: flex;
		align-items: center;
		.label {
			margin-bottom: 0;
			margin-right: 12px;
		}
	}
}
</style>
