export default [
	{
		name: 'Home',
		path: '/home',
		meta: {
			title: '首页',
			hideNavBar: true
		},
		component: () => import('@/views/home/Index')
	}
]
