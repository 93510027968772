import api from '@/utils/http/axios'
import { BASE_URL } from '@/utils/http/base-url'

/**
 * @desc 授权身份认证
 * @param {string} authorizeKey
 * @param {string} lastFourDigits
 */
export const queryAuthBankCardInfo = (params = {}, config = {}) => {
	return api.post(
		BASE_URL + '/app/frontend/api/bankCard/queryAuthBankCardInfo',
		params,
		{
			...config
		}
	)
}
