<template>
	<van-dialog
		v-model="visible"
		class-name="submit-dialog"
		:get-container="getContainer"
		overlay-class="submit-overlay"
		:show-confirm-button="false"
		:close-on-click-overlay="false"
		@close="closeDialog">
		<div class="dialog-container">
			<div
				class="close-btn"
				@click="visible = false">
				<SvgIcon name="close" />
			</div>
			<VanValidForm>
				<VanValidFormItem label="密码">
					<FieldInput
						v-model="formData.charPwd"
						clearable
						type="password"
						maxlength="20"
						autocomplete="off"
						:allow-space="false"
						:capitalize="false"
						valid-type=""
						placeholder="请输入密码" />
				</VanValidFormItem>
				<VanValidFormItem label="短信验证码">
					<div class="flex">
						<InputTimeout
							ref="inputTimeout"
							v-model="formData.code"
							class="input-timeout"
							:country-code="userInfo.countryCode"
							:phone="userInfo.mobileNo"
							:border="false"
							valid-type="REG5"
							maxlength="6"
							btn-type="black"
							:sms-type="smsType" />
					</div>
				</VanValidFormItem>
			</VanValidForm>
			<van-button
				type="black"
				round
				class="submit-btn"
				@click="handleSubmit">
				{{ smsType === 'TRADE_VERIFY_CANCEL' ? '确认取消' : '确认支付' }}
			</van-button>
		</div>
	</van-dialog>
</template>

<script>
import { mapState } from 'vuex'
export default {
	props: {
		smsType: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			visible: false,
			formData: {
				charPwd: '',
				code: ''
			}
		}
	},
	computed: {
		...mapState('app', ['userInfo']),
		...mapState('rent', ['rentFormInfo'])
	},
	methods: {
		closeDialog() {
			this.visible = false
			this.formData.charPwd = ''
			this.formData.code = ''
		},
		showDialog() {
			this.visible = true
		},
		getContainer() {
			return document.querySelector('#app')
		},
		submit() {
			return new Promise((resolve, reject) => {
				const { bizOrder, formData } = this
				if (!formData.landlordNo) {
					this.$toast('请选择房东')
					reject(false)
					return
				}
				if (!formData.amount) {
					this.$toast('请输入金额')
					reject(false)
					return
				}
				if (!formData.cardInfoNo) {
					this.$toast('请选择付款银行卡')
					reject(false)
					return
				}
				resolve({
					bizOrder,
					formData
				})
			})
		},
		handleSubmit() {
			const { formData } = this
			console.log(this.rentFormInfo)
			if (!formData.charPwd) {
				this.$toast('请输入密码')
				return
			}
			if (!formData.code) {
				this.$toast('请输入验证码')
				return
			}
			this.$emit('submit', this.formData)
		}
	}
}
</script>
<style lang="less" scoped>
.submit-overlay {
	position: absolute;
}
.close-btn {
	position: absolute;
	right: 20px;
	top: 15px;
	font-size: 16px;
	cursor: pointer;
}
.submit-dialog {
	position: absolute;
	padding: 30px 20px;
	// 验证码
	/deep/.input-timeout {
		.timeout-btn {
			height: 40px;
		}
	}
}
</style>
