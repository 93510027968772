import commonApi from '@/api/common'

/**
 * @description 公共方法
 * @param commit vuex commit
 * @param state vuex state
 * @param name 模块名称，需要和state中名称对应
 * @param api 枚举接口
 * @param isArray 保存的数据是否是数组，普通枚举处理为对象，某些数据需要直接保存数组（如国码）
 * @returns {Promise<{}|*>}
 */
const actionsFn = async ({ commit, state }, name, api, isArray = false) => {
	if (state[`${name}Loading`]) {
		await new Promise(resolve => setTimeout(resolve, 200))
		return actionsFn({ commit, state }, name, api)
	}
	if (
		Object.keys(state[name]).length > 0 ||
		(isArray && state[name].length > 0)
	) {
		return state[name]
	}
	commit('SET_STATE', {
		state: `${name}Loading`,
		data: true
	})
	try {
		const res = await api()
		if (res) {
			let data = {}
			if (isArray) {
				data = res
			} else {
				res.forEach(item => {
					const { key, elements } = item
					data[key] = elements || []
				})
			}
			commit('SET_STATE', {
				state: `${name}`,
				data
			})
			commit('SET_STATE', {
				state: `${name}Loading`,
				data: false
			})
			return data
		}
	} catch (e) {
		commit('SET_STATE', {
			state: `${name}Loading`,
			data: false
		})
		return state[name]
	}
	commit('SET_STATE', {
		state: `${name}Loading`,
		data: false
	})
}

const enums = {
	namespaced: true,
	state: {
		countryListLoading: false,
		countryList: [],
		commonEnumsLoading: false,
		commonEnums: {}
	},
	mutations: {
		SET_STATE: (state, obj) => {
			state[obj.state] = obj.data
		}
	},
	actions: {
		// 国码模块枚举
		async getCountryList(options) {
			return actionsFn(
				options,
				'countryList',
				commonApi.queryCountryCodeList,
				true
			)
		},
		// 公共枚举
		async getEnumsList(options) {
			return actionsFn(options, 'commonEnums', commonApi.getCommonEnums)
		}
	}
}

export default enums
