<template>
	<div class="card-conatiner">
		<keep-alive :include="/Cache$/">
			<router-view />
		</keep-alive>
	</div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
	name: 'CardAddOtherPersonCardCache',
	beforeRouteLeave(to, from, next) {
		if (to.name !== 'CardProtocol') {
			this.SET_INIT()
			console.log(1)
			this.$EventBus.$off('removeCachePage')
		}
		next()
	},
	methods: {
		...mapMutations('card', ['SET_INIT'])
	}
}
</script>

<style lang="less" scoped>
.card-conatiner {
	min-height: calc(100vh - var(--navbar-height));
	background-color: var(--white);
	padding: 16px 24px;
}
</style>
