<template>
	<div class="add-valide-container">
		<div class="title">请输入 {{ queryInfo.name }} 的身份证后四位</div>
		<NumberGroupInput
			ref="numberGroupInput"
			:max-length="4"
			extra-key="X"
			input-type="text"
			@inputComplete="handleValidate" />
	</div>
</template>
<script>
import { queryAuthBankCardInfo } from '@/views/faceValidate/api/index.js'
export default {
	data() {
		return {
			queryInfo: {}
		}
	},
	created() {
		this.queryInfo = this.$route.query
	},
	methods: {
		handleValidate(val) {
			const { authorizeKey } = this.queryInfo
			queryAuthBankCardInfo({
				authorizeKey,
				lastFourDigits: val
			})
				.then(res => {
					this.$router.push({
						name: 'FaceValidateCardHolderConfirm',
						query: {
							authorizeKey,
							...res
						}
					})
				})
				.catch(err => {
					if (err.retCode === 'P1056') {
						this.$router.push({
							name: 'FaceValidateCardHolderResult',
							query: {
								faceResult: 'UNBIND'
							}
						})
					} else {
						this.$refs.numberGroupInput.initValue()
					}
				})
		}
	}
}
</script>
<style lang="less" scoped>
.add-valide-container {
	height: calc(100vh - var(--navbar-height));
	padding: 80px 24px 0;
	background: var(--white);

	.title {
		font-size: 18px;
		line-height: 18px;
		text-align: center;
		margin-bottom: 52px;
	}
}
</style>
