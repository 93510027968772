<template>
	<div class="login">
		<div class="login-tips">
			<p>
				为保障交易安全，请妥善保管您的支付密码；安全的支付密码建议不要包含生日、手机号或连续字母数字等信息。
			</p>
		</div>
		<VanValidForm>
			<VanValidFormItem label="原始支付密码">
				<InputPassword
					v-model="formData.oldPassword"
					size="large"
					:formatter="passwordFormatter"
					maxlength="6"
					placeholder="请输入原始支付密码" />
			</VanValidFormItem>
			<VanValidFormItem label="新支付密码">
				<InputPassword
					v-model="formData.password1"
					size="large"
					:formatter="passwordFormatter"
					maxlength="6"
					placeholder="请输入6位数字的新修改的支付密码" />
			</VanValidFormItem>
			<VanValidFormItem label="确认新支付密码">
				<InputPassword
					v-model="formData.password2"
					size="large"
					:formatter="passwordFormatter"
					maxlength="6"
					placeholder="请再次输入新修改的支付密码" />
			</VanValidFormItem>
		</VanValidForm>
		<div class="login-btn">
			<van-button
				round
				:disabled="!isLoginBtn"
				type="black"
				@click="nextStep">
				确 定
			</van-button>
		</div>
	</div>
</template>
<script>
import VanValidFormItem from 'components/form/VanValidFormItem.vue'
import VanValidForm from 'components/form/VanValidForm.vue'
import { updateTradePwd } from 'views/setting/api/tradePassword'

export default {
	components: { VanValidForm, VanValidFormItem },
	data() {
		return {
			formData: {
				oldPassword: '',
				password1: '',
				password2: ''
			}
		}
	},
	computed: {
		isLoginBtn() {
			const { oldPassword, password1, password2 } = this.formData
			return oldPassword && password1 && password2
		},
		isPasswordLength() {
			return (
				this.formData.oldPassword.length >= 6 &&
				this.formData.password1.length >= 6 &&
				this.formData.password2.length >= 6
			)
		}
	},
	methods: {
		passwordFormatter(value) {
			return value.replace(/[^0-9]/g, '')
		},
		nextStep() {
			if (!this.isPasswordLength) {
				this.$toast('密码长度至少为6位')
				return
			}
			const { oldPassword, password1, password2 } = this.formData
			if (password2 !== password1) {
				this.$toast('两次密码输入不一致')
				return
			}
			const loading = this.$toast.loading()
			updateTradePwd({
				oldPassword,
				password: password1
			})
				.then(res => {
					this.$toast('操作成功')
					this.$router.go(-1)
				})
				.catch(err => {
					console.log(err)
				})
				.finally(() => {
					loading.clear()
				})
		}
	}
}
</script>
<style scoped lang="less">
.login {
	min-height: calc(100vh - var(--navbar-height));
	background-color: #fff;
	padding: 14px 24px;

	.login-btn {
		padding-top: 30px;
	}
	.login-tips {
		color: var(--main-04);
		font-size: 12px;
		line-height: 20px;
		padding-bottom: 24px;
	}
}
</style>
