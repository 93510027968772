<template>
	<div
		class="container"
		:style="fileList.length ? 'margin-bottom: -20px;' : ''">
		<!-- 查看 -->
		<ViewFileItem
			v-for="(item, index) in fileList"
			:key="item.path"
			:index="index"
			:file="item"
			:del="!disabled"
			:upload-type="uploadType"
			@delete="handleFileDelete" />
		<!-- :style="index % 3 === 2 ? 'margin-right:0px' : ''" -->

		<!-- 上传 -->
		<div
			v-if="fileList.length < maxFiles"
			v-loading="uploading"
			class="upload-container"
			:class="[
				{ mb20: fileList.length },
				disabled && fileList.length > 0 ? 'hidden' : disabled ? 'disabled' : ''
			]">
			<div class="upload-wrapper">
				<div class="upload-icon">
					<SvgIcon
						name="upload"
						fill="#1460FF" />
				</div>
				<p class="upload-text">{{ '上传文件' }}</p>
			</div>
			<div
				v-if="showTip"
				class="tip-container">
				{{ tip }}
			</div>

			<input
				v-if="!disabled"
				ref="fileInput"
				type="file"
				class="upload-input"
				:accept="accept"
				:multiple="false"
				@change="handleFileUpload" />
		</div>
	</div>
</template>

<script>
import commonApi from '@/api/common'
import { right } from 'core-js/internals/array-reduce'
import emitter from 'element-ui/src/mixins/emitter'

export default {
	mixins: [emitter],

	props: {
		// 文件列表
		fileList: {
			type: Array,
			default() {
				return []
			}
		},
		// 支持上传的文件类型
		accept: {
			type: String,
			default:
				'image/png,image/jpeg,image/jpg,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
		},
		showTip: {
			type: Boolean,
			default: false
		},
		// 是否禁用
		disabled: {
			type: Boolean,
			default: false
		},
		// 允许上传的最大数量
		maxFiles: {
			type: Number,
			default: 10
		},
		// 单个文件的最大size，单位M
		sizeLimit: {
			type: Number,
			default: 10
		},
		// 特殊上传接口
		uploadType: {
			type: String,
			default: ''
		},
		// 上传时携带的额外参数
		uploadData: {
			type: Object,
			default() {
				return {}
			}
		},
		uploadFileKey: {
			type: String,
			default: 'file'
		}
	},
	data() {
		return {
			uploading: false // 上传中状态
		}
	},
	computed: {
		tip() {
			const typeList = []
			const { accept, sizeLimit, maxFiles } = this.$props
			const list = accept.split(',')
			list.forEach(item => {
				if (item === 'application/pdf') {
					typeList.push('PDF')
				}
				if (item === 'image/png') {
					typeList.push('PNG')
				}
				if (item === 'image/jpeg') {
					typeList.push('JPEG')
				}
				if (item === 'image/jpg') {
					typeList.push('JPG')
				}
				if (
					item === 'application/vnd.ms-excel' ||
					item ===
						'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
				) {
					if (!typeList.includes('XLSX')) {
						typeList.push('XLSX')
					}
				}
			})
			const data = {
				type: typeList.join(', '),
				len: maxFiles,
				size: sizeLimit
			}
			return `Supported file formats: ${data.type}. Max file size: ${data.size}. Max upload limit: ${data.len} files.`
		}
	},
	mounted() {},
	methods: {
		right,
		upload(data) {
			const { uploadType } = this.$props
			if (uploadType === 'salesBatch') {
				// return FxSalesApi.salesOrderFileUpload(data)
			}
			return commonApi.uploadFile(data)
		},
		handleFileUpload(e) {
			const { files } = e.target
			const { sizeLimit, uploadData } = this
			const { accept } = this.$props
			for (let i = 0; i < files.length; i++) {
				const file = files[i]
				const { type, size } = file
				const isLt2M = size / 1024 / 1024 <= sizeLimit
				if (!isLt2M) {
					this.$toast(`上传文件大小不能超过${sizeLimit}MB!`)
					continue
				}
				// windows选择全部文件，会略过accept文件选择限制，做一层判断
				const fileType = file.type
				if (!accept.split(',').includes(fileType)) {
					this.$toast('文件格式不正确')
					continue
				}
				// 选择完文件后将输入框置为失焦，否则enter键可以再次唤起选择文件窗口
				if (this.$refs.fileInput) {
					this.$refs.fileInput.blur()
				}
				this.uploading = true
				this.upload({
					[this.uploadFileKey]: file,
					...uploadData
				})
					.then(res => {
						if (res) {
							const { name, path, fileName, fileNo, fileSerialNo } = res
							// eslint-disable-next-line vue/no-mutating-props
							this.fileList.push({
								...res,
								type,
								fileNo,
								name: name || fileName,
								path: path || fileNo || fileSerialNo
							})
							// 上传完成后 把input的value置为'' 这样才能上传重复的文件
							// this.$refs.fileInput.value = ''
							// 触发事件
							this.$emit('uploaded')
							this.dispatch('ElFormItem', 'el.form.change', this.fileList)
						}
					})
					.catch(console.error)
					.finally(() => {
						if (this.$refs.fileInput) {
							this.$refs.fileInput.value = ''
						}
						this.uploading = false
					})
			}
		},
		handleFileDelete(file) {
			const index = this.fileList.findIndex(item => item.path === file.path)
			// eslint-disable-next-line vue/no-mutating-props
			this.fileList.splice(index, 1)
		}
	}
}
</script>

<style scoped lang="less">
.mb20 {
	margin-bottom: 20px;
}
.container {
	display: flex;
	flex-wrap: wrap;
	padding: 14px 0;
	.process-container {
		width: 120px;
		height: 120px;
		border-radius: 4px;
		border: 1px dashed var(--main-03);
	}
	.upload-container {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 90px;
		height: 90px;
		border-radius: 4px;
		color: var(--blue-01);
		border: 1px dashed var(--main-03);
		//margin-right: 20px;
		background-color: var(--main-08);
		cursor: pointer;
		&:hover {
			background-color: var(--main-06);
		}
		&.disabled {
			cursor: not-allowed;
			opacity: 0.6;
		}
		&.hidden {
			display: none;
		}
		.upload-wrapper {
			width: 100px;
			text-align: center;
			.upload-icon {
				width: 24px;
				height: 24px;
				font-size: 22px;
				margin: 0 auto;
				padding-right: 5px;
				> img {
					display: block;
					width: 100%;
					height: 100%;
				}
			}
			.upload-text {
				margin-top: 5px;
				font-size: 14px;
				font-weight: 400;
				line-height: 14px;
				user-select: none;
			}
		}
		.tip-container {
			flex: 1;
			font-size: 12px;
			line-height: 20px;
			color: var(--main-01);
			padding-right: 5px;
			word-break: break-word;
		}

		.upload-input {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 99;
			width: 100%;
			height: 100%;
			padding: 0;
			cursor: pointer;
			opacity: 0;
		}
	}
}
</style>
