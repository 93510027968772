export default {
	// 身份证号码 https://juejin.cn/post/6844903575877861390
	idCard:
		/^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9X]$/,
	// 国外手机号
	phone: /^\d{5,11}$/,
	// 中国手机号
	phone86: /^([1][3,4,5,6,7,8,9])\d{9}$/,
	// 地址
	address: /^[a-zA-Z\d /.,?:()\-+{}]{0,140}$/,
	// 数字
	number: /^[0-9]+$/,
	// 字母 数字
	numberAndEn: /^[a-zA-Z0-9]+$/,
	// 大写字母 数字
	numberAndUpperEn: /^[A-Z0-9]+$/,
	// 字母、数字、空格或/.,?:()-+{}，不超过140个字符
	common: /^[a-zA-Z0-9 /.,?:()\-+{}]{0,140}$/,
	// 汉字、空格
	zhAndSpacing: /[\u4E00-\u9FA5|\uF900-\uFA2D|\u0020]/,
	noZhAndPoint: /[^\u4e00-\u9fa5·]/,
	// 密码格式
	password: /^(?:(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])).{8,12}$/,
	// 邮箱
	email: /^[a-zA-Z0-9_\-.]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,

	// REG1：字母、数字、空格或/.,?:()-+{}
	REG1: /^[a-zA-Z\d /.,?:()\-+{}]+$/,
	// REG2：字母、数字、空格、.,、或字母数字下划线
	REG2: /^[(a-z)|(A-Z)|(\d)][(,|.|\w)]+$/,
	// REG3：字符、数字、空格
	REG3: /^[a-zA-Z\d ]+$/,
	// REG4：字母、数字
	REG4: /^[A-Za-z0-9]+$/,
	REG4_BIG: /^[A-Z0-9]+$/,
	REG4_UPPER_8_OR_11: /^([A-Z\d]{8}|[A-Z\d]{11})$/,
	REG4_1: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/, // 必须包含大小写字母和数字
	REG4_2: /^[A-Za-z0-9\-_]+$/,
	REG4_3: /^[A-Za-z]+$/, // 纯字母
	REG4_3_1: /^[A-Za-z ,.;-]+$/, // 字母,.;- 空格
	REG4_4: /^[A-Za-z0-9$@#%-]+$/, // 字母数字特殊符号$@#%-
	// REG5：数字
	REG5: /^[0-9]+$/,
	REG5_1_INFINITE_DECIMALS: /^([1-9]\d{0,9}|0)(\.\d+)?$/, // 无限制小数位
	REG5_2: /^([1-9]\d{0,9}|0)(\.\d{2})?$/, // 金额 匹配两位小数
	REG5_3: /^[0-9.]+$/, // 数字，小数点
	REG5_4: /^[0-9*a-zA-Z()]+$/, // 带*的数字、大小写英文 以及英文括号
	REG5_5: /^[^\d]+$/, // 非数字
	// REG6：所有的中文标点符号（）〈 〉《 》「 」『 』﹃ ﹄〔 〕… — ～﹏ ￥、【 】，。？！：；“ ”‘’（按照一一对应的关系）
	REG6: /[\uff08|\uff09|\u3008|\u3009|\u300a|\u300b|\u300c|\u300d|\u300e|\u300f|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5|\u3001|\u3010|\u3011|\uff0c|\u3002|\uff1f|\uff01|\uff1a|\uff1b|\u201c|\u201d|\u2018|\u2019]/,
	// REG7：汉字、字母、数字、空格或/.,?:()-+{}
	REG7: /^[\u4e00-\u9fa5a-zA-Z\d /.,?:()\-+{}]+$/,
	REG7_1: /^[a-zA-Z\d /.,?:()\-+{}]+$/, // 不包含汉字
	REG7_2: /^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+$/, // 不包含汉字
	// 纯中文
	REG8: /^[\u4e00-\u9fa5]+$/,
	REG8_2: /^[^\u4e00-\u9fa5]+$/, // 不包含中文
	REG8_3: /^[\u4e00-\u9fa5*·]+$/, // 带*和·的中文
	REG8_4: /^[a-zA-Z\u4e00-\u9fa5*·]+$/, // 带*和·的中文及英文
	REG8_5: /^[a-zA-Z\u4e00-\u9fa5*· ]+$/ // 带*和·以及空格的中文及英文
}
