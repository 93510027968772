var render = function render(){var _vm=this,_c=_vm._self._c;return _c('van-dialog',{attrs:{"class-name":"time-progress-dialog","get-container":_vm.getContainer,"show-confirm-button":false,"close-on-click-overlay":false},on:{"close":_vm.closeDialog},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[(_vm.title)?_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c('div',{staticClass:"progress-contianer"},[_c('div',{staticClass:"progress",class:[
				'progress--' + _vm.type,
				{
					'progress--without-text': !_vm.showText,
					'progress--text-inside': _vm.textInside
				}
			],attrs:{"role":"progressbar","aria-valuenow":_vm.percentage,"aria-valuemin":"0","aria-valuemax":"100"}},[(_vm.type === 'line')?_c('div',{staticClass:"progress-bar"},[_c('div',{staticClass:"progress-bar__outer",style:({
						height: _vm.strokeWidth + 'px',
						backgroundColor: _vm.defineBackColor
					})},[_c('div',{staticClass:"progress-bar__inner",style:(_vm.barStyle)},[(_vm.showText && _vm.textInside)?_c('div',{staticClass:"progress-bar__innerText",style:({ color: _vm.textColor })},[_vm._v(" "+_vm._s(_vm.content)+" ")]):_vm._e()])])]):_c('div',{staticClass:"progress-circle",style:({ height: _vm.width + 'px', width: _vm.width + 'px' })},[_c('svg',{attrs:{"viewBox":"0 0 100 100"}},[_c('path',{staticClass:"progress-circle__track",style:(_vm.trailPathStyle),attrs:{"d":_vm.trackPath,"stroke":_vm.defineBackColor,"stroke-width":_vm.relativeStrokeWidth,"fill":"none"}}),_c('path',{staticClass:"progress-circle__path",style:(_vm.circlePathStyle),attrs:{"d":_vm.trackPath,"stroke":_vm.stroke,"fill":"none","stroke-linecap":_vm.strokeLinecap,"stroke-width":_vm.percentage ? _vm.relativeStrokeWidth : 0}})])]),(_vm.showText && !_vm.textInside)?_c('div',{staticClass:"progress-text-container"},[_c('div',{staticClass:"progress-text",style:({ fontSize: _vm.progressTextSize + 'px', color: _vm.textColor })},[_vm._v(" "+_vm._s(_vm.content)+" ")])]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }