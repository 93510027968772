<template>
	<div class="landlord-list-container">
		<van-button
			class="fixd-container"
			size="small"
			@click="handleGoHelp">
			寻求帮助？
		</van-button>
		<ul
			v-if="landlordList && landlordList.length > 0"
			class="landlord-list">
			<li
				v-for="(item, index) in transformLandlordList"
				:key="index"
				class="landlord-item">
				<div
					style="cursor: pointer"
					@click="handleClickLandlord(item)">
					<div class="header">
						<p class="name">{{ item.fullName }}</p>
						<div class="status">
							<span
								class="status-text"
								:class="item.landlordStatus">
								{{ item.landlordStatusCn }}
							</span>
						</div>
					</div>
					<div class="content">
						{{ item.bankCardNo }}
					</div>
				</div>
				<div class="footer">
					<ul class="operation-list">
						<li
							v-if="item.landlordStatus === 'AUDIT_PASS'"
							class="operation-item"
							@click.stop="handleGoToPay(item)">
							<SvgIcon
								name="transfer"
								class="icon" />
							<span class="text">去付款</span>
						</li>
						<li
							v-if="item.landlordStatus === 'AUDIT_NOT_PASS'"
							class="operation-item"
							@click.stop="handleResubmitLandlord(item)">
							<SvgIcon
								name="resubmit"
								class="icon" />
							<span class="text">重新提交</span>
						</li>
						<!-- 一期下线 -->
						<!-- <li
							v-clipboard:copy="item.bankCardNo"
							v-clipboard:success="handleCopySuccess"
							v-clipboard:error="handleCopyError"
							class="operation-item">
							<SvgIcon
								name="copy"
								class="icon" />
							<span class="text">复制</span>
						</li> -->
					</ul>
					<div
						v-if="item.landlordStatus !== 'WAIT_AUDIT'"
						v-clickoutside="() => handleClickOutside(item, index)"
						class="more"
						@click.stop="handleShowMore(item, index)">
						<SvgIcon name="more" />
						<ul
							v-show="item.showMore"
							class="more-list">
							<li
								v-if="item.landlordStatus === 'NULLIFY'"
								class="more-item"
								@click="handleEnableLandlord(item)">
								启用
							</li>
							<li
								v-else-if="item.landlordStatus === 'AUDIT_PASS'"
								class="more-item"
								@click="handleDisableLandlord(item)">
								停用
							</li>
							<li
								class="more-item red"
								@click="handleDeleteLandlord(item)">
								删除
							</li>
						</ul>
					</div>
				</div>
			</li>
		</ul>
		<van-empty
			v-else
			:image-size="160"
			:image="require('@/assets/image/common/empty.png')"
			description="暂无数据" />
		<van-button
			type="black"
			round
			class="landlord-btn"
			@click="handleAddLandlord">
			<div class="content">
				<SvgIcon
					class="icon"
					name="plus-circle" />
				<span class="text">添加房东</span>
			</div>
		</van-button>
	</div>
</template>

<script>
import lanlordApi from './api/list'

export default {
	data() {
		return {
			pageNo: 1,
			pageSize: 9999,
			landlordList: [],
			transformLandlordList: []
		}
	},
	watch: {
		landlordList: {
			handler(list) {
				this.transformLandlordList = list.map(item => {
					return {
						...item,
						showMore: false
					}
				})
			},
			immediate: true
		}
	},
	created() {
		this.getLandlordList()
	},
	methods: {
		getLandlordList() {
			const { pageNo, pageSize } = this
			return lanlordApi
				.getLandlordList({
					pageNo,
					pageSize
				})
				.then(res => {
					const { records } = res
					this.landlordList = records
				})
				.catch(console.error)
		},
		handleClickLandlord(item) {
			/*
				AUDIT_PASS 有效
				AUDIT_NOT_PASS 驳回
				WAIT_AUDIT 审核中
				INIT 草稿
				NULLIFY 停用
			*/
			const { landlordNo, landlordStatus } = item
			const map = {
				AUDIT_PASS: 'LandlordDetail',
				AUDIT_NOT_PASS: 'LandlordDetail',
				WAIT_AUDIT: 'LandlordDetail',
				INIT: 'LandlordOperationStep1',
				NULLIFY: 'LandlordDetail'
			}
			this.$router.push({
				name: map[landlordStatus],
				query: {
					landlordNo
				}
			})
		},
		handleCopySuccess() {
			this.$toast('复制成功')
		},
		handleCopyError() {
			this.$toast('复制失败')
		},
		handleShowMore(item, index) {
			item.showMore = true
		},
		handleClickOutside(item, index) {
			item.showMore = false
		},
		// 添加
		handleAddLandlord() {
			this.$router.push({
				name: 'LandlordOperationStep1'
			})
		},
		// 删除
		handleDeleteLandlord(item) {
			const { landlordNo, landlordStatusCn } = item
			this.$dialog
				.confirm({
					title: '提示',
					message: `您确定要删除状态为 ${landlordStatusCn} 的房东信息么？`
				})
				.then(_ => {
					const loading = this.$toast.loading()
					lanlordApi
						.deleteLandlord({
							landlordNo
						})
						.then(res => {
							if (res) {
								loading.clear()
								this.$dialog
									.alert({
										title: '提示',
										message: '删除成功'
									})
									.then(_ => {
										this.getLandlordList()
									})
							}
						})
						.catch(console.error)
				})
				.catch(_ => {})
		},
		// 启用
		handleEnableLandlord(item) {
			const { landlordNo } = item
			const loading = this.$toast.loading()
			lanlordApi
				.enableLandlord({
					landlordNo
				})
				.then(res => {
					if (res) {
						loading.clear()
						this.$dialog
							.alert({
								title: '提示',
								message: '启用成功'
							})
							.then(_ => {
								this.getLandlordList()
							})
					}
				})
				.catch(console.error)
		},
		// 停用
		handleDisableLandlord(item) {
			const { landlordNo } = item
			const loading = this.$toast.loading()
			lanlordApi
				.disableLandlord({
					landlordNo
				})
				.then(res => {
					if (res) {
						loading.clear()
						this.$dialog
							.alert({
								title: '提示',
								message: '停用成功'
							})
							.then(_ => {
								this.getLandlordList()
							})
					}
				})
				.catch(console.error)
		},
		// 重新提交
		handleResubmitLandlord(item) {
			const { landlordNo } = item
			this.$router.push({
				name: 'LandlordOperationStep1',
				query: {
					landlordNo
				}
			})
		},
		// 去付款
		handleGoToPay(item) {
			const { landlordNo } = item
			this.$router.push({
				name: 'RentPayment',
				query: {
					landlordNo
				}
			})
		},
		handleGoHelp() {
			this.$router.push({
				name: 'Help'
			})
		}
	}
}
</script>

<style scoped lang="less">
.landlord-list-container {
	.fixd-container {
		position: absolute;
		top: 10px;
		right: 16px;
		z-index: 10;
		border: 1px solid var(--main-06);
		// border-radius: 4px;
		// padding: 9px 10px;
		cursor: pointer;
	}
	.landlord-list {
		position: relative;
		color: var(--main-01);
		user-select: none;
		.landlord-item {
			width: 100%;
			min-height: 146px;
			margin-bottom: 15px;
			padding: 20px 16px;
			background: var(--main-08);
			border-radius: 20px;
			&:last-child {
				margin-bottom: 0;
			}
			.header {
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				margin-bottom: 16px;
				.name {
					font-size: 16px;
					line-height: 20px;
					font-weight: bold;
					max-width: 80%;
					word-break: break-all;
				}
				.status {
					width: 56px;
					height: 24px;
					line-height: 24px;
					text-align: center;
					.status-text {
						display: inline-block;
						width: 100%;
						height: 100%;
						font-size: 12px;
						color: var(--white);
						border-radius: 20px;
						&.AUDIT_PASS {
							background: #92e1bf;
						}
						&.AUDIT_NOT_PASS {
							background: #fc99a5;
						}
						&.WAIT_AUDIT {
							background: #ffd27a;
						}
						&.INIT {
							background: var(--main-04);
						}
						&.NULLIFY {
							background: var(--main-05);
						}
					}
				}
			}
			.content {
				max-width: 80%;
				margin-bottom: 20px;
				font-size: 16px;
				word-break: break-all;
			}
			.footer {
				display: flex;
				justify-content: space-between;
				align-items: center;
				.operation-list {
					display: flex;
					align-items: center;
					.operation-item {
						display: flex;
						align-items: center;
						margin-right: 10px;
						padding: 5px 14px 5px 12px;
						background-color: var(--white);
						border-radius: 20px;
						cursor: pointer;
						&:last-child {
							margin-right: 0;
						}
						.icon {
							margin-right: 6px;
							font-size: 20px;
						}
						.text {
							font-size: 12px;
						}
					}
				}
				.more {
					position: relative;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 30px;
					height: 30px;
					border-radius: 15px;
					background-color: var(--white);
					cursor: pointer;
					.more-list {
						position: absolute;
						top: 36px;
						right: 0;
						display: flex;
						align-items: center;
						height: 36px;
						padding: 6px 0;
						background: var(--white);
						box-shadow: 0px 6px 24px 0px rgba(38, 44, 71, 0.16);
						border-radius: 4px;
						.more-item {
							font-size: 12px;
							padding: 6px 24px;
							border-right: 1px solid var(--main-08);
							white-space: nowrap;
							&.red {
								color: var(--error-dark);
							}
						}
					}
				}
			}
		}
	}
	.landlord-btn {
		margin-top: 30px;
		.content {
			display: flex;
			justify-content: center;
			align-items: center;
			.icon {
				margin-right: 6px;
				font-size: 24px;
			}
			.text {
				font-size: 16px;
				color: var(--white);
			}
		}
	}
}
</style>
