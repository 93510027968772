<template>
	<div class="bills-container">
		<router-view />
	</div>
</template>

<script>
export default {
	data() {
		return {}
	},
	methods: {}
}
</script>

<style scoped lang="less">
.bills-container {
	padding: 16px;
	background-color: var(--main-08);
}
</style>
