import { render, staticRenderFns } from "./Result.vue?vue&type=template&id=153eb578&scoped=true"
import script from "./Result.vue?vue&type=script&lang=js"
export * from "./Result.vue?vue&type=script&lang=js"
import style0 from "./Result.vue?vue&type=style&index=0&id=153eb578&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "153eb578",
  null
  
)

export default component.exports