<template>
	<div>
		<VanValidForm>
			<VanValidFormModule title="账户信息 (英文)">
				<VanValidFormItem label="First Name">
					<FieldInput
						v-model="formData.firstName"
						clearable
						disabled
						placeholder="请输入" />
				</VanValidFormItem>
				<VanValidFormItem label="Last Name">
					<FieldInput
						v-model="formData.lastName"
						clearable
						disabled
						placeholder="请输入" />
				</VanValidFormItem>
			</VanValidFormModule>
			<VanValidFormModule title="房东地址 (仅限美国) (英文)">
				<VanValidFormItem label="State">
					<ElFormSelect
						v-model="formData.landlordStateCode"
						placeholder="请选择"
						clearable
						class="input-form"
						@change="
							code => handleChangeState(code, { changeType: 'landlord' })
						">
						<ElOption
							v-for="item in stateList"
							:key="item.areaCode"
							:label="item.areaNameEn"
							:value="item.areaCode" />
					</ElFormSelect>
				</VanValidFormItem>
				<VanValidFormItem label="City">
					<ElFormSelect
						v-model="formData.landlordCityCode"
						placeholder="请选择"
						clearable
						class="input-form"
						@change="
							code => handleChangeCity(code, { changeType: 'landlord' })
						">
						<ElOption
							v-for="item in cityList"
							:key="item.areaCode"
							:label="item.areaNameEn"
							:value="item.areaCode" />
					</ElFormSelect>
				</VanValidFormItem>
				<VanValidFormItem label="District">
					<ElFormSelect
						v-model="formData.landlordDistrictCode"
						placeholder="请选择"
						clearable
						class="input-form">
						<ElOption
							v-for="item in districtList"
							:key="item.areaCode"
							:label="item.areaNameEn"
							:value="item.areaCode" />
					</ElFormSelect>
				</VanValidFormItem>
				<VanValidFormItem label="Address">
					<FieldInput
						v-model="formData.landlordAddress"
						type="textarea"
						clearable
						placeholder="请输入" />
				</VanValidFormItem>
				<VanValidFormItem label="Zip Code">
					<FieldInput
						v-model="formData.landlordZipCode"
						clearable
						placeholder="请输入"
						:formatter="formatLandlordZipCode" />
				</VanValidFormItem>
			</VanValidFormModule>
			<van-button
				type="black"
				round
				class="submit-btn"
				native-type="submit"
				@click="handleNext">
				Save & Next
			</van-button>
		</VanValidForm>
	</div>
</template>

<script>
import getDetailMixins from '../mixins/getDetail'
import selectCityMixins from '../mixins/selectCity'
import validateMixins from '../mixins/validate'
import operationApi from '../api/operation'

export default {
	mixins: [getDetailMixins, selectCityMixins, validateMixins],
	data() {
		return {
			formData: {
				firstName: '',
				lastName: '',
				landlordStateCode: '',
				landlordCityCode: '',
				landlordDistrictCode: '',
				landlordAddress: '',
				landlordZipCode: ''
			},
			rules: {
				landlordStateCode: [{ required: true, message: '请选择State' }],
				landlordCityCode: [{ required: true, message: '请选择City' }],
				landlordDistrictCode: [{ required: true, message: '请选择District' }],
				landlordAddress: [{ required: true, message: '请输入Address' }],
				landlordZipCode: [{ required: true, message: '请输入Zip Code' }]
			}
		}
	},
	async created() {
		await this.getLanglordDetail()
		const { landlordStateCode, landlordCityCode } = this.formData
		if (landlordStateCode) {
			this.queryStateAndCityList({
				pareaCode: landlordStateCode,
				target: 'cityList'
			})
		}
		if (landlordCityCode) {
			this.queryStateAndCityList({
				pareaCode: landlordCityCode,
				target: 'districtList'
			})
		}
	},
	methods: {
		formatLandlordZipCode(value) {
			if (!value) return ''
			return value.replace(/[^0-9-]/g, '')
		},
		handleNext() {
			const { landlordNo, formData, rules } = this
			this.validateForm(formData, rules)
				.then(_ => {
					const loading = this.$toast.loading()
					operationApi
						.addLandlordAccount({
							landlordNo,
							...formData
						})
						.then(res => {
							if (res) {
								const { landlordNo } = res
								this.$router.push({
									name: 'LandlordOperationStep3',
									query: {
										landlordNo
									}
								})
							}
						})
						.catch(console.error)
						.finally(_ => {
							loading.clear()
						})
				})
				.catch(({ rules }) => {
					const { message } = rules
					this.$dialog.alert({
						title: '提示',
						message
					})
				})
		}
	}
}
</script>

<style scoped lang="less">
.input-form {
	width: 100%;
}
</style>
